import React, { useEffect, useMemo, useState } from 'react'
import styles from "../../Identity-management-individual/style.module.css";
import styles1 from "./style.module.css";
import Spinner from '../../components/spinner';
import { OutlineButton } from './components/buttons';
import Table from '../../components/Table';
import {
    useFetchIncome,
    useSetupGETRequests,
    useSetupPOSTRequests
} from './components/hooks';
import { useHistory } from "react-router";
import { formatAmount } from '../../utils';
import { EditModal } from './components/editModal';
import axios from 'axios';
import Alert from '../../components/Alert';
import Modal from "../../components/Modal/regular";
import GoBackButton from '../../components/goBackButton';

const user = JSON.parse(localStorage.getItem("user_info"));

const headers = [
    ["Type of Income", "incometype"],
    ["Income Description", "incomesource"],
    ["Amount (₦)", "amount", formatAmount]
];
const prepaymentTableHeader = [
    ["Type of Prepayments", "incometype"],
    ["Income Description", "incomesource"],
    ["Amount (₦)", "amount", formatAmount]
];

const HNITax = () => {
    const [loading, setLoading] = useState(false);
    const [generatingHNI, setGeneratingHNI] = useState(false);
    const [taxIsCalculated, setTaxIsCalculated] = useState(false);
    const [billNumber, setBillNumber] = useState(null);
    const [sendingForApproval, setSendingForApproval] = useState(false);
    const [viewModalShowing, setViewModalShowing] = useState(false);
    const history = useHistory();

    const [showModal, setShowModal] = useState({
        modalState: false,
        modalType: "",
        data: null
    });

    const [alert, setAlert] = useState({
        showing: false,
        type: null,
        message: ""
    });


    const [taxpayerDetails, setTaxpayerDetails] = useState({
        zone: '',
        esbn: '',
        lga: '',
        email: '',
        gender: '',
        address: '',
        name: '',
        fiscalYear: '',
        phoneNumber: '',
        taxAreaOffice: ''
    });
    const [totalCharges, setTotalCharges] = useState(0);
    const [generatedBillData, setGeneratedBillData] = useState(null);

    const [taxData, setTaxData] = useState({
        tax7percentage: 0,
        tax11percentage: 0,
        tax15percentage: 0,
        tax19percentage: 0,
        tax21percentage: 0,
        tax24percentage: 0,
        tax24bpercentage: 0
    })

    const zones = useFetchIncome({
        route: `https://alpha.norlics.ng/api/settings/zones/${user?.mdaModuleCoy}`
    })

    const lgas = useFetchIncome({
        route: `https://alpha.norlics.ng/api/settings/lgass/${user?.mdaModuleCoy}`
    })

    const taxoffices = useFetchIncome({
        route: `https://alpha.norlics.ng/api/settings/assesstaxoffice`
    })

    const allIncome = useFetchIncome({
        route: `https://alpha.norlics.ng/api/hniallincome`
    })

    const allPrepayment = useFetchIncome({
        route: `https://alpha.norlics.ng/api/hniallprepayment`
    })

    const {
        data: saveHNIData,
        loading: savingHNI,
        setHookData: saveHNIHook,
        status: saveHNIStatus
    } = useSetupPOSTRequests();


    const {
        data: esbnData,
        status: ESBNStatus,
        setHookData: getESBNData
    } = useSetupGETRequests();

    const [income, setIncome] = useState({
        incometype: '',
        incomesource: '',
        amount: ''
    })

    const [payment, setPayment] = useState({
        incometype: '',
        incomesource: '',
        amount: ''
    })

    const [incomeTableData, setIncomeTableData] = useState([]);
    const [paymentTableData, setPaymentTableData] = useState([]);

    const updateTables = (data, tableType) => {
        if (Object.values(data).includes('')) {
            return
        } else {
            if (tableType === 'income') {
                setIncomeTableData((prevState) => ([
                    ...prevState,
                    {
                        ...data,
                        index: prevState?.length + 1
                    }
                ]))
                setIncome({
                    incometype: '',
                    incomesource: '',
                    amount: ''
                })
            } else {
                setPaymentTableData((prevState) => ([
                    ...prevState,
                    {
                        ...data,
                        index: prevState?.length + 1
                    }
                ]))
                setPayment({
                    incometype: '',
                    incomesource: '',
                    amount: ''
                })
            }
        }

    }

    const allIncomeTotal = useMemo(() => {
        const totalAmount = incomeTableData.reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.amount),
            0
        );
        return totalAmount;
    }, [incomeTableData])

    const allPrepaymentTotal = useMemo(() => {
        const totalAmount = paymentTableData.reduce(
            (accumulator, currentValue) =>
                accumulator + Number(currentValue.amount),
            0
        );
        return totalAmount;
    }, [paymentTableData])

    const editTableData = (record, tableType) => {
        if (tableType === 'income') {
            const filteredTableData = incomeTableData?.map(
                current => {
                    return current?.index === record?.index
                        ? record : current
                }
            );
            setIncomeTableData(filteredTableData);
        } else {
            const filteredTableData = paymentTableData?.map(
                current => {
                    return current?.index === record?.index
                        ? record : current
                }
            );
            setPaymentTableData(filteredTableData);
        }
    }

    const deleteTableData = (record, tableType) => {
        if (tableType === 'income') {
            const filteredTableData = incomeTableData?.filter(
                current => current?.index !== record?.index
            );
            const reshuffledTableData = filteredTableData?.map((data, index) => ({
                ...data,
                index
            }))
            setIncomeTableData(reshuffledTableData)
        } else {
            const filteredTableData = paymentTableData?.filter(
                current => current?.index !== record?.index
            );

            const reshuffledTableData = filteredTableData?.map((data, index) => ({
                ...data,
                index
            }))
            setPaymentTableData(reshuffledTableData)
        }
    }


    useEffect(() => {
        if (generatedBillData) {

            setAlert({
                showing: true,
                type: "success",
                message: generatedBillData.msg
            });
        }
    }, [generatedBillData])


    //Get Tranch Charges
    const getTranchCharges = async () => {
        setLoading(true);
        setTaxIsCalculated(true);

        try {
            const res = await axios
                .get(`/assessdatranchcharges/${allIncomeTotal}`)
                .then((res) => (res.data || {}).data);

            // setTranches(res);
            if (res) {
                res.forEach((tranch) => {
                    if (tranch.incTranchNo === "1")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax7percentage: tranch.tranchTax
                        }));
                    if (tranch.incTranchNo === "2")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax11percentage: tranch.tranchTax
                        }));
                    if (tranch.incTranchNo === "3")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax15percentage: tranch.tranchTax
                        }));
                    if (tranch.incTranchNo === "4")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax19percentage: tranch.tranchTax
                        }));
                    if (tranch.incTranchNo === "5")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax21percentage: tranch.tranchTax
                        }));
                    if (tranch.incTranchNo === "6")
                        setTaxData((prevState) => ({
                            ...prevState,
                            tax24percentage: tranch.tranchTax
                        }));
                });

                setTotalCharges(res[res.length - 2]?.totTaxCalc);
                setLoading(false);
                setTaxIsCalculated(false);
                setAlert({
                    ...alert,
                    showing: true,
                    type: "success",
                    message: "ChargeTaxSpread found"
                });
            }
        } catch (error) {
            if (error.response) {
                setAlert({
                    ...alert,
                    showing: true,
                    type: "error",
                    message:
                        error.response.data?.errors?.[0] ||
                        error.response.data?.msg
                });
            }
        }
    };

    const handleSaveHNI = async () => {
        const assessmentcode = await axios.get(`gettmsids/01`);

        if (assessmentcode?.data?.Id) {
            setBillNumber(assessmentcode?.data?.Id)
            const payload = {
                billno: assessmentcode?.data?.Id,
                esbn: taxpayerDetails?.esbn,
                name: `${taxpayerDetails?.name}`?.trim(),
                sex: taxpayerDetails?.gender,
                addr: taxpayerDetails?.address,
                phoneno: taxpayerDetails?.phoneNumber,
                email: taxpayerDetails?.email,
                lga: taxpayerDetails?.lga,
                zone: taxpayerDetails?.zone,
                taxoffice: taxpayerDetails?.taxAreaOffice,
                duedate: `${taxpayerDetails?.fiscalYear}/12/31`, // Find out what it means
                fiscalyear: taxpayerDetails?.fiscalYear,
                totalamtbilled: allIncomeTotal,
                discount: 0,
                discountreason: " ",
                grandtot: allIncomeTotal,
                totalrelief: 0,
                totaloutstanding: 0,
                taxableincome: allIncomeTotal,
                totaltaxdue: totalCharges,
                procstatus: "Pending",
                ...taxData,
                approve: "0",
                whyreject: "",
                inputter: user?.email
                    ? `${user?.email}`.trim()
                    : "",
                authoriser: " ",
                incomes: incomeTableData?.map((income) => ({
                    incometype: income?.incometype ?? ' ',
                    amt: income?.amount ?? 0,
                    incomesource: income?.incomesource ?? ' ',
                    outstandingamt: 0,
                    outstandingreason: " ",
                    revenuecode: " ", //direct Assessment (Current)
                    revenuename: " "
                })),
                prepayments: paymentTableData?.map((payment) => ({
                    incometype: payment?.incometype ?? ' ',
                    incomesource: payment?.incomesource ?? ' ',
                    amount: payment?.amount ?? 0,
                    // outstandingamt: 0,
                    // outstandingreason: " ",
                    // revenuecode: " ", //direct Assessment (Current)
                    // revenuename: " "
                })),
            }
            saveHNIHook({
                method: 'POST',
                route: `https://alpha.norlics.ng/api/hni`,
                service: 'saveHNI',
                payload
            })
        }
    }

    const generateHNI = (async () => {
        if (billNumber) {
            setGeneratingHNI(true);
            try {
                await axios
                    .get(`gethnibill/${billNumber}/1`)
                    .then((res) => {
                        if (res?.data) {
                            setGeneratedBillData(res?.data);
                        }
                        setAlert({
                            showing: true,
                            type: "success",
                            message: saveHNIData.msg
                        });
                    });
            } catch (error) {
                setAlert({
                    showing: true,
                    type: "error",
                    message: error.response.data?.msg ||
                        error.response.data?.errors?.[0] ||
                        error.response.data?.errors?.[0]?.message ||
                        error.response.data?.errors?.details[0]?.message ||
                        error.response.message ||
                        error.response.data.message
                });
            } finally {
                setGeneratingHNI(false);
            }
        }
    })


    useEffect(() => {
        if (saveHNIStatus?.state === 'success') {
            setAlert({
                showing: true,
                type: "success",
                message: saveHNIStatus?.message ?? saveHNIData.msg
            });
            if (saveHNIData) {
                setAlert({
                    showing: true,
                    type: "success",
                    message: saveHNIStatus?.message ?? saveHNIData.msg
                });
                generateHNI();
            }
        }
        if (saveHNIStatus?.state === 'error') {
            setAlert({
                showing: true,
                type: "error",
                message: saveHNIStatus?.message
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [saveHNIData, saveHNIStatus?.message])

    useEffect(() => {
        if (ESBNStatus?.state === 'success') {
            setAlert({
                showing: true,
                type: "success",
                message: ESBNStatus?.message ?? esbnData.msg
            });
            if (esbnData) {
                setAlert({
                    showing: true,
                    type: "success",
                    message: ESBNStatus?.message ?? esbnData.msg
                });
                generateHNI();
            }
        }
        if (ESBNStatus?.state === 'error') {
            setAlert({
                showing: true,
                type: "error",
                message: ESBNStatus?.message
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [esbnData, ESBNStatus?.message])

    useEffect(() => {
        if (generatedBillData) {
            setAlert({
                showing: true,
                type: "success",
                message: saveHNIData.msg
            });

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [generatedBillData])

    useEffect(() => {
        if (taxpayerDetails?.esbn) {
            getESBNData({
                route: `https://alpha.norlics.ng/api/users/anssid?anssid=${taxpayerDetails?.esbn}`,
                service: 'esbn'
            })

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taxpayerDetails?.esbn])

    useEffect(() => {
        if (esbnData) {
            setTaxpayerDetails((prevState) => ({
                ...prevState,
                // zone: '',
                gender: esbnData?.sex,
                email: esbnData?.email,
                name: esbnData?.name ? esbnData?.name :
                    `${esbnData?.firstname} ${esbnData?.surname}`,
                lga: esbnData?.lgaofresidence,
                // fiscalYear: '',
                phoneNumber: esbnData?.phonenumber ?? esbnData?.phoneNumber,
                address: esbnData?.residentialaddress,
                // taxAreaOffice: ''
            }))
        }

    }, [esbnData])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTaxpayerDetails({ ...taxpayerDetails, [name]: value })
    }

    const handleIncomeChange = (e) => {
        const { name, value } = e.target;
        setIncome({ ...income, [name]: value })
    }
    const handlePaymentChange = (e) => {
        const { name, value } = e.target;
        setPayment({ ...payment, [name]: value })
    }

    const submit = (e) => {
        e?.preventDefault();
        if (taxIsCalculated || totalCharges > 0) {
            handleSaveHNI()
        } else {
            getTranchCharges();
        }
    }

    const requestForApproval =
        async (approvalCode, id = saveHNIData?.data?.id, reason = '') => {
            setSendingForApproval(true)
            try {
                const userInfo = JSON.parse(localStorage.getItem("user_info"));

                const res = await axios.post(`/processhni`, {
                    billno: billNumber,
                    recId: id,
                    modifier: userInfo?.email,
                    approve: approvalCode,
                    reason4Reject: reason,
                });
                setAlert((alert) => ({
                    ...alert,
                    showing: true,
                    type: "success",
                    message: res.data.msg
                }));
                setTimeout(() => {
                    history.push('/hni/tax');
                }, 500);
            } catch (error) {
                if (error.response) {
                    setAlert((alert) => ({
                        ...alert,
                        showing: true,
                        type: "error",
                        message:
                            error.response.data?.msg ||
                            error.response.data?.errors?.[0]?.message ||
                            error.response.data?.errors?.details[0]?.message ||
                            error.response?.data?.message ||
                            error.response?.data.err.message
                    }));
                } else if (error.request) {
                    setAlert((alert) => ({
                        ...alert,
                        showing: true,
                        type: "error",
                        message: error.request
                    }));
                } else {
                    setAlert((alert) => ({
                        ...alert,
                        showing: true,
                        type: "error",
                        message: error.message
                    }));
                }
            } finally {
                setSendingForApproval(false)
            }
        };

    useEffect(() => {
        const delay = setTimeout(
            () =>
                setAlert({
                    showing: false,
                    type: null,
                    message: ""
                }),
            3000
        );

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!alert.showing]);

    return (
        <section>
            {(viewModalShowing || generatedBillData) &&
                <Modal toggle={setViewModalShowing} showing={viewModalShowing}>
                    <div className={styles1.cert__btns}>

                        <button
                            className={`tertiary__btn ${styles1.request__btn}`}
                            style={{ marginBottom: "20px" }}
                            onClick={() => history.push("/hni/tax")}
                        >
                            Close
                        </button>
                        <button
                            style={{ padding: "0 25px" }}
                            onClick={() => requestForApproval("5", saveHNIData?.data?.id)}
                            className={`primary__btn ${styles.request__btn}`}
                        >
                            {sendingForApproval
                                ? "Requesting..."
                                : "Request for Approval"}
                        </button>
                    </div>
                    <div
                        dangerouslySetInnerHTML={{
                            __html: generatedBillData
                        }}
                    ></div>
                </Modal>
            }
            {showModal.modalState &&
                <EditModal
                    data={showModal.data}
                    incomeTypes={allIncome[0]?.data}
                    showing={showModal.modalState}
                    modalAction={(reason) => {
                        editTableData({
                            incometype: reason?.IncomeType ?? '',
                            incomesource: reason?.IncomeSource ?? '',
                            amount: reason?.Amount ?? '',
                            index: reason?.index
                        }, showModal.modalType);
                    }}
                    closeModal={(value) =>
                        setShowModal({
                            modalState: value,
                            modalType: "",
                            data: null
                        })
                    }
                    modalType={showModal.modalType}
                />
            }
                <GoBackButton />
            <form className={styles.columned__form} onSubmit={submit}>
                {alert.showing && (
                    <Alert text={alert.message} type={alert.type} />
                )}
                <h3>Taxpayer Details</h3>
                <br />

                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="esbn">
                            ESBN
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="esbn"
                            onChange={handleInputChange}
                            id="esbn"
                            placeholder="Enter ESBN"
                            //  disabled
                            required
                            value={taxpayerDetails?.esbn}
                        />
                    </div>
                    <div>
                        <label className={styles.input__label} htmlFor="name">
                            Name
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            onChange={handleInputChange}
                            id="name"
                            placeholder="Enter first name"
                            //  disabled
                            required
                            value={taxpayerDetails?.name}
                        />
                    </div>
                </div>
                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="phoneNumber">
                            Phone Number
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            onChange={handleInputChange}
                            id="phoneNumber"
                            placeholder="Enter phone number"
                            required
                            value={taxpayerDetails?.phoneNumber}
                        />
                    </div>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="gender"
                        >
                            Gender
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="gender"
                            name="gender"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.gender}
                            required
                        >
                            <option value="">Select</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                    </div>
                </div>

                <div className={styles.double__inputs}>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="email"
                        >
                            Email
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="email"
                            name="email"
                            onChange={handleInputChange}
                            id="email"
                            placeholder="Enter email"
                            required
                            value={taxpayerDetails?.email}
                        />
                    </div>
                    <div>
                        <label className={styles.input__label} htmlFor="lga">
                            Local Govt. Area
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="lga"
                            name="lga"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.lga}
                            required
                        >
                            <option value="">Select</option>
                            {lgas[0]?.data?.map((tzone, index) => (
                                <React.Fragment key={tzone.Code + index}>
                                    <option
                                        value={tzone.Description}
                                        zonecode={tzone.Code}
                                        index={index}
                                    >
                                        {tzone.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={styles.double__inputs}>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="zone"
                        >
                            Zone
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="zone"
                            name="zone"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.zone}
                            required
                        >
                            <option value="">Select</option>
                            {zones[0]?.data?.map((tzone, index) => (
                                <React.Fragment key={tzone.Code + index}>
                                    <option
                                        value={tzone.Description}
                                        zonecode={tzone.Code}
                                        index={index}
                                    >
                                        {tzone.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className={styles.input__label} htmlFor="taxAreaOffice">
                            Tax Area Office
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="taxAreaOffice"
                            name="taxAreaOffice"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.taxAreaOffice}
                            required
                        >
                            <option value="">Select</option>
                            {taxoffices[0]?.data?.map((tzone, index) => (
                                <React.Fragment key={tzone.Code + index}>
                                    <option
                                        value={tzone.Description}
                                        zonecode={tzone.Code}
                                        index={index}
                                    >
                                        {tzone.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>
                </div>

                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="address">
                            Address
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="address"
                            onChange={handleInputChange}
                            id="address"
                            placeholder="Enter your address"
                            required
                            value={taxpayerDetails?.address}
                        />
                    </div>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="fiscalYear"
                        >
                            Fiscal Year
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                            id="fiscalYear"
                            name="fiscalYear"
                            onChange={handleInputChange}
                            value={taxpayerDetails?.fiscalYear}
                            required
                        >
                            <option value="">Select</option>
                            <option>2013</option>
                            {new Array(new Date().getFullYear() - 2013)
                                .fill(0)
                                .map((_, i) => (
                                    <option key={i}>{2013 + i + 1}</option>
                                ))}
                        </select>
                    </div>
                </div>
                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="fiscalDueDate">
                            Fiscal Due Date
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                            type="text"
                            name="fiscalDueDate"
                            id="fiscalDueDate"
                            value={
                                taxpayerDetails?.fiscalYear ?
                                    `31/12/${taxpayerDetails?.fiscalYear}` : ''
                            }
                            placeholder=""
                            disabled
                            required
                        />
                    </div>
                </div>

                <h3 style={{ marginTop: '30px' }}>Details of Incomes</h3>
                <p>After entering the information, click on "Add Income" to include it.
                    <br />
                    Repeat this process to add additional income sources.</p>
                <br />

                <div className={styles.double__inputs}>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="incometype"
                        >
                            Type of Income
                        </label>
                        <select
                            id="incometype"
                            name="incometype"
                            onChange={handleIncomeChange}
                            value={income?.incometype}
                        >
                            <option value="">Select</option>
                            {allIncome[0]?.data?.map((income, index) => (
                                <React.Fragment key={income.Code + index}>
                                    <option
                                        value={income.Description}
                                    >
                                        {income.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className={styles.input__label} htmlFor="incomesource">
                            Income Description
                        </label>
                        <input
                            type="text"
                            name="incomesource"
                            id="incomesource"
                            onChange={handleIncomeChange}
                            value={income?.incomesource}
                            placeholder="Enter source of income"
                        // required
                        />
                    </div>
                </div>
                <div className={styles.double__inputs}
                >
                    <div>
                        <label className={styles.input__label} htmlFor="amount">
                            Amount
                        </label>
                        <div className={styles1.amount__input}>
                            <input
                                type="text"
                                name="amount"
                                id="amount"
                                onChange={handleIncomeChange}
                                value={income?.amount}
                                placeholder="Enter amount"
                            // required
                            />
                            <span className='amount__symb'>₦</span>
                        </div>
                    </div>
                </div>

                <div className={[
                    styles1.add_button__wrapper,
                    styles1.margin__buttom
                ].join(" ")}
                    style={{
                        marginLeft: 'auto'
                    }}
                >
                    <OutlineButton
                        text='Add Income'
                        onclick={() => updateTables(income, 'income')}
                        loading={false}
                        loadingStyle={styles.loading__button}
                    />
                </div>
                {/* <br />
                <br /> */}

                <Table
                    headers={headers}
                    data={incomeTableData}
                    // full
                    pageSize={20}
                    total={incomeTableData?.length}
                    onRowClick={() => null}
                    billNoOfAmountPaidShowing={null}
                    onEdit={(record) =>
                        setShowModal({
                            modalState: true,
                            modalType: "income",
                            data: record
                        })
                    }
                    onDelete={(record) => deleteTableData(record, 'income')}
                />

                <div className={styles1.totals__wrapper}>
                    <div className={[
                        styles1.totals,
                        styles1.income__totals,
                    ].join(" ")}
                    >
                        <h5>Total Income</h5>
                        <h5>₦{Number(allIncomeTotal).toLocaleString()}</h5>
                    </div>
                </div>
                {/* <hr className={styles.divider} /> */}

                <div className={styles1.margin__buttom} />
                <h3>Prepayments</h3>
                <br />

                <div className={styles.double__inputs}>
                    <div>
                        <label
                            className={styles.input__label}
                            htmlFor="incometype"
                        >
                            Payment Description
                            {/* <span style={{ color: "red" }}>*</span> */}
                        </label>
                        <select
                            id="incometype"
                            name="incometype"
                            onChange={handlePaymentChange}
                            value={payment?.incometype}
                        // required
                        >
                            <option value="">Select</option>
                            {allPrepayment[0]?.data?.map((prepayment, index) => (
                                <React.Fragment key={prepayment.Code + index}>
                                    <option
                                        value={prepayment.Description}
                                    >
                                        {prepayment.Description}
                                    </option>
                                </React.Fragment>
                            ))}
                        </select>
                    </div>

                    <div>
                        <label className={styles.input__label} htmlFor="incomesource">
                            Income Description
                        </label>
                        <input
                            type="text"
                            name="incomesource"
                            id="incomesource"
                            onChange={handlePaymentChange}
                            value={payment?.incomesource}
                            placeholder="Enter source of income"
                        />
                    </div>
                </div>

                <div className={styles.double__inputs}>
                    <div>
                        <label className={styles.input__label} htmlFor="amount">
                            Amount
                        </label>
                        <div className={styles1.amount__input__side}>
                            <input
                                type="text"
                                name="amount"
                                id="amount"
                                placeholder="Enter amount"
                                onChange={handlePaymentChange}
                                value={payment?.amount}
                            />
                            <span>₦</span>
                        </div>
                    </div>
                </div>

                <div className={[
                    styles1.add_button__wrapper,
                    styles1.margin__buttom
                ].join(" ")}
                    style={{
                        marginLeft: 'auto'
                    }}
                >
                    <OutlineButton
                        text='Add Prepayments'
                        onclick={() => updateTables(payment, 'prepayments')}
                        loading={false}
                        loadingStyle={styles.loading__button}
                    />
                </div>
                {/* <br />
                <br />
                <br /> */}

                <Table
                    headers={prepaymentTableHeader}
                    data={paymentTableData}
                    total={paymentTableData?.length}
                    onRowClick={() => null}
                    billNoOfAmountPaidShowing={null}
                    onEdit={(record) =>
                        setShowModal({
                            modalState: true,
                            modalType: "prepayments",
                            data: record
                        })
                    }
                    onDelete={(record) => deleteTableData(record, 'prepayments')}

                />

                <div className={styles1.totals__wrapper}>
                    <div className={[
                        styles1.totals,
                        styles1.prepayment__totals,
                    ].join(" ")}
                    >
                        <h5>Total Prepayments</h5>
                        <h5>₦{Number(allPrepaymentTotal).toLocaleString()}</h5>
                    </div>
                </div>

                {/* <hr className={styles.divider} /> */}
                <div className={styles1.margin__buttom} />

                <h3>Total Tax: ₦{Number(totalCharges).toLocaleString()}</h3>

                <footer
                    style={{
                        marginLeft: 'auto'
                    }}
                    className={styles.form__footer}
                >
                    <button
                        style={{
                            padding: '0px 25px'
                        }}
                        disabled={
                            Object.values(taxpayerDetails).includes('') ||
                            incomeTableData.length === 0 ||
                            loading ||
                            taxIsCalculated ||
                            savingHNI ||
                            generatingHNI
                        }
                        type="submit"
                        className={[
                            "primary__btn",
                            styles.primary__button,
                            loading || taxIsCalculated ||
                                savingHNI || generatingHNI
                                ? styles.loading__button : ""
                        ].join(" ")}
                    >
                        {
                            totalCharges > 0 ?
                                "Generate Bill" : savingHNI ?
                                    "Saving HNI..." : generatingHNI ?
                                        "Generating HNI..." : "Calculate Tax"
                        }

                        {(loading || taxIsCalculated ||
                            savingHNI || generatingHNI) && <Spinner />}
                    </button>
                    <div className={styles1.margin__buttom} />
                </footer>
            </form>
        </section>
    )
}

export default HNITax