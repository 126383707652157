import React from 'react';
import styles from '../style.module.css';
import Spinner from '../../../components/spinner';


export const OutlineButton = ({
    text = 'Hello world',
    iconType = 'add',
    loading,
    loadingStyle = '',
    onclick = () => { }
}) => {
    return (
        <>
            {
                !loading ?
                    <button
                        type='button'
                        className={styles.add__button}
                        onClick={onclick}
                    >
                        {iconType === 'add' &&
                            <>
                                <span style={{
                                    position: 'relative',
                                    top: '-1px',
                                    fontSize: '2rem'
                                }}>
                                    +
                                </span>
                                &nbsp;
                            </>}
                        {text}

                    </button>
                    : <button
                        disabled={loading}
                        type='button'
                        className={`primary__btn ${loadingStyle}`}
                        style={{
                            alignItems: 'center',
                            padding: '0px 10px'
                        }}
                    >
                        {iconType === 'add' &&
                            <>
                                <span style={{
                                    position: 'relative',
                                    top: '-1px',
                                    fontSize: '2rem'
                                }}>
                                    +
                                </span>
                                &nbsp;
                            </>}
                        {text}
                        {<Spinner />}

                    </button>
            }
        </>
    )
}
