import React, { useEffect, useState } from 'react'
import styles from "../../Identity-management-individual/style.module.css";
import styles1 from "./style.module.css";
import styles2 from "../../Direct-assessment/Create-direct-assessment/style.module.css";
import Spinner from '../../components/spinner';
import { useLocation } from "react-router-dom";
import Alert from '../../components/Alert';
import axios from 'axios';

const HNISetup = () => {
    const [loading, setLoading] = useState(false);
    let { state } = useLocation();
    const [setupType, setSetupType] = useState(state?.setupType ?? 'income');
    const [alert, setAlert] = useState({
        showing: false,
        type: null,
        message: ""
    });

    const [income, setIncome] = useState({
        code: '',
        descripton: ''
    })

    const [payment, setPayment] = useState({
        code: '',
        descripton: ''
    })

    const handleIncomeChange = (e) => {
        const { name, value } = e.target;
        setIncome({ ...income, [name]: value })
    }

    const handlePaymentChange = (e) => {
        const { name, value } = e.target;
        setPayment({ ...payment, [name]: value })
    }

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await axios
                .post(setupType === 'income' ?
                    `https://alpha.norlics.ng/api/hniincome` :
                    `https://alpha.norlics.ng/api/hniprepayment`, {
                    Code: income?.code,
                    Description: income?.descripton,
                    Deactivated: false
                })
                .then((res) => (res.data));
            setAlert({
                ...alert,
                showing: true,
                type: "success",
                message: res?.msg
            });
        setLoading(false);
        } catch (error) {
            if (error.response) {
                setAlert({
                    ...alert,
                    showing: true,
                    type: "error",
                    message:
                        error.response.data?.errors?.[0] ||
                        error.response.data?.msg
                });
            }
        setLoading(false);
        }
    }

    useEffect(() => {
        const delay = setTimeout(
            () =>
                setAlert({
                    showing: false,
                    type: null,
                    message: ""
                }),
            3000
        );

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!alert.showing]);

    return (
        <form className={styles.columned__form} onSubmit={submit}>
            {alert.showing && (
                <Alert text={alert.message} type={alert.type} />
            )}
            <div
                className={styles2.radio__button_div}
            >
                <div>
                    <div>
                        <input
                            id="incomeSetup"
                            type="radio"
                            value="income"
                            checked={setupType === "income"}
                            onChange={(e) => {
                                setSetupType(e.target.value);
                            }}
                        />
                        <label
                            className={styles.input__label}
                            htmlFor="incomeSetup"
                        >
                            Income
                        </label>
                    </div>
                    <div>
                        <input
                            id="paymentSetup"
                            type="radio"
                            value="payment"
                            checked={setupType === "payment"}
                            onChange={(e) => {
                                setSetupType(e.target.value);
                            }}
                        />
                        <label
                            className={styles.input__label}
                            htmlFor="paymentSetup"
                        >
                            Prepayment
                        </label>
                    </div>
                </div>
            </div>

            {setupType === 'income' ? (
                <>
                    <h3>Income</h3>
                    <br />
                    <div className={styles.double__inputs}>
                        <div>
                            <label
                                className={styles.input__label}
                                htmlFor="code"
                            >
                                Code
                                <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                                type="text"
                                id="code"
                                name="code"
                                onChange={handleIncomeChange}
                                placeholder="enter code"
                                value={income?.code}
                            />
                        </div>
                        <div>
                            <label className={styles.input__label} htmlFor="descripton">
                                Description/Name
                                <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                                type="text"
                                name="descripton"
                                onChange={handleIncomeChange}
                                id="descripton"
                                placeholder="enter description/ name"
                                value={income?.descripton}
                            />
                        </div>
                    </div>
                    <div className={styles1.add_button__wrapper}>
                        <button
                            disabled={loading}
                            type="submit"
                            className={[
                                "primary__btn",
                                styles1.create__button,
                                styles.primary__button,
                                loading ? styles.loading__button : ""
                            ].join(" ")}
                        >
                            Create
                            {loading && <Spinner />}
                        </button>
                    </div>
                </>
            ) : (
                <>
                    <h3>Prepayments</h3>
                    <br />
                    <div className={styles.double__inputs}>
                        <div>
                            <label
                                className={styles.input__label}
                                htmlFor="code"
                            >
                                Code
                                <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                                type="text"
                                id="code"
                                name="code"
                                onChange={handlePaymentChange}
                                placeholder="enter code"
                                value={payment?.code}
                            />
                        </div>
                        <div>
                            <label className={styles.input__label} htmlFor="descripton">
                                Description/Name
                                <span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                                type="text"
                                name="descripton"
                                onChange={handlePaymentChange}
                                id="descripton"
                                placeholder="enter description/ name"
                                value={payment?.descripton}
                            />
                        </div>
                    </div>
                    <div className={styles1.add_button__wrapper}>
                        <button
                            disabled={loading}
                            type="submit"
                            className={[
                                "primary__btn",
                                styles1.create__button,
                                styles.primary__button,
                                loading ? styles.loading__button : ""
                            ].join(" ")}
                        >

                            {loading ? 'Creating...' : 'Create'}
                            {loading && <Spinner />}
                        </button>
                    </div>
                </>
            )}

        </form>
    )
}

export default HNISetup