/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-new-func */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import styles from "./style.module.css";
import Alert from "../../../components/Alert";
import RemitaForPayment from "../../../assets/Home/remitaLogo.png";
import { useHistory } from "react-router";
import { RevenueHeads } from "../../../payment/components/revenueHeads";
import { Mdas } from "../../../payment/components/mdas";
import constants from "../../../constants";
import arrowLeft from "../../../assets/PAYEarrow.svg";

axios.defaults.testURL = constants.BASE_URL;

var sha512 = require("js-sha512");

const Remitacheckout = ({ data, setModalToShow }) => {
	const user = JSON.parse(localStorage.getItem("user_info"));

	const [amount, setAmount] = useState("");
	const [name, setName] = useState("");
	const [ownersesbn, setownersesbn] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [revenue, setRevenue] = useState("");
	const [getrev, setRevhead] = useState([]);
	const [mda, setMdas] = useState("");
	const [revenueDeets, setRevenueDeets] = useState({
		description: "",
		code: ""
	});
	const [showESBN, toggleShowESBN] = useState("esbn");
	const [showAlert, setShowAlert] = useState(true);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const RevenueHead = async () => {
		const res = await axios
			.get(`/settings/mdalinkedrevenue/${mda}`)
			.then((res) => (res.data || {}).data);
		setRevhead(res);
	};

	useEffect(() => {
		RevenueHead();
	}, [mda]);

	const history = useHistory();
	const apiHash = useRef(null);

	useEffect(() => {
		const getESBN = async () => {
			const res = await axios
				.get(`users/anssid?anssid=${ownersesbn}`)
				.then((res) => (res.data || {}).data);

			if (res.accounttype === "individual") {
				setName(`${res.firstname} ${res.surname}`);
			} else {
				setName(res.name);
			}

			setEmail(res.email);
		};
		if (ownersesbn?.length >= 10) getESBN();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ownersesbn]);

	useEffect(() => {
		const getBillAmount = async () => {
			await axios
				.get(`/billingno/${BillNumber}`)
				.then((res) => {
					if (res?.data?.data[0]?.GrandTotal) {
						setAmount(res.data.data[0].GrandTotal);
					}
				})
				.catch((error) => {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: "Amount generation unsuccessful. Try again"
						});
					}
				});
		};
		if (BillNumber?.length >= 18) getBillAmount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [BillNumber]);

	useEffect(() => {
		const script = document.createElement("script");
		script.src =
			"https://login.remita.net/payment/v1/remita-pay-inline.bundle.js";
		script.async = true;
		document.body.appendChild(script);
		// Cleanup: remove the script when the component is unmounted
	}, []);

	const apiKey = "809567";
	const merchantId = "12422489261";
	const serviceTypeId = "12422453483";
	var d = new Date();

	const generateRemitaRRR = async () => {
		const url =
			"https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit";

		const payerName = name;
		const payerEmail = email;
		const description = "Enugu Tax Payer Bill";

		const consumerToken = sha512(
			merchantId + serviceTypeId + d.getTime() + Number(amount) + apiKey
		);

		apiHash.current = consumerToken;

		const requestData = {
			serviceTypeId: serviceTypeId,
			amount: Number(amount),
			orderId: d.getTime(),
			payerName: payerName,
			payerEmail: payerEmail,
			description: description
		};
		const config = {
			headers: {
				dataType: "json",
				"Content-Type": "application/json",
				Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${consumerToken}`
			}
		};
		try {
			const req = await axios.post(url, requestData, config);
			let RRR;
			const f = new Function("jsonp", `${req.data}`);

			f(function (ref) {
				RRR = ref.RRR;
			});
			return RRR;
		} catch (err) {
			return err;
		}
	};

	function paymentCallback(response) {
		const requestData = {
			paymentgateway: "REMITA",
			paymentoption: showESBN,
			paygatetranid: "REMITA",
			billnumber: BillNumber,
			billername: ownersesbn ? ownersesbn : name,
			billerphoneno: phone,
			billeremail: email,
			amount: amount,
			mda: mda,
			revenue: revenue,
			payload: [
				{
					firstName: name,
					lastName: "",
					narration: "Remita Payment",
					amount: Number(amount),
					transactionId: "",
					processRrr: true,
					extendedData: {
						customFields: [
							{
								name: "payerName",
								value: name
							},
							{
								name: "payerPhoneNumber",
								value: phone
							},
							{
								name: "CustReference",
								value: ownersesbn
							},
							{
								name: "CustomerId",
								value: ownersesbn
							},
							{
								name: "BillNumber",
								value: BillNumber
							},
							{
								name: "AgencyCode",
								value: revenueDeets?.code
									?.toString()
									?.substring(0, 8)
							},
							{
								name: "RevenueCode",
								value: revenueDeets?.code
							}
						]
					}
				}
			]
		};

		// API endpoint
		const apiEndpoint = `${constants.BASE_URL}/taxpaid`;

		// Use Axios to send the POST request
		axios
			.post(apiEndpoint, requestData, {
				headers: {
					"Content-Type": "application/json"
					// Add any additional headers as needed
				}
			})
			.then((response) => {
				console.log("Data sent successfully");
				// Handle success if needed
			})
			.catch((error) => {
				console.error("Failed to send data to the API:", error);
				// Handle errors if needed
			});
	}
	const handleButton = async () => {
		try {
			await paymentCallback();
		} catch (error) {
			// Handle errors from Function 1
			console.error("Error in Function 1:", error);
		}
		// Execute Function 2
		await initializeRemita();
	};

	useEffect(() => {
		const revenueDetails = revenue.split("/");
		setRevenueDeets({
			description: revenueDetails[0],
			code: revenueDetails[1]
		});
	}, [revenue]);

	const publicKey = process.env.REACT_APP_REMITA_PUBLIC_KEY;

	const initializeRemita = async () => {
		try {
			const rrr = await generateRemitaRRR(); //That RRR function I gave you earlier
			const remitaPaymentEngine = window.RmPaymentEngine.init({
				key: publicKey,
				firstName: name,
				lastName: "",
				narration: "Test Remita Payment",
				amount: Number(amount),
				transactionId: "",
				processRrr: true,
				extendedData: {
					customFields: [
						{
							name: "rrr",
							value: rrr
						},
						{
							name: "payerName",
							value: name
						},
						{
							name: "payerPhoneNumber",
							value: phone
						},
						{
							name: "CustReference",
							value: ownersesbn
						},
						{
							name: "CustomerId",
							value: ownersesbn
						},
						{
							name: "BillNumber",
							value: BillNumber
						},
						{
							name: "AgencyCode",
							value: revenueDeets?.code
								?.toString()
								?.substring(0, 8)
						},
						{
							name: "RevenueCode",
							value: revenueDeets?.code
						}
					]
				},
				onSuccess: function (response) {
					paymentCallback(response);
					console.log(response, "onSuccess");
				},
				onError: function (response) {
					console.log(response, "onError");
				},
				onClose: function () {
					console.log("onClose");
				}
			});
			remitaPaymentEngine.showPaymentWidget();
		} catch (error) {}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	//Alert message when the page is loaded
	const CustomAlert = ({ message, onClose }) => {
		return (
			<div className={[styles.custom__alert].join("")}>
				<div className="alert-content">
					<p>{message}</p>
					<button onClick={onClose} className={[styles.btn]}>
						OK
					</button>
				</div>
			</div>
		);
	};

	const handleCloseAlert = () => {
		setShowAlert(false);
	};

	return (
		<>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			<main className={styles.main}>
				<div className={styles.paymentBody}>
					<div
						className={`${styles.paymentIcon} ${styles.remita__header}`}
					>
						<div
							className={styles.goBack}
							onClick={() => setModalToShow("gatewayList")}
						>
							<img
								src={arrowLeft}
								alt="arrowLeftIcon"
								style={{
									width: "30px"
								}}
							/>
						</div>

						<img src={RemitaForPayment} alt="Remita payment" />

						<div></div>
					</div>

					{/* <div
						style={{
							display: "flex",
							justifyContent: "flex-start",
							padding: "0px 30px"
						}}
					>
						<div className={styles.esbn__selector}>
							<input
								type="radio"
								id="esbn"
								name="esbn"
								value="esbn"
								onChange={(e) => toggleShowESBN(e.target.value)}
								checked={showESBN === "esbn"}
							/>
							<label htmlFor="esbn">Pay with ESBN</label>
						</div>

						<div className={styles.esbn__selector}>
							<input
								type="radio"
								id="noEsbn"
								name="noEsbn"
								value="noEsbn"
								onChange={(e) => toggleShowESBN(e.target.value)}
								checked={showESBN === "noEsbn"}
							/>
							<label htmlFor="noEsbn">Pay without ESBN</label>
						</div>
					</div> */}
					{/* ESBN */}
					<hr className={styles.divider} />
					{showESBN === "esbn" && (
						<>
							<div className={styles.wrapper}>
								<div
									className={styles.payments__double__inputs}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="ownersesbn"
										>
											ESBN{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											onChange={(e) =>
												setownersesbn(e.target.value)
											}
											id="ownersesbn"
											placeholder="enter your ESBN"
											required
											value={user?.anssid}
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="BillNumber"
										>
											Bill Number{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											onChange={(e) =>
												setBillNumber(e.target.value)
											}
											id="BillNumber"
											placeholder="Enter bill number"
											required
											value={data?.BillNumber}
										/>
									</div>
								</div>
								<hr className={styles.divider} />

								<div
									className={styles.payments__double__inputs}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="name"
										>
											Name{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={user?.fullname}
											onChange={(e) =>
												setName(e.target.value)
											}
											id="amount"
											placeholder="Enter Name"
											required
											disabled
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="amount"
										>
											Amount{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={data?.totAmtCharged}
											onChange={(e) =>
												setAmount(e.target.value)
											}
											id="amount"
											placeholder="Enter amount"
											required
										/>
									</div>
								</div>
								<br />
								<div
									className={styles.payments__double__inputs}
								>
									<Mdas
										setValue={(val) => setMdas(val)}
										value={mda}
									/>
									<div>
										<label
											className={styles.input__label}
											htmlFor="mda"
										>
											Revenue{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<select
											id="mda"
											className={styles.input__medium}
											name="mda"
											onChange={(e) => {
												setRevenue(e.target.value);
											}}
											value={revenue}
											required
										>
											<option>
												{getrev
													? "--Select Revenue head--"
													: "Loading..."}
											</option>
											{getrev &&
												getrev.map((getRev) => (
													<option value={getRev.Code}>
														{getRev.Description}
													</option>
												))}
										</select>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "23px 30px 0px 0px"
									}}
								>
									{mda && revenue && (
										<button
											onClick={handleButton}
											className={[styles.btn]}
										>
											Pay now
										</button>
									)}
								</div>
							</div>
						</>
					)}
					{/* No ESBN */}
					{showESBN === "noEsbn" && (
						<>
							<div className={styles.wrapper}>
								<div
									className={styles.payments__double__inputs}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="name"
										>
											Name{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={name}
											onChange={(e) =>
												setName(e.target.value)
											}
											id="name"
											placeholder="Enter Name"
											required
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="amount"
										>
											Amount{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={amount}
											onChange={(e) =>
												setAmount(e.target.value)
											}
											id="amount"
											placeholder="Enter amount"
											required
										/>
									</div>
								</div>
								<br />

								<div
									className={styles.payments__double__inputs}
								>
									<div>
										<label
											className={styles.input__label}
											htmlFor="email"
										>
											Email{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={email}
											onChange={(e) =>
												setEmail(e.target.value)
											}
											id="name"
											placeholder="Enter Email"
											required
										/>
									</div>
									<div>
										<label
											className={styles.input__label}
											htmlFor="phone"
										>
											Phone Number{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<input
											type="text"
											value={phone}
											onChange={(e) =>
												setPhone(e.target.value)
											}
											id="amount"
											placeholder="Enter phone number"
											required
										/>
									</div>
								</div>

								<br />
								<div
									className={styles.payments__double__inputs}
								>
									<Mdas
										setValue={(val) => setMdas(val)}
										value={mda}
									/>
									<div>
										<label
											className={styles.input__label}
											htmlFor="mda"
										>
											Revenue{" "}
											<span style={{ color: "red" }}>
												*
											</span>
										</label>
										<select
											id="mda"
											className={styles.input__medium}
											name="mda"
											onChange={(e) => {
												setRevenue(e.target.value);
											}}
											value={revenue}
											required
										>
											<option>
												{getrev
													? "--Select Revenue head--"
													: "Loading..."}
											</option>
											{getrev &&
												getrev.map((getRev) => (
													<option value={getRev.Code}>
														{getRev.Description}
													</option>
												))}
										</select>
									</div>
								</div>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										margin: "23px 30px 0px 0px"
									}}
								>
									{name &&
										amount &&
										email &&
										phone &&
										revenue && (
											<button
												onClick={() => handleButton()}
												className={[styles.btn]}
											>
												Pay now
											</button>
										)}
								</div>
							</div>
						</>
					)}
					{/* {showESBN === "validateRRR" && (
						<div className={styles.wrapper}>
							<div
								className={[
									style.payments__double__inputs
								].join(" ")}
							>
								<div style={{ width: "100%", margin: 0 }}>
									<label
										className={styles.input__label}
										htmlFor="rrrValidation"
									>
										RRR{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										onChange={(e) =>
											setRRRForValidation(e.target.value)
										}
										id="rrrValidation"
										placeholder="Enter your RRR"
										required
										value={rrrForValidation}
									/>
								</div>
							</div>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									margin: "23px 30px 0px 0px"
								}}
							>
								<button
									onClick={() => { }}
									className={[style.btn]}
								>
									Validate RRR
								</button>
							</div>
						</div>
					)} */}
				</div>
			</main>
		</>
	);
};

export default Remitacheckout;
