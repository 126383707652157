import React from "react";
import axios from "axios";
import CreateTaxPayer from "./create";
import { useHistory } from "react-router";
import Modal from "../../components/Modal/regular";
import Table from "../../components/Table";
import Alert from "../../components/Alert";
import anssidStyles from "./style.module.css";
import styles from "../MDAs/style.module.css";
import hniStyle from "../HNI/style.module.css";
import { exportExcel, remapObj } from "../../utils";
import { useState, useEffect, createRef } from "react";
import { formatAmount, formatDate } from "../../utils";
import styles1 from "../../Dashboard/MDAs/style.module.css";
import MDAstyles from "../../Dashboard/MDAs/style.module.css";
import approvedCheck from "../../assets/Certificate/approvedCheck.svg";
import failureCheck from "../../assets/Certificate/failureCheck.svg";

const user = JSON.parse(localStorage.getItem("user_info"));
const headers = [
	["ESBN", "OwnersESBN"],
	["Bill Number", "BillNumber"],
	["Shop Owner's Name", "ownersName"],
	["Line of Business", "residentialaddress"],
	["Shop Number", "shopnumber"],
	["Amount", "GrandTotal", formatAmount],
	["Email", "OwnerEmail"],
	["Phone", "OwnersPhone"],
	// ["Tax Zone", "TaxZone"],
	// ["Tax LGA", "TaxLGA"],s
	// ["Tax Office", "TaxOffice"],
	// ["House Number", "HouseNo"],
	// ["House Street", "HouseStreet"],
	["Created At", "createdAt", formatDate],
	["Payment Status", "PaymentStatus"],
	["Process Status", "ApproveFlg", getStatusText],
	["Inputter", "Inputter"]
];

function getStatusText(approveRejectFlag) {
	// 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval; 6: Deleted
	if (approveRejectFlag === "0") {
		return "Pending";
	} else if (approveRejectFlag === "1") {
		return "Cancelled";
	} else if (approveRejectFlag === "2") {
		return "Declined";
	} else if (approveRejectFlag === "3") {
		return "Approved";
	} else if (approveRejectFlag === "4") {
		return "Revoked";
	} else if (approveRejectFlag === "5") {
		return "Pending Approval";
	}
	else {
		return "Deleted";
	}
}

const AuthoriseNewHaven = () => {
	const history = useHistory();

	const [createModal, setCreateModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [OwnersESBN, setOwnersESBN] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [ownersName, setOwnersName] = useState("");
	const [Name, setName] = useState("");
	const [residentialaddress, setresidentialaddress] = useState("");
	// const [OwnersPhone, setOwnersPhone] = useState("");
	const [Inputter, setInputter] = useState("");
	const [PropertyId, setPropertyId] = useState("");
	// const [shopNum, setShopNum] = useState("");
	const [shopnumber, setshopnumber] = useState("");
	const [houseStreet, setHouseStreet] = useState("");
	const [taxZone, setTaxZone] = useState("");
	const [allTaxZones, setAllTaxZones] = useState([]);
	const [taxLGA, setTaxLGA] = useState("");
	const [allTaxLGAs, setAllTaxLGAs] = useState([]);
	const [taxOffice, setTaxOffice] = useState("");
	const [allTaxOffices, setAllTaxOffices] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState("");
	const [processStatus, setProcessStatus] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const filterRef = createRef();

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	// New Approval system start
	const [viewModalShowing, setViewModalShowing] = useState(false);
	const [loadingCert, setLoadingCert] = useState(false);
	const [certificate, setCertificate] = useState(null);
	const [reviewedCertDetails, setReviewedCertDetails] = useState(null);
	const [approveModalShowing, setApproveModalShowing] = useState(false);
	const [approving, setApproving] = useState(false);
	const [
		approvalSuccessModalShowing,
		setApprovalSuccessModalShowing
	] = useState(false);
	const [
		rejectModalShowing,
		setRejectModalShowing
	] = useState(false);
	const [
		revokeModalShowing,
		setRevoketModalShowing
	] = useState(false);
	const [rejecting, setRejecting] = useState(false);
	const [
		rejectionSuccessModalShowing,
		setRejectionSuccessModalShowing
	] = useState(false);

	const [revokeReason, setRevokeReason] = useState("");
	const [revoking, setRevoking] = useState(false);
	const [
		revocationSuccessModalShowing,
		setRevocationSuccessModalShowing
	] = useState(false);

	const [rejectReason, setRejectReason] = useState("");

	const reject = async (e) => {
		e?.preventDefault();
		setRejecting(true);
		try {
			const res = await axios
				.post(`processmda`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "2",
					coyid: user?.mdaModuleCoy,
					recId: reviewedCertDetails?.id,
					reason4Reject: rejectReason
				})
				.then((res) => res.data);

			setRejectionSuccessModalShowing(false);
			setRejectReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});
			getProfile()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const approve = async () => {
		setApproving(true);
		setApproveModalShowing(false);

		try {
			await axios
				.post(`processmda`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "3",          // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked
					recId: reviewedCertDetails?.id,
					coyid: user?.mdaModuleCoy,
					reason4Reject: "test"
				})
				.then((res) => res.data);

			setViewModalShowing(false);
			setApprovalSuccessModalShowing(true);
			getProfile()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setApproving(false);
		}
	};

	const revoke = async (e) => {
		e?.preventDefault();
		setRevoking(true);

		try {
			const res = await axios
				.post(`processmda`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "4",
					recId: reviewedCertDetails?.id,
					coyid: user?.mdaModuleCoy,
					reason4Reject: revokeReason
				})
				.then((res) => res.data);

			setRevocationSuccessModalShowing(false);
			setRevokeReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});
			getProfile()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const handleView = (data) => {
		viewCertificate(data.nb, data?.id, 1);
		setReviewedCertDetails({
			billNumber: data.nb,
			Name: data.Name || data?.OwnersName,
			id: data?.id,
			status: getStatusText(data.ApproveFlg)
		});
	}
	
	const viewCertificate = async (billNumber, id, viewMode = 1) => {
		if (viewMode === 1) {
			setLoadingCert(true);
			setViewModalShowing(true);
		}

		try {
			const data = await axios
				.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}/1`, {
					responseType: viewMode === 2 ? "blob" : "json"
				})
				.then((res) => res.data);

			if (viewMode === 1) {
				setCertificate(data);
			} else {
				setViewModalShowing(false);
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: data.msg
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.msg ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoadingCert(false);
		}
	};
	// New Approval system end

	const getProfile =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
			async (e) => {
				e?.preventDefault?.();

				setLoading(true);
				if (!filterRef?.current?.classList?.contains(styles.no__display)) {
					filterRef?.current?.classList?.toggle(styles.no__display);
				}

				let query = `page=${currentPage}&pageSize=${pageSize}&`;
				if (ownersName) {
					query += `Name=${ownersName}&`;
				}

				if (OwnersESBN) {
					query += `ESBN=${OwnersESBN}&`;
				}

				if (residentialaddress) {
					query += `lineofbiz=${residentialaddress}&`;
				}

				if (shopnumber) {
					query += `shopno=${shopnumber}&`;
				}

				if (BillNumber) {
					query += `BillNo=${BillNumber}&`;
				}

				if (startDate) {
					query += `startdate=${startDate}&`;
				}

				if (endDate) {
					query += `enddate=${endDate}&`;
				}

				if (Inputter) {
					query += `Inputter=${Inputter}&`;
				}

				if (PropertyId) {
					query += `propertyId=${PropertyId}&`;
				}

				if (houseStreet) {
					query += `houseStreet=${houseStreet}&`;
				}

				if (taxOffice) {
					query += `taxOffice=${taxOffice}&`;
				}

				if (taxZone) {
					query += `TaxZone=${taxZone}&`;
				}

				if (taxLGA) {
					query += `TaxLGA=${taxLGA}&`;
				}

				if (paymentStatus) {
					query += `paymentStatus=${paymentStatus}&`;
				}

				if (processStatus) {
					query += `processStatus=${processStatus}&`;
				}

				if (minAmount) {
					query += `minAmount=${minAmount}&`;
				}

				if (maxAmount) {
					query += `maxAmount=${maxAmount}&`;
				}

				try {
					const accessToken = localStorage.getItem("access_token");
					let data = await axios
						.get(
							`mdareports/mdabilldisplay/${user?.mdaModuleCoy}?${query}`,
							{
								headers: {
									Authorization: `Bearer ${accessToken}`
								}
							}
						)
						.then((res) => res.data || { data: {} });

					if (!data || !data?.data) {
						// handle errors
						return null;
					}

					if (!download) setData(data.data);
					setTotal(data?.total);

					setData(data.data);
					setTotal(data?.total);
					return data.data;
				} catch (error) {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response?.data?.msg ||
								error.response?.data?.errors?.[0] ||
								error.response?.data?.errors?.[0]?.message ||
								error.response?.data?.errors?.details[0]?.message ||
								error.response?.message ||
								error.response?.data?.message
						});
					} else {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: error.response?.data?.msg
						});
					}
				} finally {
					setLoading(false);
				}
			};

	const getAllTaxOffices = async () => {
		try {
			const offices = await axios
				.get(`settings/assesstaxoffice`)
				.then((res) => res.data);

			setAllTaxOffices(offices.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response?.data?.msg ||
						error.response?.data?.errors?.[0] ||
						error.response?.data?.errors?.[0]?.message ||
						error.response?.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const getAllTaxLGAs = async () => {
		try {
			const LGAs = await axios
				.get(`settings/lgass/01`)
				.then((res) => res.data);

			setAllTaxLGAs(LGAs.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response?.data?.msg ||
						error.response?.data?.errors?.[0] ||
						error.response?.data?.errors?.[0]?.message ||
						error.response?.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		}
	};

	const getAllTaxZones = async () => {
		try {
			const zones = await axios
				.get(`settings/zones/${user?.mdaModuleCoy}`)
				.then((res) => res.data);

			setAllTaxZones(zones.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response?.data?.msg ||
						error.response?.data?.errors?.[0] ||
						error.response?.data?.errors?.[0]?.message ||
						error.response?.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		}
	};

	const exportAnalytics = async () => {
		const info = await getProfile({
			currentPage: 1,
			pageSize: total,
			download: true
		})();
		exportExcel(remapObj(info, headers), headers, `New Haven Report.xlsx`);
	};

	const clearFilters = () => {
		setOwnersESBN("");
		setStartDate("");
		setEndDate("");
		setOwnersName("");
		setName("");
		setresidentialaddress("");
		setshopnumber();
		// setOwnerEmail("");
		// setOwnersPhone("");
		setBillNumber("");
		setInputter("");
		setPropertyId("");
		setHouseStreet("");
		setTaxOffice("");
		setTaxLGA("");
		setTaxZone("");
		setProcessStatus("");
		setPaymentStatus("");
		setMinAmount("");
		setMaxAmount("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getProfile()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getProfile()();
		getAllTaxLGAs();
		getAllTaxZones();
		getAllTaxOffices();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles1.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{viewModalShowing && (
				<Modal toggle={setViewModalShowing} showing={viewModalShowing}>
					{loadingCert ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<>
							{reviewedCertDetails.status === "Pending Approval" && (
								<p className={styles.review__instructions}>
									Please review the form details below. Once
									you have completed your review, select
									either "Approve" or "Decline" to proceed with
									your decision.
								</p>
							)}
							<div
								dangerouslySetInnerHTML={{
									__html: certificate
								}}
							></div>
							{reviewedCertDetails.status === "Pending Approval" && (
								<div className={hniStyle.review__btns}>
									<button
										className="tertiary__btn"
										onClick={() =>
											setRejectModalShowing(true)
										}
										disabled={approveModalShowing}
									>
										{rejecting ? "Declining..." : "Decline"}
									</button>
									<button
										className="primary__btn"
										onClick={() =>
											setApproveModalShowing(true)
										}
										disabled={rejectModalShowing}
									>
										{approving ? "Approving..." : "Approve"}
									</button>
									{/* Idiot proof modal for approval */}
									{approveModalShowing && (
										<div
											className={`${hniStyle.idiotProof__modal} ${hniStyle.approve__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Approve</span> this Bill
												with{" "}
												<b>
													{reviewedCertDetails.billNumber}
												</b>{" "}
												Bill Number?
											</p>
											<div
												className={
													hniStyle.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setApproveModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => approve()}
													disabled={approving}
												>
													Yes
												</button>
											</div>
										</div>
									)}
									{/* Idiot proof modal for rejection */}
									{rejectModalShowing && (
										<div
											className={`${hniStyle.idiotProof__modal} ${hniStyle.reject__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Reject</span> this Bill
												with{" "}
												<b>
													{reviewedCertDetails.ESBN}
												</b>{" "}
												bill number?
											</p>
											<div
												className={
													hniStyle.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setRejectModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => {
														setRejectionSuccessModalShowing(
															true
														);
														setRejectModalShowing(
															false
														);
														setViewModalShowing(
															false
														);
													}}
												>
													Yes
												</button>
											</div>
										</div>
									)}
								</div>
							)}

							{reviewedCertDetails.status === "Approved" && (
								<div className={hniStyle.review__btns}>
									<button
                                            className="btn btn-danger"
                                            style={{
                                                padding: '12px',
                                                height: 'fit-content'
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setViewModalShowing(false);
                                            }}
                                        >
                                            {"Close"}
                                        </button>
									<button
										className="tertiary__btn"
										onClick={() =>
											setRevoketModalShowing(true)
										}
										disabled={approveModalShowing || rejectModalShowing}
									>
										{revoking ? "Revoking..." : "Revoke"}
									</button>
									{/* Idiot proof modal for revocation */}
									{revokeModalShowing && (
										<div
											className={`${hniStyle.idiotProof__modal} ${hniStyle.reject__modal}`}
										>
											<p>
												Are you sure you want to{" "}
												<span>Revoke</span> this Bill
												with{" "}
												<b>
													{reviewedCertDetails.ESBN}
												</b>{" "}
												bill number?
											</p>
											<div
												className={
													hniStyle.idiotProof__btns
												}
											>
												<button
													onClick={() => {
														setRevoketModalShowing(
															false
														);
													}}
												>
													No
												</button>
												<button
													onClick={() => {
														setRevocationSuccessModalShowing(
															true
														);
														setRevoketModalShowing(
															false
														);
														setViewModalShowing(
															false
														);
													}}
												>
													Yes
												</button>
											</div>
										</div>
									)}
								</div>
							)}
						</>
					)}
				</Modal>
			)}
			{/* approval success modal */}
			{approvalSuccessModalShowing && (
				<Modal>
					<div className={hniStyle.approvalSuccess__badge}>
						<img
							src={approvedCheck}
							alt="circle with a tick check inside"
						/>
						<span>APPROVED</span>
					</div>
					<p className={hniStyle.approve__success_message}>
						You have successfully approved{" "}
						<b>{reviewedCertDetails?.name}</b>'s Bill, an email would be sent to notify them
						accordingly.
					</p>
					<button
						className={hniStyle.continue__btn}
						onClick={() => {
							setReviewedCertDetails(null);
							setApprovalSuccessModalShowing(false);
						}}
					>
						Continue
					</button>
				</Modal>
			)}
			{/* rejection success modal */}
			{rejectionSuccessModalShowing && (
				<Modal
					showing={rejectionSuccessModalShowing}
					toggle={setRejectionSuccessModalShowing}
				>
					<div className={hniStyle.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>REJECTION</span>
					</div>
					<p className={hniStyle.reject__reason_instruction}>
						You are about to reject{" "}
						<b>{reviewedCertDetails?.Name || reviewedCertDetails?.name}</b>'s Bill. Please, state your reason.
					</p>
					<form
						onSubmit={(e) => reject(e)}
						className={hniStyle.reject__reason_form}
					>
						<label htmlFor="rejectReason">
							Reason for Rejecting{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="rejectReason"
							placeholder="write the reason for rejecting this Bill"
							value={rejectReason}
							onChange={(e) => setRejectReason(e.target.value)}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={!rejectReason || rejecting}
						>
							{rejecting ? "Continuing..." : "Continue"}
						</button>
					</form>
				</Modal>
			)}
			{/* rejection success modal */}
			{revocationSuccessModalShowing && (
				<Modal
					showing={revocationSuccessModalShowing}
					toggle={setRevocationSuccessModalShowing}
				>
					<div className={hniStyle.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>REJECTION</span>
					</div>
					<p className={hniStyle.reject__reason_instruction}>
						You are about to revoke{" "}
						<b>{reviewedCertDetails?.Name || reviewedCertDetails?.name}</b>'s Bill. Please, state your reason.
					</p>
					<form
						onSubmit={(e) => revoke(e)}
						className={hniStyle.reject__reason_form}
					>
						<label htmlFor="revokeReason">
							Reason for Revocation{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="revokeReason"
							placeholder="write the reason for revoking this Bill"
							value={revokeReason}
							onChange={(e) => setRevokeReason(e.target.value)}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={!revokeReason || revoking}
						>
							{revoking ? "Continuing..." : "Continue"}
						</button>
					</form>
				</Modal>
			)}
			<div className={styles.actions__div}>
				<button
					style={{
						padding: "0 25px"
					}}
					className={["primary__btn", anssidStyles.create__btn].join(
						" "
					)}
					onClick={() => {
						history.push("/multi-company/create-new-haven", {
							// taxpayers: true,
							// fromIndividual: true,
							// ssActive: "1"
						});
					}}
				>
					Prepare bill
				</button>
				<button
					className={[
						"primary__btn",
						MDAstyles.download__button
					].join(" ")}
					onClick={() => exportAnalytics()}
				// disabled={loading}
				>
					Download Report
				</button>

				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!OwnersESBN &&
								!startDate &&
								!endDate &&
								!ownersName &&
								!Name &&
								!residentialaddress &&
								shopnumber &&
								// !OwnerEmail &&
								// !OwnersPhone &&
								!BillNumber &&
								!Inputter &&
								// !PropertyId &&
								!houseStreet &&
								!taxOffice &&
								!taxZone &&
								!taxLGA &&
								!processStatus &&
								!paymentStatus &&
								!minAmount &&
								!maxAmount
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="owneresbn">ESBN</label>
								<input
									name="owneresbn"
									id="owneresbn"
									type="text"
									value={OwnersESBN}
									onChange={(e) =>
										setOwnersESBN(e.target.value)
									}
									placeholder="Eg. 00909986588"
								/>
							</div>
							<div>
								<label htmlFor="billNumber">Bill Number</label>
								<input
									name="billNumber"
									id="billNumber"
									type="text"
									value={BillNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Eg. AB2024155662EN"
								/>
							</div>
						</form>
						<p>By Owner Details</p>
						<form>
							<div>
								<label htmlFor="ownersName">Owner's Name</label>
								<input
									name="ownersName"
									id="ownersName"
									type="text"
									value={ownersName}
									onChange={(e) =>
										setOwnersName(e.target.value)
									}
									placeholder="Eg. Odogwu Okeke"
								/>
							</div>
							{/* <div>
								<label htmlFor="Name">Owner Name Email</label>
								<input
									name="Name"
									id="Name"
									type="email"
									value={Name}
									onChange={(e) => setName(e.target.value)}
									placeholder="Eg. odogwu@okeke.com"
								/>
							</div> */}
							{/* <div>
								<label htmlFor="OwnersPhone">Owner Phone</label>
								<input
									name="OwnersPhone"
									id="OwnersPhone"
									type="tel"
									value={OwnersPhone}
									onChange={(e) =>
										setOwnersPhone(e.target.value)
									}
									placeholder="Eg. 09333348848"
								/>
							</div> */}
							<div>
								<label htmlFor="residentialaddress">
									Line Of Business
								</label>
								<input
									name="residentialaddress"
									id="residentialaddress"
									type="text"
									value={residentialaddress}
									onChange={(e) =>
										setresidentialaddress(e.target.value)
									}
									placeholder="Eg. 4567"
								/>
							</div>
							<div>
								<label htmlFor="shopnumber">Shop Number</label>
								<input
									name="shopnumber"
									id="shopnumber"
									type="text"
									value={shopnumber}
									onChange={(e) =>
										setshopnumber(e.target.value)
									}
									placeholder="Eg. No. 34 Odimegwu"
								/>
							</div>
							{/* <div>
								<label htmlFor="houseStreet">
									House Street
								</label>
								<input
									name="houseStreet"
									id="houseStreet"
									type="text"
									value={houseStreet}
									onChange={(e) =>
										setHouseStreet(e.target.value)
									}
									placeholder="Eg. Uduma Street"
								/>
							</div> */}
							<div>
								<label htmlFor="taxLGA">Tax LGA</label>
								<select
									name="taxLGA"
									id="taxLGA"
									value={taxLGA}
									onChange={(e) => setTaxLGA(e.target.value)}
								>
									<option value="">--select tax LGA--</option>
									{allTaxLGAs?.map((LGA, i) => (
										<option key={i}>
											{LGA.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="taxZone">Tax Zone</label>
								<select
									name="taxZone"
									id="taxZone"
									value={taxZone}
									onChange={(e) => setTaxZone(e.target.value)}
								>
									<option value="">
										--select tax zone--
									</option>
									{allTaxZones?.map((zone, i) => (
										<option key={i}>
											{zone.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="taxOffice">Tax Office</label>
								<select
									name="taxOffice"
									id="taxOffice"
									value={taxOffice}
									onChange={(e) =>
										setTaxOffice(e.target.value)
									}
								>
									<option value="">
										--select tax office--
									</option>
									{allTaxOffices?.map((office, i) => (
										<option key={i}>
											{office.Description}
										</option>
									))}
								</select>
							</div>
						</form>
						<p>By Amount</p>
						<form>
							<div>
								<label htmlFor="minAmount">Min Amount</label>
								<input
									name="minAmount"
									id="minAmount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="₦ 100, 000"
								/>
							</div>
							<div>
								<label htmlFor="maxAmount">Max Amount</label>
								<input
									name="maxAmount"
									id="maxAmount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="₦ 1, 000, 000"
								/>
							</div>
						</form>
						<p>By Status</p>
						<form>
							<div>
								<label htmlFor="processStatus">
									Process Status
								</label>
								<select
									name="processStatus"
									id="processStatus"
									value={processStatus}
									onChange={(e) =>
										setProcessStatus(e.target.value)
									}
								>
									<option value="">
										--select process status--
									</option>
									<option>Approved</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
							<div>
								<label htmlFor="paymentStatus">
									Payment Status
								</label>
								<select
									name="paymentStatus"
									id="paymentStatus"
									value={paymentStatus}
									onChange={(e) =>
										setPaymentStatus(e.target.value)
									}
								>
									<option value="">
										--select payment status--
									</option>
									<option>Paid</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
						</form>
						<p>By Inputter</p>
						<form>
							<div>
								<label htmlFor="Inputter">Inputter</label>
								<input
									name="Inputter"
									id="Inputter"
									type="email"
									value={Inputter}
									onChange={(e) =>
										setInputter(e.target.value)
									}
									placeholder="Eg. ada@example.com"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getProfile()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getProfile}
						onEdit={(data) => {
							Number(data?.ApproveFlg) <= 2 ?
								history.push(`/multi-company/billing4NewHaven/edit`, {
									coy: data.CoyId,
									userId: data.nb
								}) :
								setAlert({
									showing: true,
									type: 'error',
									message:
										`${getStatusText(data.ApproveFlg)} Bill cannot be edited.`
								})
						}}
						onView={(data) => {
							Number(data?.ApproveFlg) !== 6 ?
								handleView(data) :
								setAlert({
									showing: true,
									type: 'error',
									message:
										`${getStatusText(data.ApproveFlg)} Bill cannot be viewed.`
								})
						}}
					/>
				)}
			</div>
			{createModal && (
				<Modal small showing={createModal} toggle={setCreateModal}>
					<CreateTaxPayer />
				</Modal>
			)}
		</div>
	);
};

export default AuthoriseNewHaven;
