import {
	Switch,
	BrowserRouter as Router,
	Route,
	Redirect
} from "react-router-dom";
import Login from "./Auth/Login/Login";
import Signup from "./Auth/SignUp/signup";
import ResetPassword from "./Auth/ResetPassword";
import IndividualIdentityManagement from "./Identity-management-individual";
import CorporateIdentityManagement from "./Identity-management-corporate";
import CorporateIdentityManagementSuccess from "./Identity-management-corporate/final";
import IndividualIdentityManagementSuccess from "./Identity-management-individual/final";
import Otp from "./Auth/OTP";
import Dashboard from "./Dashboard";
import Home from "./Home";
import Unifiedpayment from "./payment/unifiedpayment";
import Flutterwave from "./payment/flutterwave";
import Interswitch from "./payment/interswitchpay";
import RemitaCheckout from "./payment/remita";
import ETranzact from "./payment/etranzact";
import MoniePoint from "./payment/moniepoint";
import TaxType from "./Home/tax-type";
import TaxSystem from "./Home/tax-system";
import TaxCollection from "./Home/tax-collection";
import TaxLegislation from "./Home/tax-legislation";
import AboutUs from "./Home/about-us";
import FaqPage from "./Home/faqPage";
import ConsolidatedSteps from "./Home/consolidated-steps";
import NewsPage from "./Home/newsPage";
import TCCVerification from "./TCC-Verification";

function App() {
	const accessToken = localStorage.getItem("access_token");
	let userInfo = localStorage.getItem("user_info");
	if (userInfo) {
		userInfo = JSON.parse(userInfo);
	}

	return (
		<Router>
			<Switch>
				<Route path="/home">
					<Home />
				</Route>
				<Route path="/enugutccverification">
					<TCCVerification />
				</Route>
				<Route path="/tax-type">
					<TaxType />
				</Route>
				<Route path="/tax-system">
					<TaxSystem />
				</Route>
				<Route path="/tax-collection">
					<TaxCollection />
				</Route>
				<Route path="/tax-legislation">
					<TaxLegislation />
				</Route>
				<Route path="/about-us">
					<AboutUs />
				</Route>
				<Route path="/frequently-asked-questions">
					<FaqPage />
				</Route>
				<Route path="/latest-news">
					<NewsPage />
				</Route>
				<Route path="/steps-to-pay-bills">
					<ConsolidatedSteps />
				</Route>

				<Route path="/identity-management">
					{userInfo?.account_type === "corporate" ? (
						<CorporateIdentityManagement />
					) : (
						<IndividualIdentityManagement />
					)}
				</Route>
				<Route path="/CIM-success">
					<CorporateIdentityManagementSuccess />
				</Route>
				<Route path="/IM-success">
					<IndividualIdentityManagementSuccess />
				</Route>
				<Route path="/login">
					<Login />
				</Route>
				{/* <Route path="/addnew">
					<Addnew />
				</Route> */}
				<Route path="/pay/unifiedpayment">
					<Unifiedpayment />
				</Route>
				<Route path="/pay/flutterwave">
					<Flutterwave />
				</Route>
				<Route path="/pay/interswitch">
					<Interswitch />
				</Route>
				<Route path="/pay/moniepoint">
					<MoniePoint />
				</Route>
				<Route path="/pay/remita">
					<RemitaCheckout />
				</Route>
				<Route path="/pay/etranzact">
					<ETranzact />
				</Route>
				<Route path="/reset-password">
					<ResetPassword />
				</Route>
				<Route path="/signup">
					<Signup />
				</Route>
				<Route path="/otp-phone">
					<Otp title="Phone" />
				</Route>
				<Route path="/otp-email">
					<Otp title="Email" />
				</Route>
				<Route path="/individual-identity-management">
					{!/^(individual)|(corporate)$/i.test(
						userInfo?.account_type
					) ? (
						<IndividualIdentityManagement />
					) : (
						<div>You should not be here...</div>
					)}
				</Route>
				<Route path="/corporate-identity-management">
					<CorporateIdentityManagement />
				</Route>
				<Route path="/">
					{(userInfo?.account_type &&
						!/^(individual)|(corporate)$/i.test(
							userInfo?.account_type
						)) ||
					userInfo?.anssid ? (
						<Dashboard />
					) : accessToken ? (
						<Redirect to="/identity-management" />
					) : (
						<Redirect to="/home" />
					)}
				</Route>
			</Switch>
		</Router>
	);
}

export default App;
