import React, { createRef, useEffect, useState } from 'react'
import axios from 'axios';
import { useHistory } from "react-router";
import Table from '../../components/Table';
import styles from "../MDAs/style.module.css";
import styles2 from "./style.module.css";
import { formatAmount, formatDate } from '../../utils';
import Alert from '../../components/Alert';
import Modal from "../../components/Modal/regular";
import downloadIcon from "../../assets/whiteDownloadIcon.svg";
import failureCheck from "../../assets/Certificate/failureCheck.svg";

const headers = [
    ["Individuals Name", "Name"],
    ["ESBN", "ESBN"],
    ["Bill Number", "BillNumber"],
    ["Generated By", "Inputter"],
    ["Total Tax Due", "AnnualTaxDue", formatAmount],
    ["Total Income", "TaxableIncome", formatAmount],
    ["Individual Address", "Address"],
    ["Payment Status", "PaymentStatus"],
    ["Process Status", "ApproveFlg", getStatusText],
    ["Reason for rejection", "RejectReason"],
    ["Date Issued", "createdAt", formatDate]
];

function getStatusText(approveRejectFlag) {
    // 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval; 6: Deleted
    if (approveRejectFlag === "0") {
        return "Pending";
    } else if (approveRejectFlag === "1") {
        return "Cancelled";
    } else if (approveRejectFlag === "2") {
        return "Declined";
    } else if (approveRejectFlag === "3") {
        return "Approved";
    } else if (approveRejectFlag === "4") {
        return "Revoked";
    } else if (approveRejectFlag === "5") {
        return "Pending Approval";
    }
    else {
        return "Deleted";
    }
}

const userInfo = JSON.parse(localStorage.getItem("user_info"));

const HNIHome = () => {
    const history = useHistory();

    const [data, setData] = useState(null);
    const [total, setTotal] = useState(0);

    // Filter Params start
    const [taxZone, setTaxZone] = useState("");
    const [taxOffice, setTaxOffice] = useState("");
    const [minAmount, setMinAmount] = useState("");
    const [maxAmount, setMaxAmount] = useState("");
    const [address, setAddress] = useState("");
    const [inputter, setInputter] = useState("");
    const [taxLGA, setTaxLGA] = useState('');
    const [BillNumber, setBillNumber] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState("");
    const [ownersname, setownersname] = useState("");
    const [OwnersESBN, setOwnersESBN] = useState("");
    const [filtersCleared, setFiltersCleared] = useState(false);
    // Filter Params end

    const [reviewedCertDetails, setReviewedCertDetails] = useState(null);
    const [loadingCert, setLoadingCert] = useState(false);
    const [certificate, setCertificate] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [sendingToMail, setSendingToMail] = useState(false);
    const [sendingForApproval, setSendingForApproval] = useState(false);
    const [cancelApprovalRequest, setCancelApprovalRequest] = useState(false);
    const [deletionApprovalRequest, setDeleteApprovalRequest] = useState(false);
    const [approvalCertDetails, setApprovalCertDetails] = useState(null);
    const [rejectionSuccessModalShowing, setRejectionSuccessModalShowing] = useState(false);
    const [deletionSuccessModalShowing, setDeletionSuccessModalShowing] = useState(false);
    const [rejectReason, setRejectReason] = useState(" ");
    const [deletionReason, setDeletionReason] = useState(" ");

    const [viewModalShowing, setViewModalShowing] = useState(false);
    const filterRef = createRef();

    const [alert, setAlert] = useState({
        showing: false,
        type: null,
        message: ""
    });

    const getHNI =
        ({
            currentPage = 1,
            pageSize = 20,
            download = false,
            printing = false
        } = {}) =>
            async (e) => {
                e?.preventDefault?.();

                setLoading(true);
                if (!filterRef?.current?.classList?.contains(styles.no__display)) {
                    filterRef?.current?.classList?.toggle(styles.no__display);
                }

                let query = `page=${currentPage}&pageSize=${pageSize}&`;
                if (ownersname) {
                    query += `Name=${ownersname}&`;
                }

                if (OwnersESBN) {
                    query += `ESBN=${OwnersESBN}&`;
                }

                if (startDate) {
                    query += `startdate=${startDate}&`;
                }

                if (endDate) {
                    query += `enddate=${endDate}&`;
                }

                if (inputter) {
                    query += `inputter=${inputter}&`;
                }

                if (taxZone) {
                    query += `TaxZone=${taxZone}&`;
                }

                if (taxOffice) {
                    query += `taxOffice=${taxOffice}&`;
                }

                if (address) {
                    query += `Address=${address}&`;
                }

                if (BillNumber) {
                    query += `BillNo=${BillNumber}&`;
                }

                if (taxLGA) {
                    query += `TaxLGA=${taxLGA}&`;
                }

                if (minAmount) {
                    query += `minAmount=${minAmount}&`;
                }

                if (maxAmount) {
                    query += `maxAmount=${maxAmount}&`;
                }

                try {
                    const accessToken = localStorage.getItem("access_token");
                    let data = await axios
                        .get(
                            `allhni?${query}`,
                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`
                                }
                            }
                        )
                        .then((res) => res.data || { data: {} });
                    if (!data || !data?.data) {
                        // handle errors
                        return null;
                    }

                    if (!download && !printing) setData(data.data);
                    setTotal(data?.total);

                    return data.data;
                } catch (error) {
                    if (error.response) {
                        setAlert({
                            ...alert,
                            showing: true,
                            type: "error",
                            message:
                                error.response.data?.msg ||
                                error.response.data?.errors?.[0] ||
                                error.response.data?.errors?.[0]?.message ||
                                error.response.data?.errors?.details[0]?.message ||
                                error.response.message ||
                                error.response.data.message
                        });
                    }
                } finally {
                    setLoading(false);
                }
            };

    const viewCertificate = async (billNumber, id, viewMode) => {
        if (viewMode === 1) {
            setLoadingCert(true);
            setViewModalShowing(true);
        } else if (viewMode === 2) {
            setDownloading(true);
        } else {
            setSendingToMail(true);
        }

        try {
            const data = await axios
                .get(`billing/${billNumber}/${viewMode}`, {
                    responseType: viewMode === 2 ? "blob" : "json"
                })
                .then((res) => res.data);

            if (viewMode === 1) {
                setCertificate(data);
                setApprovalCertDetails({
                    billno: billNumber,
                    recId: id,
                    reason4Reject: rejectReason
                });
            } else if (viewMode === 2) {
                const blobURL = URL.createObjectURL(data);

                const element = document.createElement("a");

                element.href = blobURL;
                element.download = `HNI Bill.pdf`;
                document.body.appendChild(element);
                element.click();

                document.body.removeChild(element);

                URL.revokeObjectURL(blobURL);
            } else {
                setViewModalShowing(false);
                setAlert({
                    ...alert,
                    showing: true,
                    type: "success",
                    message: data.msg
                });
            }
        } catch (error) {
            if (error.response) {
                setAlert({
                    ...alert,
                    showing: true,
                    type: "error",
                    message:
                        error.response.data?.msg ||
                        error.response.data?.errors?.[0] ||
                        error.response.data?.errors?.[0]?.message ||
                        error.response.data?.errors?.details[0]?.message ||
                        error.response?.data?.message
                });
            } else {
                console.error(error.message);
                setAlert({
                    ...alert,
                    showing: true,
                    type: "error",
                    message: error.message
                });
            }
        } finally {
            setLoadingCert(false);
            setDownloading(false);
            setSendingToMail(false);
        }
    };

    const requestForApproval = async (approvalCode, reason = '') => {
        approvalCode === "5"
            ? setSendingForApproval(true)
            : approvalCode === "1" ?
                setDeleteApprovalRequest(true)
                : setCancelApprovalRequest(true)

        try {
            const res = await axios.post(`/processhni`, {
                ...approvalCertDetails,
                modifier: userInfo?.email,
                approve: approvalCode,
                reason4Reject: reason,
            });

            setViewModalShowing(false);
            getHNI()();
            setRejectionSuccessModalShowing(false);
            setDeletionSuccessModalShowing(false);
            setDeleteApprovalRequest(false);
            setAlert((alert) => ({
                ...alert,
                showing: true,
                type: "success",
                message: res.data.msg
            }));
            setApprovalCertDetails(null);
        } catch (error) {
            if (error.response) {
                setAlert((alert) => ({
                    ...alert,
                    showing: true,
                    type: "error",
                    message:
                        error.response.data?.msg ||
                        error.response.data?.errors?.[0]?.message ||
                        error.response.data?.errors?.details[0]?.message ||
                        error.response?.data?.message ||
                        error.response?.data.err.message
                }));
            } else if (error.request) {
                setAlert((alert) => ({
                    ...alert,
                    showing: true,
                    type: "error",
                    message: error.request
                }));
            } else {
                console.log("Error", error.message);
                setAlert((alert) => ({
                    ...alert,
                    showing: true,
                    type: "error",
                    message: error.message
                }));
            }
        } finally {
            approvalCode === "0"
                ? setSendingForApproval(false)
                : approvalCode === "1" ?
                    setDeleteApprovalRequest(false)
                    : setCancelApprovalRequest(false)
        }
    };

    const printCertificate = () => {
        const w = window.open();
        w.document.write(certificate);
        w.document.close();
        w.onload = () => {
            w.focus();
            w.print();
        };

        w.onafterprint = () => {
            w.close();
        };
    };

    const clearFilters = () => {
        setOwnersESBN("");
        setAddress("");
        setStartDate("");
        setEndDate("");
        setownersname("");
        setBillNumber("");
        setInputter("");
        setTaxOffice("");
        setTaxLGA("");
        setTaxZone("");
        setMinAmount("");
        setMaxAmount("");

        filterRef.current.classList.toggle(styles.no__display);
        setFiltersCleared(true);
    }

    const handleInvoiceDownload = (data) => {
        const blob = new Blob([data], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, 'downloaded-file.pdf')
        } else {
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a');
            link.href = url;
            link.download = 'HNI-Bill.pdf';
            document.body.appendChild(link);
            link.click();

            // Clean up DOM
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    }

    const handleBillActions = async (billNumber, rptmode = 1) => {
        if (rptmode === 2) {
            setDownloading(true);
        }

        if (rptmode === 3) {
            setSendingToMail(true);
        }

        try {
            // const user = JSON.parse(localStorage.getItem("user_info"));
            await axios
                .get(`gethnibill/${billNumber}/${rptmode}`, {
                    responseType: rptmode === 2 ? "blob" : "json"
                })
                .then((res) => {
                    if (rptmode > 1) {
                        setAlert({
                            showing: true,
                            type: "success",
                            message: rptmode === 3 ?
                                res?.data?.msg : "HNI download complete."
                        });
                        if (rptmode === 2) {
                            handleInvoiceDownload(res?.data)
                        }
                    }

                });
        } catch (error) {
            setAlert({
                ...alert,
                showing: true,
                type: "error",
                message:
                    error.response.data?.msg ||
                    error.response.data?.errors?.[0] ||
                    error.response.data?.errors?.[0]?.message ||
                    error.response.data?.errors?.details[0]?.message ||
                    error.response?.data?.message
            });
        } finally {
            setDownloading(false);
            setSendingToMail(false);
        }
    }

    useEffect(() => {
        getHNI()();
        setFiltersCleared(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filtersCleared === true]);

    const handleView = (data) => {
        viewCertificate(data.BillNumber, data?.id, 1);
        setReviewedCertDetails({
            billNumber: data.BillNumber,
            Name: data.Name,
            id: data?.id,
            status: getStatusText(data.ApproveFlg)
        });
    }

    useEffect(() => {
        const delay = setTimeout(
            () =>
                setAlert({
                    showing: false,
                    type: null,
                    message: ""
                }),
            3000
        );

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!alert.showing]);

    return (
        <section>
            {alert.showing && <Alert text={alert.message} type={alert.type} />}
            {viewModalShowing && (
                <Modal toggle={setViewModalShowing} showing={viewModalShowing}>
                    {loadingCert ? (
                        <p style={{ textAlign: "center" }}>Loading...</p>
                    ) : (
                        <>
                            <div className={styles2.cert__btns}>
                                {reviewedCertDetails.status === "Approved" && (
                                    <>
                                        <button
                                            className="btn btn-danger"
                                            style={{
                                                padding: '12px',
                                                height: 'fit-content'
                                            }}
                                            disabled={!rejectReason || cancelApprovalRequest}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setViewModalShowing(false);
                                            }}
                                        >
                                            {"Close"}
                                        </button>
                                        <button
                                            className={`tertiary__btn ${styles2.download__btn}`}
                                            style={{ marginBottom: "20px" }}
                                            onClick={() => printCertificate()}
                                        >
                                            Print
                                        </button>
                                        <button
                                            className={`secondary__btn ${styles2.download__btn}`}
                                            style={{ marginBottom: "20px" }}
                                            onClick={() =>
                                                handleBillActions(reviewedCertDetails.billNumber, 2)
                                            }
                                        >
                                            <img
                                                src={downloadIcon}
                                                alt="download icon"
                                                style={{ marginRight: "8px" }}
                                            />
                                            {downloading
                                                ? "Downloading..."
                                                : "Download"}
                                        </button>
                                        <button
                                            className={styles2.to__mail_btn}
                                            style={{ marginBottom: "20px" }}
                                            onClick={() =>
                                                handleBillActions(reviewedCertDetails.billNumber, 3)
                                            }
                                        >
                                            {sendingToMail
                                                ? "Sending..."
                                                : "Send to Email"}
                                        </button>
                                    </>
                                )}
                                {reviewedCertDetails.status === "Pending Approval" && (
                                    <>
                                        <button
                                            className="btn btn-danger"
                                            disabled={!rejectReason || cancelApprovalRequest}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setViewModalShowing(false);
                                            }}
                                        >
                                            {"Close"}
                                        </button>
                                        <button
                                            style={{ padding: "0 25px" }}
                                            className={`tertiary__btn ${styles.request__btn}`}
                                            onClick={() => setRejectionSuccessModalShowing(true)}
                                        >
                                            {"Cancel Bill"}
                                        </button>
                                    </>
                                )}
                                {reviewedCertDetails.status === "Cancelled" && (
                                    <>
                                        <button
                                            className="btn btn-danger"
                                            disabled={!rejectReason || cancelApprovalRequest}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setViewModalShowing(false);
                                            }}
                                        >
                                            {"Close"}
                                        </button>
                                        <button
                                            style={{ padding: "0 25px" }}
                                            className={`tertiary__btn ${styles.request__btn}`}
                                            onClick={() => setDeletionSuccessModalShowing(true)}
                                        >
                                            {"Delete Bill"}
                                        </button>
                                    </>
                                )}
                                {reviewedCertDetails.status === "Pending" && (
                                    <>
                                        <button
                                            className="btn btn-danger"
                                            disabled={!rejectReason || cancelApprovalRequest}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setViewModalShowing(false);
                                            }}
                                        >
                                            {"Close"}
                                        </button>
                                        <button
                                            style={{ padding: "0 25px" }}
                                            onClick={() => requestForApproval("5")}
                                            className={`primary__btn ${styles.request__btn}`}
                                        >
                                            {sendingForApproval
                                                ? "Requesting..."
                                                : "Request for Approval"}
                                        </button>
                                    </>
                                )}
                            </div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: certificate
                                }}
                            ></div>
                        </>
                    )}
                </Modal>
            )}
            {rejectionSuccessModalShowing && (
                <Modal
                    showing={rejectionSuccessModalShowing}
                    toggle={setRejectionSuccessModalShowing}
                >
                    <div className={styles2.rejection__badge}>
                        <img src={failureCheck} alt="circle with an X inside" />
                        <span>CANCELLATION</span>
                    </div>
                    <p className={styles2.reject__reason_instruction}>
                        You are about to cancel{" "}
                        <b>{reviewedCertDetails?.Name}</b>'s HNI Bill. Please, state your reason.
                    </p>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            requestForApproval("1", rejectReason)
                        }}
                        className={styles2.reject__reason_form}
                    >
                        <label htmlFor="rejectReason">
                            Reason for cancelling{" "}
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                            id="rejectReason"
                            placeholder="write the reason for cancelling this HNI Bill"
                            value={rejectReason}
                            onChange={(e) => setRejectReason(e.target.value)}
                            rows={2}
                        />
                        <div style={{
                            display: 'flex'
                            // gap: 'px'
                        }}>
                            <button
                                className="tertiary__btn"
                                disabled={!rejectReason || cancelApprovalRequest}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setRejectionSuccessModalShowing(false);
                                }}
                            >
                                {"Close"}
                            </button>
                            <button
                                type="submit"
                                className="primary__btn"
                                disabled={!rejectReason || cancelApprovalRequest}
                            >
                                {cancelApprovalRequest
                                    ? "Canceling..."
                                    : "Cancel Bill"}
                            </button>
                        </div>
                    </form>
                </Modal>
            )}
            {deletionSuccessModalShowing && (
                <Modal
                    showing={deletionSuccessModalShowing}
                    toggle={setDeletionSuccessModalShowing}
                >
                    <div className={styles2.rejection__badge}>
                        <img src={failureCheck} alt="circle with an X inside" />
                        <span>DELETION</span>
                    </div>
                    <p className={styles2.reject__reason_instruction}>
                        You are about to delete{" "}
                        <b>{reviewedCertDetails?.Name}</b>'s HNI Bill. Please, state your reason.
                    </p>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            requestForApproval("6", deletionReason)
                        }}
                        className={styles2.reject__reason_form}
                    >
                        <label htmlFor="rejectReason">
                            Reason for deletion{" "}
                            <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                            id="rejectReason"
                            placeholder="write the reason for cancelling this HNI Bill"
                            value={deletionReason}
                            onChange={(e) => setDeletionReason(e.target.value)}
                            rows={2}
                        />
                        <div style={{
                            display: 'flex'
                            // gap: 'px'
                        }}>
                            <button
                                className="tertiary__btn"
                                disabled={!deletionReason || deletionApprovalRequest}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setDeletionSuccessModalShowing(false);
                                }}
                            >
                                {"Close"}
                            </button>
                            <button
                                type="submit"
                                className="primary__btn"
                                disabled={!deletionReason || deletionApprovalRequest}
                            >
                                {cancelApprovalRequest
                                    ? "Deleting..."
                                    : "Delete Bill"}
                            </button>
                        </div>
                    </form>
                </Modal>
            )}
            <div className={styles.actions__div}>
                <button
                    className="primary__btn"
                    style={{
                        padding: "0 25px"
                    }}
                    onClick={() => {
                        history.push("/hni/prepare-tax", {
                            taxpayers: true,
                            fromIndividual: true,
                            ssActive: "1"
                        });
                    }}
                >
                    Generate HNI Tax
                </button>

                <div className={styles.filter}>
                    <button
                        onClick={() => {
                            filterRef.current.classList.toggle(
                                styles.no__display
                            );
                        }}
                        className={styles.filter__button}
                    >
                        Filter
                    </button>
                    <div
                        ref={filterRef}
                        className={[
                            styles.filter__div,
                            styles.no__display
                        ].join(" ")}
                    >
                        <div className={styles.filter__header}>
                            <h6>Filter</h6>
                            <button
                                aria-labelledby="close filter button"
                                onClick={() => {
                                    filterRef.current.classList.toggle(
                                        styles.no__display
                                    );
                                }}
                            >
                                X
                            </button>
                        </div>
                        <button
                            className={styles.clear__filter_fields}
                            onClick={clearFilters}
                            disabled={
                                !OwnersESBN &&
                                !startDate &&
                                !endDate &&
                                !ownersname &&
                                !taxLGA &&
                                !taxZone &&
                                !taxOffice &&
                                !address &&
                                !BillNumber &&
                                !minAmount &&
                                !maxAmount
                            }
                        >
                            Clear Filter fields
                        </button>
                        <p>By Code</p>
                        <form>
                            <div>
                                <label htmlFor="BillNo">ESBN</label>
                                <input
                                    name="owneresbn"
                                    id="owneresbn"
                                    type="text"
                                    value={OwnersESBN}
                                    onChange={(e) =>
                                        setOwnersESBN(e.target.value)
                                    }
                                    placeholder="Eg. 00909986588"
                                />
                            </div>
                            <div>
                                <label htmlFor="BillNo">Bill Number</label>
                                <input
                                    name="BillNo"
                                    id="BillNo"
                                    type="text"
                                    value={BillNumber}
                                    onChange={(e) =>
                                        setBillNumber(e.target.value)
                                    }
                                    placeholder="Eg. AB20241000000000EN"
                                />
                            </div>
                            <div>
                                <label htmlFor="ownersname">Name</label>
                                <input
                                    name="OwnersName"
                                    id="ownersname"
                                    type="text"
                                    value={ownersname}
                                    onChange={(e) =>
                                        setownersname(e.target.value)
                                    }
                                    placeholder="Eg. Odogwu Okeke"
                                />
                            </div>
                            {/* 
                            Individuals Name *
                            ESBN *
                            Bill Number *
                            Generated By *
                            Total Tax Due
                            Total Income
                            Individual Address
                            */}
                            <div>
                                <label htmlFor="address">Individual Address</label>
                                <input
                                    name="address"
                                    id="address"
                                    type="text"
                                    value={address}
                                    onChange={(e) =>
                                        setAddress(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="inputter">Inputter</label>
                                <input
                                    name="inputter"
                                    id="inputter"
                                    type="text"
                                    value={inputter}
                                    onChange={(e) =>
                                        setInputter(e.target.value)
                                    }
                                />
                            </div>
                        </form>
                        <p>By Tax</p>
                        <form>
                            <div>
                                <label htmlFor="taxZone">Tax Zone</label>
                                <input
                                    name="taxZone"
                                    id="taxZone"
                                    type="text"
                                    value={OwnersESBN}
                                    onChange={(e) =>
                                        setTaxZone(e.target.value)
                                    }
                                    placeholder="Eg. Enugu"
                                />
                            </div>
                            <div>
                                <label htmlFor="taxOffice">Tax Office</label>
                                <input
                                    name="taxOffice"
                                    id="taxOffice"
                                    type="text"
                                    value={taxOffice}
                                    onChange={(e) =>
                                        setTaxOffice(e.target.value)
                                    }
                                    placeholder="Eg. Enugu"
                                />
                            </div>
                            <div>
                                <label htmlFor="taxZone">Tax LGA</label>
                                <input
                                    name="taxZone"
                                    id="taxZone"
                                    type="text"
                                    value={taxLGA}
                                    onChange={(e) =>
                                        setTaxLGA(e.target.value)
                                    }
                                    placeholder="Eg. Enugu West"
                                />
                            </div>
                        </form>
                        <p>By Amount</p>
                        <form>
                            <div>
                                <label htmlFor="minAmount">Minimum Ammount</label>
                                <input
                                    name="minAmount"
                                    id="minAmount"
                                    type="number"
                                    value={minAmount}
                                    onChange={(e) =>
                                        setMinAmount(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="maxAmount">Maximum Amount</label>
                                <input
                                    name="maxAmount"
                                    id="maxAmount"
                                    type="number"
                                    value={maxAmount}
                                    onChange={(e) =>
                                        setMaxAmount(e.target.value)
                                    }
                                />
                            </div>
                        </form>
                        <p>By Creation Date</p>
                        <form>
                            <div>
                                <label htmlFor="start_date">Start Date</label>
                                <input
                                    name="start_date"
                                    id="start_date"
                                    type="date"
                                    value={startDate}
                                    onChange={(e) =>
                                        setStartDate(e.target.value)
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="start_date">End Date</label>
                                <input
                                    name="end_date"
                                    id="end_date"
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </form>
                        <button
                            className={[
                                styles.submit__button,
                                "primary__btn"
                            ].join(" ")}
                            onClick={getHNI()}
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>
            <div className={styles.table__div}>
                {loading && !data ? (
                    <p style={{ textAlign: "center" }}>Loading...</p>
                ) : (
                    <Table
                        headers={headers}
                        data={data || []}
                        full
                        pageSize={20}
                        total={total}
                        onRowClick={() => null}
                        onView={(data) => {
                            Number(data?.ApproveFlg) !== 6 ?
                                handleView(data) :
                                setAlert({
                                    showing: true,
                                    type: 'error',
                                    message:
                                        `${getStatusText(data.ApproveFlg)} HNI Bill cannot be viewed.`
                                })
                        }}
                        onEdit={(data) =>
                            Number(data?.ApproveFlg) <= 2 ?
                                history.push(`/hni/edit`, data) :
                                setAlert({
                                    showing: true,
                                    type: 'error',
                                    message:
                                        `${getStatusText(data.ApproveFlg)} HNI Bill cannot be edited.`
                                })
                        }
                    />
                )}
            </div>
        </section>
    )
}

export default HNIHome