import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import constants from "./constants";
import "./index.css";

axios.defaults.baseURL = constants.BASE_URL;
axios.defaults.testURL = constants.TEST_URL;

async function init() {
	try {
		const userInfo = JSON.parse(localStorage.getItem("user_info"));
		let lastRefresh = new Date(localStorage.getItem("last_refresh"));
		const accessToken = localStorage.getItem("access_token");
		if (accessToken)
			axios.defaults.headers.common["Authorization"] =
				`Bearer ${accessToken}`;

		if (
			Math.abs(new Date() - lastRefresh) / 36e5 >= 22 &&
			!/login|signup|xtdmcd/i.test(window.location.pathname)
		) {
			const response = await axios
				.get(`/auth/token/refresh`, {
					headers: {
						"x-refresh-token": (userInfo || {}).refresh_token
					}
				})
				.then((resp) => {
					if (!resp.data)
						throw new Error("An error occurred while refreshing");
					return (resp.data || {}).data;
				});

			if (!response) throw new Error("Unknown user");

			localStorage.setItem("access_token", response.access_token);
			localStorage.setItem("last_refresh", new Date());
		}
	} catch (error) {
		console.error(error);
		if (
			!/login|signup|otp|home|enugutccverification|unifiedpayment|flutterwave|remita|etranzact|moniepoint|reset-password|tax-type|tax-system|tax-collection|tax-legislation|about-us|frequently-asked-questions/i.test(
				window.location.pathname
			)
		) {
			window.location.pathname = "/home";
		}
	}
}

init().then(() => {
	ReactDOM.render(
		<React.StrictMode>
			<App />
		</React.StrictMode>,
		document.getElementById("root")
	);

	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();
});
