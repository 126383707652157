/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState
} from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import Spinner from "../../../components/spinner";
import styles from "../../../Identity-management-individual/style.module.css";
import styles1 from "../style.module.css";
import styles2 from "../../../Direct-assessment/Create-direct-assessment/style.module.css";
import styles3 from "../../../Identity-management-individual/style.module.css";
import Alert from "../../../components/Alert";
import Receipt from "../../ReceiptPymtRef/receipt";
import backArrow from "../../../assets/arrowLeft.svg";
import PAYEEmodule from "../../../Dashboard/PAYE/Paye-Record/style.module.css";

const user = JSON.parse(localStorage.getItem("user_info"));

const ViewBill = () => {
	const history = useHistory();
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const [showInvoice, setShowInvoice] = useState(false);
	const [subscription, setSubcription] = useState("yearly");

	const [invoice, setInvoice] = useState("");

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});
	let { state } = useLocation();

	const fetchCurrentBill = useCallback(async () => {
		setLoading(true);
		const res = await axios
			.get(`billing/${state.coy}/${state.userId}`)
			.then((res) => (res.data || {}).data?.result);

		if (res?.BillNumber) {
			fetchInvoice(res?.nb);
			setLoading(false)
		}
		setData(res);
	}, [state.userId, state.coy]);

	useEffect(() => {
		fetchCurrentBill();
	}, [fetchCurrentBill]);

	const fetchInvoice = async (billNumber) => {
		await axios
			.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}/1`)
			.then((res) => {
				setInvoice(res?.data);
				setShowInvoice(true);
			});
	};

	const printInvoiceFile = (e) => {
		e.preventDefault();

		if (!!invoice) {
			const w = window.open();
			if (w.document) {
				w.document.write(invoice);
				w.document.close();
				w.onload = () => {
					w.focus();
					w.print();
				};

				w.onafterprint = () => {
					w.close();
				};
			}
		} else {
			return;
		}
	};

	return (
		<>
			<form className={styles3.columned__form} onSubmit={printInvoiceFile}>
				{/* <form className={styles3.columned__form}> */}
				{alert.showing && (
					<Alert text={alert.message} type={alert.type} />
				)}
				<div>
					<button
						className={`${PAYEEmodule.review_header_btn} ${PAYEEmodule.customBackButton}`}
						onClick={() => history.push("/multi-company/billing")}
					>
						<img
							src={backArrow}
							alt="backArrow"
							className={PAYEEmodule.backArrow}
						/>
						<span className={PAYEEmodule.backText}>Back</span>
					</button>
				</div>
				<div
					className={styles2.radio__button_div}
					style={{ marginTop: "20px" }}
				>
					<span className={styles1.input__label}>
						Bill is Scheduled for
					</span>
					<div>
						<div>
							<input
								id="yearlySchedule"
								type="radio"
								value="yearly"
								checked={data?.Year_Mth_Flg === '1'}
								disabled
							/>
							<label
								className={styles3.input__label}
								htmlFor="yearlySchedule"
							>
								Yearly
							</label>
						</div>
						<div>
							<input
								id="monthlySchedule"
								type="radio"
								value="monthly"
								checked={data?.Year_Mth_Flg === '2'}
								disabled
							/>
							<label
								className={styles3.input__label}
								htmlFor="monthlySchedule"
							>
								Monthly
							</label>
						</div>
					</div>
				</div>

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectyear"
						>
							Bill Year (Fiscal Year)
						</label>
						<select
							id="selectYear"
							value={loading ? "Loading..." : data?.fiscalYear ?? ''}
							disabled
						>
							<option value={data?.fiscalYear}>{loading ? "Loading..." : data?.fiscalYear ?? ''}</option>
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="selectmonth"
						>
							Bill Month (Fiscal Month)
						</label>
						<select
							id="selectmonth"
							value={loading ? "Loading..." : data?.fiscalMonth}
							placeholder=""
							disabled
						>
							<option value={data?.fiscalMonth}>{loading ? "Loading..." : data?.fiscalMonth ?? ''}</option>
						</select>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="taxlga">
							Bill from which Local Government Area(LGA)
						</label>
						<select
							id="taxlga"
							name="taxlga"
							value={loading ? "Loading..." : data?.TaxLGA}
							placeholder=""
							disabled
						>
							<option value={data?.TaxLGA}>{loading ? "Loading..." : data?.TaxLGA ?? ''}</option>
						</select>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="taxzone"
						>
							Bill from which Zone?
						</label>
						<select
							id="taxzone"
							name="taxzone"
							value={loading ? "Loading..." : data?.TaxZone ?? ''}
							placeholder=""
							disabled
						>
							<option value={data?.TaxZone}>{loading ? "Loading..." : data?.TaxZone ?? ''}</option>
						</select>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="taxoffice"
						>
							Bill from which Tax Office?
						</label>
						<select
							id="taxoffice"
							name="taxoffice"
							value={loading ? "Loading..." : data?.TaxOffice}
							placeholder=""
							disabled
						>
							<option value={data?.TaxOffice}>{loading ? "Loading..." : data?.TaxOffice ?? ''}</option>
						</select>
					</div>
				</div>
				<hr className={styles.divider} />
				<div className={styles.mda__double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersesbn"
						>
							ESBN
						</label>
						<input
							type="text"
							id="ownersesbn"
							placeholder=""
							value={loading ? "Loading..." : data?.OwnersESBN ?? ''}
							disabled
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="ownersname"
						>
							Name <span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="text"
							id="ownersname"
							placeholder=""
							value={loading ? "Loading..." : data?.OwnersName ?? ''}
							disabled
						/>
					</div>
				</div>
				<div className={styles.mda__double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="residentialaddress"
						>
							Address
						</label>
						<input
							type="text"
							id="residentialaddress"
							placeholder=""
							value={loading ? "Loading..." : data?.OwnersAddr ?? ''}
							disabled
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="phonenumber"
						>
							Phone
						</label>
						<input
							type="text"
							id="phonenumber"
							placeholder=""
							value={loading ? "Loading..." : data?.OwnersPhone ?? ''}
							disabled
						/>
					</div>
				</div>
				<div className={styles.double__inputs}>
					<div>
						<label className={styles.input__label} htmlFor="email">
							Email
						</label>
						<input
							type="text"
							id="email"
							placeholder=""
							value={loading ? "Loading..." : data?.OwnerEmail ?? ''}
							disabled
						/>
					</div>
				</div>

				{user?.mdaModuleCoy === '04' &&
					<>
						<hr className={styles.divider} />

						<div
							className={styles2.radio__button_div}
							style={{ marginTop: "20px" }}
						>
							<span className={styles1.input__label}>
								Water connection Status
							</span>
							<div>
								<div>
									<input
										id="connected"
										type="radio"
										value="Connected"
										checked={data?.WaterConnectStatus === "Connected"}
										disabled
									/>
									<label
										className={styles3.input__label}
										htmlFor="connected"
									>
										Connected
									</label>
								</div>
								<div>
									<input
										id="notConnected"
										type="radio"
										value="Not connected"
										checked={data?.WaterConnectStatus === "Not connected"}
										disabled
									/>
									<label
										className={styles3.input__label}
										htmlFor="notConnected"
									>
										Not connected
									</label>
								</div>
							</div>
						</div>

						<div className={styles.select__wrapper}>
							<div>
								<label htmlFor="meteringStatus">
									Metering Status
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="meteringStatus"
									className={styles.input__medium}
									name="meteringStatus"
									value={loading ? "Loading..." : data?.MeteringStatus}
									disabled
								>
									<option value="">
										{loading ? "Loading..." : data?.MeteringStatus}
									</option>
								</select>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="currentReading"
								>
									Current Reading
								</label>
								<input
									type="number"
									name="currentReading"
									// min={0}
									id="currentReading"
									placeholder="g"
									value={loading ? "Loading..." : data?.Current_Reading ?? ''}
									disabled
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="previousReading"
								>
									Previous Reading
								</label>
								<input
									type="number"
									name="previousReading"
									id="previousReading"
									placeholder=""
									value={loading ? "Loading..." : data?.Previous_Reading ?? ''}
									disabled
								/>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="consumption"
								>
									Consumption
								</label>
								<input
									type="number"
									name="consumption"
									id="consumption"
									placeholder=""
									value={loading ? "Loading..." : data?.Consumption ?? ''}
									disabled
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="readingDate"
								>
									Current Reading Date
								</label>
								<input
									type="date"
									name="readingDate"
									id="readingDate"
									value={loading ? "Loading..." : data?.Reading_Date ?? ''}
									placeholder=""
									disabled
								/>
							</div>
						</div>
					</>}

				{/* Profile ID details */}
				{data?.TaxPayerBillsDetails?.map((child, index) => (
					<React.Fragment key={`profile${index}`}>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="propertyid"
								>
									Property ID
								</label>
								<input
									type="text"
									name="propertyid"
									id="propertyid"
									placeholder=""
									value={loading ? "Loading..." : child?.PropertyId ?? ''}
									disabled
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="PIDname"
								>
									Occupant(s) / Owner's Name
								</label>
								<input
									type="text"
									name="PIDname"
									id="PIDname"
									placeholder=""
									value={loading ? "Loading..." : child?.PIDName ?? ''}
									disabled
								/>
							</div>
						</div>
						<h3>Address</h3>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="houseNo"
								>
									No
								</label>
								<input
									type="text"
									name="houseNo"
									id="houseNo"
									placeholder=""
									value={loading ? "Loading..." : child.HouseNo ?? ''}
									disabled
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="HouseStr"
								>
									Street
								</label>
								<input
									type="text"
									name="HouseStr"
									id="HouseStr"
									placeholder=""
									value={loading ? "Loading..." : child.HouseStreet}
									disabled
								/>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="houseLGA"
								>
									LGA
								</label>
								<select
									id="houseLGA"
									className={styles.input__long}
									name="houseLGA"
									value={loading ? "Loading..." : child?.HouseLGA ?? ''}
									placeholder=""
									disabled
								>
									<option>
										{loading ? "Loading..." : child?.HouseLGA ?? ''}
									</option>
								</select>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="houseZone"
								>
									Zone
								</label>
								<select
									id="houseZone"
									className={styles.input__long}
									name="houseZone"
									value={loading ? "Loading..." : child?.HouseZone ?? ''}
									disabled
								>
									<option value>{loading ? "Loading..." : child?.HouseZone ?? ''}</option>
								</select>
							</div>
						</div>

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="revenuedescription"
								>
									Further Description{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="text"
									name="revenuedescription"
									id="revenuedescription"
									placeholder=""
									value={loading ? "Loading..." : child?.RevenueDescription}
									disabled
								/>
							</div>
						</div>

						<hr className={styles.divider} />

						<div className={styles.select__wrapper}>
							<div>
								<label htmlFor="billtype">
									Bill Type
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="billtype"
									className={styles.input__medium}
									name="billtype"
									value={loading ? "Loading..." : child?.BillType ?? ''}
									disabled
								>
									<option>{loading ? "Loading..." : child?.BillType ?? ''}</option>
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label htmlFor="profile">
									Category{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="profile"
									className={styles.input__medium}
									name="profile"
									value={loading ? "Loading..." : child?.Profile ?? ''}
									disabled
								>
									<option value="">{loading ? "Loading..." : child?.Profile ?? ''}</option>
								</select>
							</div>
							<div>
								<label htmlFor="profilecategory">
									Sub-Category{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="profilecategory"
									className={styles.input__medium}
									name="profilecategory"
									value={loading ? "Loading..." : child?.ProfileCategory ?? ''}
									disabled
								>
									<option>{loading ? "Loading..." : child?.ProfileCategory ?? ''}</option>
								</select>
							</div>
						</div>
						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="qty"
								>
									How Many Units / Number of Occupants
								</label>
								<input
									type="number"
									name="qty"
									id="qty"
									placeholder=""
									value={loading ? "Loading..." : child?.Quantity ?? ''}
									disabled
								/>
							</div>
							<div>
								<label
									className={styles.input__label}
									htmlFor="month"
								>
									How Many Months
								</label>
								<input
									type="number"
									name="month"
									id="month"
									placeholder=""
									value={loading ? "Loading..." : child?.monthNumber ?? ''}
									disabled
								/>
							</div>
						</div>
						{/* {child?.profilecategory?.includes("cubic meter") && (
							<div className={styles.double__inputs}>
								<div>
									<label
										className={styles.input__label}
										htmlFor="landmeasure"
									>
										Land Measurement{" "}
										<span style={{ color: "red" }}>*</span>
									</label>
									<input
										type="text"
										name="landmeasure"
										id="landmeasure"
										// value={child.landmeasure}
										disabled
									/>
								</div>
							</div>
						)} */}

						<div className={styles.double__inputs}>
							<div>
								<label
									className={styles.input__label}
									htmlFor="amount"
								>
									{subscription === "yearly"
										? "Yearly Tarrif"
										: "Monthly Tarrif"}{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<input
									type="text"
									name="amount"
									id="amount"
									value={loading ? "Loading..." : child?.Amount ?? ''}
									disabled
								/>
							</div>
							<div>
								<label htmlFor="revenue">
									Which Revenue is this bill Attached to?{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="revenue"
									className={styles.input__medium}
									name="revenue"
									value={loading ? "Loading..." : child?.RevenueCode ?? ''}
									disabled
								>
									<option>{child?.RevenueCode ?? ''}</option>
								</select>
							</div>
						</div>

						<hr className={styles.divider} />
					</React.Fragment>
				))}

				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="discount"
						>
							Discount
						</label>
						<input
							type="text"
							id="discount"
							placeholder=""
							value={loading ? "Loading..." : data?.Discount ?? ''}
							disabled
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="discountReason"
						>
							Reason for Discount
						</label>
						<input
							type="text"
							id="discountReason"
							placeholder=""
							value={loading ? "Loading..." : data?.DiscountReason ?? ''}
							disabled
						/>
					</div>
				</div>
				{/* femi ===> outstanding */}
				<div className={styles.double__inputs}>
					<div>
						<label
							className={styles.input__label}
							htmlFor="outstanding"
						>
							Outstanding
						</label>
						<input
							type="text"
							id="outstanding"
							placeholder=""
							value={loading ? "Loading..." : data?.Outstanding ?? ''}
							disabled
						/>
					</div>
					<div>
						<label
							className={styles.input__label}
							htmlFor="outstandingReason"
						>
							Reason for Outstanding
						</label>
						<input
							type="text"
							id="outstandingReason"
							placeholder=""
							value={loading ? "Loading..." : data?.outstandingReason ?? ''}
							disabled
						/>
					</div>
				</div>
				{/* femi ===> outstanding */}

				<hr className={styles.divider} />
				<p>
					<i>
						Monthly bill is reflected by dividing yearly tarriff by
						12 (months)
					</i>
				</p>
				<div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>
						Total Amount Billed:{" "}
					</h4>
					&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{data?.GrandTotal ?? ""}
					</h4>
				</div>
				<div className={styles.double__inputs}>
					<h4 style={{ fontWeight: "bold" }}>Total Amount: </h4>&nbsp;
					<h4 style={{ fontWeight: 300 }}>
						&#8358;{data?.GrandTotal ?? ""}
					</h4>
				</div>

				<hr className={styles.divider} />

				{/* total and stuffs */}
				<footer className={styles.form__footer}>
					<button
						disabled={loading}
						type="submit"
						className={[
							"primary__btn",
							styles.primary__button,
							loading ? styles.loading__button : ""
						].join(" ")}
					>
						Download Invoice
						{loading && <Spinner />}
					</button>
				</footer>
			</form>
			{!!invoice && <Receipt data={invoice} loading={loading} />}
		</>
	);
};

export default ViewBill;

const htmlInvoice = (htmlString) => {
	return { __html: htmlString };
};
