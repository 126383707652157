import React, { useEffect, useState } from 'react'
import { useLocation } from "react-router-dom";
import styles3 from "../../Identity-management-individual/style.module.css";
import styles from "../../Identity-management-individual/style.module.css";
import styles1 from "./style.module.css"
import Table from '../../components/Table';
import { useFetchIncome } from './components/hooks';
import PAYEEmodule from "../../Dashboard/PAYE/Paye-Record/style.module.css";
import { useHistory } from "react-router";
import backArrow from "../../assets/arrowLeft.svg";
import { formatAmount } from '../../utils';
import RegularModal from '../../components/Modal/regular';
import Receipt from '../ReceiptPymtRef/receipt';
import axios from 'axios';
import Spinner from '../../components/spinner';
import Alert from '../../components/Alert';

const headers = [
    ["Type of Income", "IncomeType"],
    ["Source of Income", "IncomeSource"],
    ["Ammount (₦)", "Amount", formatAmount]
];
const prepaymentTableHeader = [
    ["Type of Prepayments", "IncomeType"],
    // ["Source of Income", "IncomeSource"],
    ["Ammount (₦)", "Amount", formatAmount]
];

const user = JSON.parse(localStorage.getItem("user_info"));

export const ViewHNI = () => {
    const [downloadInvoice, setDownloadInvoice] = useState(false);
    const [invoiceEmail, setInvoiceEmail] = useState(false);
    const [assesscode, setAssessmentCode] = useState("");
    // const [invoice, setInvoice] = useState(null);

    let { state } = useLocation();
    const history = useHistory();

    const [taxpayerDetails, setTaxpayerDetails] = useState({
        zone: '',
        esbn: '',
        lga: '',
        email: '',
        gender: '',
        address: '',
        lastName: '',
        name: '',
        fiscalYear: '',
        phoneNumber: '',
        taxAreaOffice: ''
    });

    const [downloadHNIModalStatus, setDownloadHNIModalStatus] = useState({
        downloading: false,
        showing: false,
        data: null
    });

    const [alert, setAlert] = useState({
        showing: false,
        type: null,
        message: ""
    });

    const [incomeTableData, setIncomeTableData] = useState([]);
    const [paymentTableData, setPaymentTableData] = useState([]);

    const HINData = useFetchIncome({
        route: `https://alpha.norlics.ng/api/hni/${state?.BillNumber}`
    })

    const updateFields = () => {
        if (HINData[0]?.data) {
            setTaxpayerDetails({
                ...taxpayerDetails,
                esbn: HINData[0]?.data?.ESBN,
                zone: HINData[0]?.data?.Zone,
                gender: HINData[0]?.data?.Gender,
                email: HINData[0]?.data?.Email,
                name: HINData[0]?.data?.Name,
                lga: HINData[0]?.data?.LGA,
                firstName: HINData[0]?.data?.firstname,
                fiscalYear: HINData[0]?.data?.FiscalYear,
                phoneNumber: HINData[0]?.data?.PhoneNo,
                address: HINData[0]?.data?.Address,
                taxAreaOffice: HINData[0]?.data?.TaxOffice
            })
            setAssessmentCode(HINData[0]?.data?.BillNumber)
            setIncomeTableData(HINData[0]?.data?.HNIDetailsIncomes)
            setPaymentTableData(HINData[0]?.data?.HNIDetailsPrepayments)
        }
    }

    const handleBillActions = async (billNumber, rptmode = 1) => {
        if (rptmode === 2) {
            setDownloadInvoice(true);
        }

        if (rptmode === 3) {
            setInvoiceEmail(true);
        }

        await axios
            .get(`gethnibill/${billNumber}/${user?.mdaModuleCoy}/${rptmode}`)
            .then((res) => {
                // if (rptmode === 1) {
                //     setInvoice(res?.data);
                // }
                if (rptmode > 1) {
                    setAlert({
                        showing: true,
                        type: "success",
                        message: res?.data?.msg
                    });
                    if (rptmode === 2) {
                        handleInvoiceDownload(res?.data)
                    }
                }

                // setShowInvoice(true);
                setDownloadInvoice(false);
                setInvoiceEmail(false);
            });
    }

    const fetchBill = async (billNumber) => {
        await axios
            .get(`gethnibill/${billNumber}/1`)
            .then((res) => {
                if (res?.data) {
                    setDownloadHNIModalStatus({
                        ...downloadHNIModalStatus,
                        data: res?.data
                    });
                }

                setDownloadInvoice(false);
                setInvoiceEmail(false);
            });
    }

    const printInvoiceFile = () => {
        if (!!downloadHNIModalStatus?.data) {
            const w = window.open();
            if (w.document) {
                w.document.write(downloadHNIModalStatus?.data);
                w.document.close();
                w.onload = () => {
                    w.focus();
                    w.print();
                };

                w.onafterprint = () => {
                    w.close();
                };
            }
        } else {
            return;
        }
    };

    const handleInvoiceDownload = (data) => {
        const blob = new Blob([data], { type: "application/pdf" });

        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, 'downloaded-file.pdf')
        } else {
            const url = window.URL.createObjectURL(blob)
            const link = document.createElement('a');
            link.href = url;
            link.download = 'HNI-Bill.pdf';
            document.body.appendChild(link);
            link.click();

            // Clean up DOM
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }
    }

    const handleShowMdal = (e) => {
        e.preventDefault();

        setDownloadHNIModalStatus({
            ...downloadHNIModalStatus,
            showing: true,
        });

    }

    useEffect(() => {
        if (HINData[0]?.data) {
            updateFields()
            fetchBill(HINData[0]?.data?.BillNumber)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [HINData[0]?.data])

    useEffect(() => {
        const delay = setTimeout(
            () =>
                setAlert({
                    showing: false,
                    type: null,
                    message: ""
                }),
            3000
        );

        return () => clearTimeout(delay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [!!alert.showing]);

    return (
        <form className={styles3.columned__form} onSubmit={handleShowMdal}>

            {downloadHNIModalStatus.showing && (
                <RegularModal showing={downloadHNIModalStatus.showing}>
                    <Receipt
                        data={downloadHNIModalStatus?.data}
                    />

                    <div className={styles1.invoiceBtns}>
                        <div>
                            <button
                                className={`btn btn-danger ${styles.primary__button}`}
                                onClick={() => history.push("/hni/tax")}
                            >
                                Close
                            </button>
                        </div>
                        <div>
                            <button
                                className={`btn btn-success ${styles.primary__button}`}
                                onClick={() => printInvoiceFile()}
                            >
                                Print Invoice
                            </button>
                            <button
                                className={
                                    `btn btn-success ${styles.primary__button} ${downloadInvoice ? styles.loading__button : ""}`
                                }
                                onClick={() => handleBillActions(assesscode, 2)}
                            >
                                Download Invoice
                                {downloadInvoice && <Spinner />}
                            </button>
                            <button
                                className={`btn btn-success ${styles.primary__button} ${invoiceEmail ? styles.loading__button : ""}`}
                                onClick={() => handleBillActions(assesscode, 3)}
                            >
                                Email Invoice
                                {invoiceEmail && <Spinner />}

                            </button>
                        </div>
                    </div>

                </RegularModal>
            )}

            {alert.showing && (
                <Alert text={alert.message} type={alert.type} />
            )}

            <div>
                <button
                    className={`${PAYEEmodule.review_header_btn} ${PAYEEmodule.customBackButton}`}
                    onClick={() => history.push("/hni/tax")}
                >
                    <img
                        src={backArrow}
                        alt="backArrow"
                        className={PAYEEmodule.backArrow}
                    />
                    <span className={PAYEEmodule.backText}>Back</span>
                </button>
            </div>
            <h3>Taxpayer Details</h3>
            <br />

            <div className={styles.double__inputs}>
                <div>
                    <label className={styles.input__label} htmlFor="esbn">
                        ESBN
                        <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                        type="text"
                        name="esbn"
                        id="esbn"
                        placeholder="Enter ESBN"
                        disabled
                        value={taxpayerDetails?.esbn}
                    />
                </div>
                <div>
                    <label
                        className={styles.input__label}
                        htmlFor="gender"
                    >
                        Gender
                    </label>
                    <select
                        id="gender"
                        name="gender"
                        value={taxpayerDetails?.gender}
                        disabled
                    >
                        <option value="">Select</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                </div>
            </div>
            <div className={styles.double__inputs}>
                <div>
                    <label className={styles.input__label} htmlFor="name">
                        Name
                    </label>
                    <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Enter first name"
                        disabled
                        value={taxpayerDetails?.name}
                    />
                </div>

                <div>
                    <label className={styles.input__label} htmlFor="phoneNumber">
                        Phone Number
                    </label>
                    <input
                        type="text"
                        name="phoneNumber"
                        id="phoneNumber"
                        placeholder="Enter phone number"
                        disabled
                        value={taxpayerDetails?.phoneNumber}
                    />
                </div>
            </div>

            <div className={styles.double__inputs}>
                <div>
                    <label
                        className={styles.input__label}
                        htmlFor="email"
                    >
                        Email
                    </label>
                    <input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Enter email"
                        disabled
                        value={taxpayerDetails?.email}
                    />
                </div>
                <div>
                    <label className={styles.input__label} htmlFor="address">
                        Address
                    </label>
                    <input
                        type="text"
                        name="address"
                        id="address"
                        placeholder="Enter your address"
                        disabled
                        value={taxpayerDetails?.address}
                    />
                </div>
            </div>

            <div className={styles.double__inputs}>
                <div>
                    <label className={styles.input__label} htmlFor="lga">
                        Local Govt. Area
                        <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                        id="lga"
                        name="lga"
                        value={taxpayerDetails?.lga}
                        disabled
                    >
                        <option value={taxpayerDetails?.lga}>
                            {taxpayerDetails?.lga}
                        </option>
                    </select>
                </div>
                <div>
                    <label
                        className={styles.input__label}
                        htmlFor="zone"
                    >
                        Zone
                    </label>
                    <select
                        id="zone"
                        name="zone"
                        value={taxpayerDetails?.zone}
                        disabled
                    >

                        <option value={taxpayerDetails?.zone}>
                            {taxpayerDetails?.zone}
                        </option>
                    </select>
                </div>
            </div>

            <div className={styles.double__inputs}>
                <div>
                    <label className={styles.input__label} htmlFor="taxAreaOffice">
                        Tax Area Offices
                        <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                        id="taxAreaOffice"
                        name="taxAreaOffice"
                        value={taxpayerDetails?.taxAreaOffice}
                        disabled
                    >
                        <option value={taxpayerDetails?.taxAreaOffice}>
                            {taxpayerDetails?.taxAreaOffice}
                        </option>
                    </select>
                </div>
                <div>
                    <label
                        className={styles.input__label}
                        htmlFor="fiscalYear"
                    >
                        Fiscal Year
                    </label>
                    <select
                        id="fiscalYear"
                        name="fiscalYear"
                        value={taxpayerDetails?.fiscalYear}
                        disabled
                    >
                        <option key={taxpayerDetails?.fiscalYear}>
                            {taxpayerDetails?.fiscalYear}
                        </option>
                    </select>
                </div>
            </div>

            <hr className={styles.divider} />

            <h3>Details of Incomes</h3>
            <br />
            <Table
                headers={headers}
                data={incomeTableData}
                full
                pageSize={20}
                onRowClick={() => null}
                billNoOfAmountPaidShowing={null}
                total={incomeTableData?.length ?? 90}
            />

            <hr className={styles.divider} />

            <h3>Prepayments</h3>
            <br />
            <Table
                headers={prepaymentTableHeader}
                data={paymentTableData}
                full
                pageSize={20}
                onRowClick={() => null}
                billNoOfAmountPaidShowing={null}
                total={paymentTableData?.length ?? 80}
            />

            <hr className={styles.divider} />
            <h3>Total Tax: {
                HINData[0]?.data?.AnnualTaxDue ?
                    `₦${Number(HINData[0]?.data?.AnnualTaxDue)?.toLocaleString()}` :
                    "Loading..."
            }
            </h3>

            <footer className={styles.form__footer}>
                <button
                    disabled={false}
                    type='submit'
                    className={[
                        "primary__btn",
                        styles.primary__button
                    ].join(" ")}
                >
                    View Bill
                </button>
            </footer>
        </form>
    )
}