/* eslint-disable no-unused-vars */
import { useMemo, useState } from "react";
import { Card } from "../../Settings/id.details";
import styles1 from "../../../Identity-management-individual/style.module.css";

const MultiCompanySettings = () => {
	//Revenue Heads
	const [SettingId, setSettingId] = useState("");
	const [Description, setRevenueHeadsDescription] = useState("");
	const [Coy, setCoy] = useState("");
	const [Deactivated, setDeactivated] = useState(false);

	//Company
	const [Code, setCode] = useState("");
	const [CoyId, setCoyId] = useState("");
	const [Coyname, setCoyname] = useState("");
	const [CoyDescription, setCoyDescription] = useState("");
	const [sysCoyname, setsysCoyname] = useState("");
	const [Address1, setAddress1] = useState("");
	const [Address2, setAddress2] = useState("");
	const [billSignName, setBillSignName] = useState("")
	const [receiptSignName, setReceiptSignName] = useState("")
	const [certSignatureName, setCertSignatureName] = useState("")

	const [City, setCity] = useState("");
	const [State, setState] = useState("");
	const [ContactPhone, setContactPhone] = useState("");
	const [ContactEmail, setContactEmail] = useState("");

	//Bill Record Type
	const [billRecordTypecode, setbillRecordTypecode] = useState("");
	const [billRecordTypeDesc, setbillRecordTypeDesc] = useState("");
	const [billRecordTypeCoy, setbillRecordTypeCoy] = useState("");

	//Bill Rating
	const [billRatingCode, setbillRatingCode] = useState("");
	const [billRatingDesc, setbillRatingDesc] = useState("");
	const [billRatingCoy, setbillRatingCoy] = useState("");

	//Bill Mode
	const [billModeCode, setbillModeCode] = useState("");
	const [billModeDesc, setbillModeDesc] = useState("");
	const [billModeCoy, setbillModeCoy] = useState("");

	//Bill Method
	const [billMethodCode, setbillMethodCode] = useState("");
	const [billMethodDesc, setbillMethodDesc] = useState("");
	const [billMethodCoy, setbillMethodCoy] = useState("");

	//Payment Status
	const [billpaymentStatusCode, setbillpaymentStatusCode] = useState("");
	const [billpaymentStatusDesc, setbillpaymentStatusDesc] = useState("");
	const [billpaymentStatusCoy, setbillpaymentStatusCoy] = useState("");

	//Process Status
	const [billprocessStatusCode, setbillprocessStatusCode] = useState("");
	const [billprocessStatusDesc, setbillprocessStatusDesc] = useState("");
	const [billprocessStatusCoy, setbillprocessStatusCoy] = useState("");

	//TaxOffice
	const [billTaxOfficeCode, setbillTaxOfficeCode] = useState("");
	const [billTaxOfficeDesc, setbillTaxOfficeDesc] = useState("");
	const [billTaxOfficeCoy, setbillTaxOfficeCoy] = useState("");


	return (
		<main>
			<Card
				cardName="Revenue Heads"
				codeValue={SettingId}
				onCodeChange={setSettingId}
				descriptionValue={Description}
				onDescriptionChange={setRevenueHeadsDescription}
				state={{
					Coy,

				}}
				urlSubstring={"mdarevenue"}
				altChildren={
					<>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="Description"
							>
								Description
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="Description"
								type="text"
								id="Description"
								value={Description}
								onChange={(e) =>
									setRevenueHeadsDescription(e.target.value)
								}
								placeholder="eg. 01"
								required
							/>
						</div>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="Coy"
							>
								Company{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="Coy"
								type="text"
								id="Coy"
								value={Coy}
								onChange={(e) => setCoy(e.target.value)}
								placeholder="eg. 01"
								required
							/>
						</div>
					</>
				}
			>

			</Card>
			<Card
				cardName="Company"
				codeValue={Code}
				onCodeChange={CoyDescription}
				descriptionValue={Coyname}
				onDescriptionChange={setCoyDescription}
				state={{
					CoyId,
					CoyDescription,
					Coyname,
					sysCoyname,
					Address1,
					Address2,
					City,
					State,
					ContactEmail,
					ContactPhone,
					billSignName,
					receiptSignName,
					certSignatureName,
					Deactivated
				}}
				urlSubstring={"mdacompany"}
				altChildren={
					<>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="CoyId"
							>
								Company ID{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="CoyId"
								type="text"
								id="CoyId"
								value={CoyId}
								onChange={(e) =>
									setCoyId(e.target.value)
								}
								placeholder="eg. 01"
								required
							/>
						</div>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="Coyname"
							>
								Company name{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="Coyname"
								type="text"
								id="Coyname"
								value={Coyname}
								onChange={(e) => setCoyname(e.target.value)}
								placeholder="eg. 01"
								required
							/>
						</div>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="sysCoyname"
							>
								System Company name{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="sysCoyname"
								type="text"
								id="sysCoyname"
								value={sysCoyname}
								onChange={(e) =>
									setsysCoyname(e.target.value)
								}
								placeholder="eg. 01"
								required
							/>
						</div>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="receiptSignName"
							>
								Receipt Signature Name{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="receiptSignName"
								type="text"
								id="receiptSignName"
								value={receiptSignName}
								onChange={(e) => setReceiptSignName(e.target.value)}
								placeholder=""
								required
							/>
						</div>
						<hr className={styles1.divider} />
						<div>
							<label
								className={styles1.input__label}
								htmlFor="disabled"
							>
								Cert. Signature Name {" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								name="disabled"
								type="text"
								id="disabled"
								value={certSignatureName}
								onChange={(e) => setCertSignatureName(e.target.value)}
								placeholder=""
								required
							/>
						</div>
					</>
				}
			>
				<hr className={styles1.divider} />
				<div>
					<label className={styles1.input__label} htmlFor="Address1">
						Address1 <span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="Address1"
						type="text"
						id="Address1"
						value={Address1}
						onChange={(e) => setAddress1(e.target.value)}
						placeholder="eg. 01"
						required
					/>
				</div>
				<hr className={styles1.divider} />
				{/* New */}
				<div>
					<label
						className={styles1.input__label}
						htmlFor="Address2"
					>
						Address2{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="Address2"
						type="text"
						id="Address2"
						value={Address2}
						onChange={(e) => setAddress2(e.target.value)}
						placeholder="eg. 01"
						required
					/>
				</div>
				<hr className={styles1.divider} />
				<div>
					<label
						className={styles1.input__label}
						htmlFor="city"
					>
						City{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="city"
						type="text"
						id="city"
						value={City}
						onChange={(e) => setCity(e.target.value)}
						placeholder="eg. 01"
						required
					/>
				</div>
				<hr className={styles1.divider} />
				<div>
					<label
						className={styles1.input__label}
						htmlFor="state"
					>
						MDA State{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="state"
						type="text"
						id="state"
						value={State}
						onChange={(e) => setState(e.target.value)}
						placeholder="eg. 01"
						required
					/>
				</div>
				<hr className={styles1.divider} />
				<div>
					<label
						className={styles1.input__label}
						htmlFor="billSignName"
					>
						Bill Signature Name{" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="billSignName"
						type="text"
						id="billSignName"
						value={billSignName}
						onChange={(e) => setBillSignName(e.target.value)}
						placeholder=""
						required
					/>
				</div>
				<hr className={styles1.divider} />
				<div>
					<label
						className={styles1.input__label}
						htmlFor="deactivated"
					>
						Disabled {" "}
						<span style={{ color: "red" }}>*</span>
					</label>
					<input
						name="deactivated"
						type="text"
						id="deactivated"
						value={Deactivated}
						onChange={(e) => setDeactivated(e.target.value)}
						placeholder=""
						required
					/>
				</div>

			</Card>
			<Card
				cardName="Bill Record"
				codeValue={billRecordTypecode}
				onCodeChange={setbillRecordTypecode}
				descriptionValue={billRecordTypeDesc}
				onDescriptionChange={setbillRecordTypeDesc}
				urlSubstring="mdarecord"
				state={{
					billRecordTypeCoy,
				}}
			/>
			<Card
				cardName="Bill Rating"
				codeValue={billRatingCode}
				onCodeChange={setbillRatingCode}
				descriptionValue={billRatingDesc}
				onDescriptionChange={setbillRatingDesc}
				urlSubstring="mdarating"
				state={{
					billRatingCoy,
				}}
			/>
			<Card
				cardName="Bill Mode"
				codeValue={billModeCode}
				onCodeChange={setbillModeCode}
				descriptionValue={billModeDesc}
				onDescriptionChange={setbillModeDesc}
				urlSubstring="mdamode"
				state={{
					billModeCoy,
				}}
			/>
			<Card
				cardName="Bill Method"
				codeValue={billMethodCode}
				onCodeChange={setbillMethodCode}
				descriptionValue={billMethodDesc}
				onDescriptionChange={setbillMethodDesc}
				urlSubstring="mdamethod"
				state={{
					billMethodCoy,
				}}
			/>
			<Card
				cardName="Payment Status"
				codeValue={billpaymentStatusCode}
				onCodeChange={setbillpaymentStatusCode}
				descriptionValue={billpaymentStatusDesc}
				onDescriptionChange={setbillpaymentStatusDesc}
				urlSubstring="mdapaymentstatus"
				state={{
					billpaymentStatusCoy,
				}}
			/>
			<Card
				cardName="Process Status"
				codeValue={billprocessStatusCode}
				onCodeChange={setbillprocessStatusCode}
				descriptionValue={billprocessStatusDesc}
				onDescriptionChange={setbillprocessStatusDesc}
				urlSubstring="mdaprocessstatus"
				state={{
					billprocessStatusCoy,
				}}
			/>
			<Card
				cardName="Tax Office"
				codeValue={billTaxOfficeCode}
				onCodeChange={setbillTaxOfficeCode}
				descriptionValue={billTaxOfficeDesc}
				onDescriptionChange={setbillTaxOfficeDesc}
				urlSubstring="mdataxoffice"
				state={{
					billTaxOfficeCoy,
				}}
			/>
		</main>
	)
}

export default MultiCompanySettings