import { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import styles from "./style.module.css";
import TaxCalcInputter from "./sidebars/taxCalcInputter";
import LookUpESBN from "./sidebars/lookup/esbn";
import LookUpReceipt from "./sidebars/lookup/receipt";
import LookUpCertificate from "./sidebars/lookup/certificate";
import constants from "../constants.js";
import FindESBN from "./findESBN";
import Calculator from "./calculator/index.js";
import Faq from "./faq/index.js";
import Footer from "./footer/index.js";
import NavBar from "./nav-bar.js";
import TaxCategory from "./tax-category/index.js";
import Subscription from "../Home/subscription/index";
import NewsModal from "./news.modal.js";

const Home = () => {
	const [isOpenPayWithBtn, setIsOpenPayWithBtn] = useState(false);
	const [isOpenESBNrelated, setIsOpenESBNrelated] = useState(false);
	const dropdownRef = useRef(null);

	useEffect(() => {
		const words = document.querySelectorAll(`.${styles.word}`);

		words.forEach((word) => {
			const letters = word.textContent.split("");
			word.textContent = "";
			letters.forEach((letter) => {
				const span = document.createElement("span");
				span.textContent = letter;
				span.className = styles.letter;
				word.append(span);
			});
		});

		let currentWordIndex = 0;
		const maxWordIndex = words.length - 1;
		words[currentWordIndex].style.opacity = "1";

		const rotateText = () => {
			const currentWord = words[currentWordIndex];
			const nextWord =
				currentWordIndex === maxWordIndex
					? words[0]
					: words[currentWordIndex + 1];

			Array.from(currentWord.children).forEach((letter, i) => {
				setTimeout(() => {
					letter.className = `${styles.letter} ${styles.out}`;
				}, i * 80);
			});

			nextWord.style.opacity = "1";
			Array.from(nextWord.children).forEach((letter, i) => {
				letter.className = `${styles.letter} ${styles.behind}`;
				setTimeout(
					() => {
						letter.className = `${styles.letter} ${styles.in}`;
					},
					340 + i * 80
				);
			});

			currentWordIndex =
				currentWordIndex === maxWordIndex ? 0 : currentWordIndex + 1;
		};

		rotateText();
		const intervalId = setInterval(rotateText, 4000);

		return () => clearInterval(intervalId);
	}, []);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsOpenPayWithBtn(false);
				setIsOpenESBNrelated(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	return (
		<>
			<TaxCalcInputter />
			<LookUpESBN />
			<LookUpReceipt />
			<LookUpCertificate />
			<NavBar />
			<NewsModal />
			<section className={styles.heroSection}>
				<div className={styles.banner__desc}>
					<h1>
						<span className={styles.enuguState}>Enugu State</span>
						&nbsp;
						<span className={`${styles.word} ${styles.central}`}>
							Central
						</span>
						<span className={` ${styles.word} ${styles.payment}`}>
							Payment
						</span>
						<br /> Management System
					</h1>
					<h2>
						#<span>Tomorrow is here</span>
					</h2>
					<div className={styles.banner__buttons} ref={dropdownRef}>
						<div className={` ${styles.dropdown}`}>
							<button
								className={` ${styles.banner_payWith_btn} `}
								type="button"
								onClick={() => {
									setIsOpenESBNrelated(false);
									setIsOpenPayWithBtn(!isOpenPayWithBtn);
								}}
								style={{ cursor: "pointer" }}
							>
								Pay Tax with
								<span
									className="caret"
									style={{ marginLeft: "8px" }}
								></span>
							</button>
							{isOpenPayWithBtn && (
								<ul
									className={styles.dropdown_menu}
									style={{ zIndex: 0 }}
								>
									{constants.paymentGateways.map(
										(gateway) => (
											<li>
												<NavLink
													to={gateway.link}
													className={styles.nav__link}
												>
													<img
														src={gateway.img}
														alt={gateway.name}
														style={{
															height: "23px",
															marginRight: "10px"
														}}
													/>
													{gateway.name}
												</NavLink>
											</li>
										)
									)}
								</ul>
							)}
						</div>
						<div className={` ${styles.dropdown}`}>
							<button
								className={` ${styles.esbn_btn} `}
								type="button"
								onClick={() => {
									setIsOpenESBNrelated(!isOpenESBNrelated);
									setIsOpenPayWithBtn(false);
								}}
							>
								ESBN
								<span
									className="caret"
									style={{ marginLeft: "8px" }}
								></span>
							</button>
							{isOpenESBNrelated && (
								<ul
									className={styles.dropdown_menu}
									style={{ zIndex: 0 }}
								>
									<li className={styles.gatewayContent}>
										<NavLink
											to={{
												pathname: "/signup",
												state: { anscims: true }
											}}
										>
											Get ESBN
										</NavLink>
									</li>
									<li className={styles.gatewayContent}>
										<a href="#find-ESBN">
											<span>Find ESBN</span>
										</a>
									</li>
									<li
										type="button"
										data-toggle="modal"
										data-target="#lookUpESBN"
										className={styles.gatewayContent}
									>
										Confirm ESBN
									</li>
								</ul>
							)}
						</div>
					</div>
				</div>
			</section>
			<TaxCategory />
			<FindESBN />
			<Calculator />
			<Faq />
			<Subscription />
			<Footer />
		</>
	);
};

export default Home;
