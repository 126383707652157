import React, { createRef, useEffect, useState } from "react";
import arrowRightIcon from "../../assets/arrowRightIcon.svg";
import styles1 from "../../Dashboard/MDAs/style.module.css";
import styles from "../MDAs/style.module.css";
import style from "./style.module.css";
import Alert from "../../components/Alert";
import Table from "../../components/Table";
import { useHistory } from "react-router";
import axios from "axios";
import RegularModal from "../../components/Modal/regular";
import downloadIcon from "../../assets/downloadFilingIcon.svg";
import { H1Document } from "./modals/h1Document";

const headers = [
	["Filed Year", "filedYear"],
	["Total Employee", "totalEmployees"],
	["Submission Status", "submissionStatus"],
	["Date Filed", "dateFiled", (val) => new Date(val).toLocaleDateString()]
];

const userData = JSON.parse(localStorage.getItem("user_info"));

export const AnnualReturnsCorporate = () => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [H1File, setH1File] = useState([]);
	const [total, setTotal] = useState(0);
	const [filters, setFilters] = useState({
		ESBN: "",
		filedYear: "",
		filingStatus: ""
	});

	const [downloadH1ModalStatus, setDownloadH1ModalStatus] = useState({
		downloading: false,
		showing: false,
		data: null
	});

	const filterRef = createRef();
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const history = useHistory();

	const getH1 = async (annualID, corporateCompanyID) => {
		setLoading(true);
		try {
			const accessToken = localStorage.getItem("access_token");
			let data = await axios
				.get(
					`company/annual-returns/${annualID}/${corporateCompanyID}`,
					{
						headers: {
							Authorization: `Bearer ${accessToken}`
						}
					}
				)
				.then((res) => res.data || { data: {} });

			if (!data || !data?.data) {
				// handle errors
				return null;
			}
			if (data) {
				setDownloadH1ModalStatus((h1Modal) => ({
					...h1Modal,
					showing: true,
					data: data
				}));
			}
		} catch (error) {
			setAlert({
				showing: true,
				type: "error",
				message:
					error?.response?.data?.message ??
					"H1 Data Fetching Unsuccessful!"
			});
		} finally {
			setLoading(false);
		}
	};

	const printH1File = () => {
		if (H1File) {
			const w = window.open();
			if (w.document) {
				w.document.write(H1File);
				w.document.close();
				w.onload = () => {
					w.focus();
					w.print();
				};

				w.onafterprint = () => {
					w.close();
				};
			} else {
				return;
			}
		} else {
			return;
		}
	};

	const fetchH1 =
		({ currentPage = 1 } = {}) =>
		async (e) => {
			e?.preventDefault?.();

			setLoading(true);
			if (!filterRef?.current?.classList?.contains(styles.no__display)) {
				filterRef?.current?.classList?.toggle(styles.no__display);
			}

			try {
				const accessToken = localStorage.getItem("access_token");
				let data = await axios
					.get(
						`coylandpage/annual-returns/h1filing/${userData?.anssid}`,
						{
							headers: {
								Authorization: `Bearer ${accessToken}`
							}
						}
					)
					.then((res) => res.data || { data: {} });

				if (!data || !data?.data) {
					return null;
				}
				setData(data?.data?.items);
				setTotal(data?.data?.pagination?.totalItems);
			} catch (error) {
				setAlert({
					showing: true,
					type: "error",
					message:
						error?.response?.data?.message ??
						"Annual Returns Fetching Unsuccessful!"
				});
			} finally {
				setLoading(false);
			}
		};

	useEffect(() => {
		fetchH1()();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);
	return (
		<div className={styles1.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{downloadH1ModalStatus.showing && (
				<RegularModal showing={downloadH1ModalStatus.showing}>
					<H1Document
						data={downloadH1ModalStatus?.data}
						getFile={(file) => setH1File(file)}
					/>
					<div className={style.action__btns}>
						<button
							className={`btn btn-danger`}
							onClick={() =>
								setDownloadH1ModalStatus((h1Modal) => ({
									...h1Modal,
									showing: false,
									data: {}
								}))
							}
						>
							<p>Close Modal</p>
						</button>
						<button
							className={`btn btn-success ${style.download__btn}`}
							onClick={() => printH1File()}
						>
							<p>Download H1 Details</p>
							<img src={downloadIcon} alt="download" />
						</button>
					</div>
				</RegularModal>
			)}
			<div className={style.flex__betweeen}>
				<h2>Find Annual Returns</h2>
				<button
					className={`btn btn-success ${style.download__btn}`}
					onClick={() => history.push("/file-annual-returns")}
				>
					<p>File Annual Returns</p>
					<img src={arrowRightIcon} alt="download" />
				</button>
			</div>
			<div className={styles.actions__div}>
				<button></button>
				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							disabled={true}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="owneresbn">Esbn</label>
								<input
									name="owneresbn"
									id="owneresbn"
									type="text"
									value={filters.ESBN}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											ESBN: e.target.value
										}))
									}
									placeholder="Esbn"
								/>
							</div>
						</form>
						<p>By Filing Details</p>
						<form>
							<div>
								<label htmlFor="filedYear">Filed Year</label>
								<input
									name="filedYear"
									id="filedYear"
									type="text"
									value={filters.filedYear}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											filedYear: e.target.value
										}))
									}
									placeholder="Eg 22/12/1990"
								/>
							</div>
							<div>
								<label htmlFor="filingStatus">
									Filing Status
								</label>
								<input
									name="filingStatus"
									id="filingStatus"
									type="text"
									value={filters.filingStatus}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											filingStatus: e.target.value
										}))
									}
								/>
							</div>
							<div>
								<label htmlFor="agentName">Agent Name</label>
								<input
									name="agentName"
									id="agentName"
									type="text"
									value={filters.agentName}
									onChange={(e) =>
										setFilters(() => ({
											...filters,
											agentName: e.target.value
										}))
									}
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={""}
									// onChange={
									// 	(e) => {}
									// 	setStartDate(e.target.value)
									// }
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={""}
									// onChange={
									// 	(e) => {}
									// 	setEndDate(e.target.value)
									// }
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={fetchH1()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						// onView={(data) => {
						// 	history.push(`/annual-returns/view-employees`, {
						// 	    coy: data.CoyId,
						// 	    userId: data.BillNumber
						// 	});
						// }}
						onH1={(data) => {
							getH1(
								data?.annualReturnsId,
								data?.corporatecoyfoldid
							);
						}}
					/>
				)}
			</div>
		</div>
	);
};
