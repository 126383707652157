import React from "react";
import axios from "axios";
import CreateTaxPayer from "./create";
import styles2 from "./style.module.css";
import { useHistory } from "react-router";
import Modal from "../../components/Modal/regular";
import Table from "../../components/Table";
import Alert from "../../components/Alert";
import anssidStyles from "./style.module.css";
import styles from "../MDAs/style.module.css";
import hniStyle from "../HNI/style.module.css";
import { exportExcel, remapObj } from "../../utils";
import { useState, useEffect, createRef } from "react";
import { formatAmount, formatDate } from "../../utils";
import styles1 from "../../Dashboard/MDAs/style.module.css";
import downloadIcon from "../../assets/whiteDownloadIcon.svg";
import MDAstyles from "../../Dashboard/MDAs/style.module.css";
import approvedCheck from "../../assets/Certificate/approvedCheck.svg";
import failureCheck from "../../assets/Certificate/failureCheck.svg";

const user = JSON.parse(localStorage.getItem("user_info"));
const headers = [
	["ESBN", "OwnersESBN"],
	["Bill Number", "BillNumber"],
	["Shop Owner's Name", "ownersName"],
	["Line of Business", "residentialaddress"],
	["Shop Number", "shopnumber"],
	["Amount", "GrandTotal", formatAmount],
	["Email", "OwnerEmail"],
	["Phone", "OwnersPhone"],
	// ["Tax Zone", "TaxZone"],
	// ["Tax LGA", "TaxLGA"],s
	// ["Tax Office", "TaxOffice"],
	// ["House Number", "HouseNo"],
	// ["House Street", "HouseStreet"],
	["Created At", "createdAt", formatDate],
	["Payment Status", "PaymentStatus"],
	["Process Status", "ApproveFlg", getStatusText],
	["Inputter", "Inputter"]
];

function getStatusText(approveRejectFlag) {
	// 1: Cancel; 2: Declined; 3: Approved; 4: Revoked; 5: Pending Approval; 6: Deleted
	if (approveRejectFlag === "0") {
		return "Pending";
	} else if (approveRejectFlag === "1") {
		return "Cancelled";
	} else if (approveRejectFlag === "2") {
		return "Declined";
	} else if (approveRejectFlag === "3") {
		return "Approved";
	} else if (approveRejectFlag === "4") {
		return "Revoked";
	} else if (approveRejectFlag === "5") {
		return "Pending Approval";
	}
	else {
		return "Deleted";
	}
}

const Profile = () => {
	const history = useHistory();

	const [createModal, setCreateModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState([]);
	const [OwnersESBN, setOwnersESBN] = useState("");
	const [BillNumber, setBillNumber] = useState("");
	const [startDate, setStartDate] = useState("");
	const [endDate, setEndDate] = useState("");
	const [ownersName, setOwnersName] = useState("");
	const [Name, setName] = useState("");
	const [residentialaddress, setresidentialaddress] = useState("");
	// const [OwnersPhone, setOwnersPhone] = useState("");
	const [Inputter, setInputter] = useState("");
	const [PropertyId, setPropertyId] = useState("");
	// const [shopNum, setShopNum] = useState("");
	const [shopnumber, setshopnumber] = useState("");
	const [houseStreet, setHouseStreet] = useState("");
	const [taxZone, setTaxZone] = useState("");
	const [allTaxZones, setAllTaxZones] = useState([]);
	const [taxLGA, setTaxLGA] = useState("");
	const [allTaxLGAs, setAllTaxLGAs] = useState([]);
	const [taxOffice, setTaxOffice] = useState("");
	const [allTaxOffices, setAllTaxOffices] = useState([]);
	const [paymentStatus, setPaymentStatus] = useState("");
	const [processStatus, setProcessStatus] = useState("");
	const [minAmount, setMinAmount] = useState("");
	const [maxAmount, setMaxAmount] = useState("");
	const [filtersCleared, setFiltersCleared] = useState(false);
	const [total, setTotal] = useState(0);
	const filterRef = createRef();

	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	// New Approval system start
	const [certificate, setCertificate] = useState(null);
	const [loadingCert, setLoadingCert] = useState(false);
	const [downloading, setDownloading] = useState(false);
	const [sendingToMail, setSendingToMail] = useState(false);
	const [deletionReason, setDeletionReason] = useState(" ");
	const [viewModalShowing, setViewModalShowing] = useState(false);
	const [sendingForApproval, setSendingForApproval] = useState(false);
	const [reviewedCertDetails, setReviewedCertDetails] = useState(null);
	const [approvalCertDetails, setApprovalCertDetails] = useState(null);
	const [cancelApprovalRequest, setCancelApprovalRequest] = useState(false);
	const [deletionApprovalRequest, setDeleteApprovalRequest] = useState(false);
	const [deletionSuccessModalShowing, setDeletionSuccessModalShowing] = useState(false);
	const [
		approvalSuccessModalShowing,
		setApprovalSuccessModalShowing
	] = useState(false);
	const [rejecting, setRejecting] = useState(false);
	const [
		rejectionSuccessModalShowing,
		setRejectionSuccessModalShowing
	] = useState(false);

	const [rejectReason, setRejectReason] = useState("");

	const reject = async (e) => {
		e?.preventDefault();
		setRejecting(true);
		try {
			const res = await axios
				.post(`processmda`, {
					billno: reviewedCertDetails?.billNumber,
					approve: "2",
					recId: reviewedCertDetails?.id,
					coyid: user?.mdaModuleCoy,
					reason4Reject: rejectReason
				})
				.then((res) => res.data);

			setRejectionSuccessModalShowing(false);
			setRejectReason("");
			setAlert({
				...alert,
				showing: true,
				type: "success",
				message: res.msg
			});
			getProfile()();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setRejecting(false);
		}
	};

	const requestForApproval = async (approvalCode, reason = '') => {
		approvalCode === "5"
			? setSendingForApproval(true)
			: approvalCode === "1" ?
				setDeleteApprovalRequest(true)
				: setCancelApprovalRequest(true)

		try {
			const res = await axios.post(`/processmda`, {
				...approvalCertDetails,
				approve: approvalCode,
				recId: reviewedCertDetails?.id,
				coyid: user?.mdaModuleCoy,
				reason4Reject: reason,
			});

			setViewModalShowing(false);
			getProfile()();
			setRejectionSuccessModalShowing(false);
			setDeletionSuccessModalShowing(false);
			setDeleteApprovalRequest(false);
			setAlert((alert) => ({
				...alert,
				showing: true,
				type: "success",
				message: res.data.msg
			}));
			setApprovalCertDetails(null);
		} catch (error) {
			if (error.response) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message ||
						error.response?.data.err.message
				}));
			} else if (error.request) {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.request
				}));
			} else {
				setAlert((alert) => ({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				}));
			}
		} finally {
			setSendingForApproval(false)
			setDeleteApprovalRequest(false)
			setCancelApprovalRequest(false)
		}
	};

	const handleView = (data) => {
		viewCertificate(data.nb, data?.id, 1);
		setReviewedCertDetails({
			billNumber: data.nb,
			Name: data.Name || data?.OwnersName,
			id: data?.id,
			status: getStatusText(data.ApproveFlg)
		});
	}

	// Review
	const handleBillActions = async (billNumber, rptmode = 1) => {
		if (rptmode === 2) {
			setDownloading(true);
		}

		if (rptmode === 3) {
			setSendingToMail(true);
		}
		try {
			await axios
				.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}/${rptmode}`, {
					responseType: rptmode === 2 ? "blob" : "json"
				})
				.then((res) => {
					if (rptmode > 1) {
						setAlert({
							showing: true,
							type: "success",
							message: rptmode === 3 ?
								res?.data?.msg ||
								res?.msg
								: "Bill download complete."
						});
						if (rptmode === 2) {
							handleInvoiceDownload(res?.data)
						}
					}

				});
		} catch (error) {
			setAlert({
				...alert,
				showing: true,
				type: "error",
				message:
					error.response.data?.msg ||
					error.response.data?.errors?.[0] ||
					error.response.data?.errors?.[0]?.message ||
					error.response.data?.errors?.details[0]?.message ||
					error.response?.msg ||
					error.response?.data?.message
			});
		} finally {
			setDownloading(false);
			setSendingToMail(false);
		}
	}

	const viewCertificate = async (billNumber, id, viewMode) => {
		if (viewMode === 1) {
			setLoadingCert(true);
			setViewModalShowing(true);
		} else if (viewMode === 2) {
			setDownloading(true);
		} else {
			setSendingToMail(true);
		}
		try {
			const data = await axios
				.get(`mdanotice/${billNumber}/${user?.mdaModuleCoy}/1`, {
					responseType: viewMode === 2 ? "blob" : "json"
				})
				.then((res) => res.data);

			if (viewMode === 1) {
				setCertificate(data);
				setApprovalCertDetails({
					billno: billNumber,
					recId: id,
					reason4Reject: rejectReason
				});
			} else if (viewMode === 2) {
				const blobURL = URL.createObjectURL(data);
				const element = document.createElement("a");
				element.href = blobURL;
				element.download = `Bill.pdf`;
				document.body.appendChild(element);
				element.click();
				document.body.removeChild(element);
				URL.revokeObjectURL(blobURL);
			} else {
				setViewModalShowing(false);
				setAlert({
					...alert,
					showing: true,
					type: "success",
					message: data.msg
				});
			}
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response.msg ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		} finally {
			setLoadingCert(false);
			setDownloading(false);
			setSendingToMail(false);
		}
	};

	const printCertificate = () => {
		const w = window.open();
		w.document.write(certificate);
		w.document.close();
		w.onload = () => {
			w.focus();
			w.print();
		};
		w.onafterprint = () => {
			w.close();
		};
	};

	const handleInvoiceDownload = (data) => {
		const blob = new Blob([data], { type: "application/pdf" });

		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(blob, 'downloaded-file.pdf')
		} else {
			const url = window.URL.createObjectURL(blob)
			const link = document.createElement('a');
			link.href = url;
			link.download = 'Bill.pdf';
			document.body.appendChild(link);
			link.click();
			// Clean up DOM
			document.body.removeChild(link);
			window.URL.revokeObjectURL(url);
		}
	}
	// New Approval system end

	const getProfile =
		({ currentPage = 1, pageSize = 20, download = false } = {}) =>
			async (e) => {
				e?.preventDefault?.();

				setLoading(true);
				if (!filterRef?.current?.classList?.contains(styles.no__display)) {
					filterRef?.current?.classList?.toggle(styles.no__display);
				}

				let query = `page=${currentPage}&pageSize=${pageSize}&`;
				if (ownersName) {
					query += `Name=${ownersName}&`;
				}

				if (OwnersESBN) {
					query += `ESBN=${OwnersESBN}&`;
				}

				if (residentialaddress) {
					query += `lineofbiz=${residentialaddress}&`;
				}

				if (shopnumber) {
					query += `shopno=${shopnumber}&`;
				}

				if (BillNumber) {
					query += `BillNo=${BillNumber}&`;
				}

				if (startDate) {
					query += `startdate=${startDate}&`;
				}

				if (endDate) {
					query += `enddate=${endDate}&`;
				}

				if (Inputter) {
					query += `Inputter=${Inputter}&`;
				}

				if (PropertyId) {
					query += `propertyId=${PropertyId}&`;
				}

				if (houseStreet) {
					query += `houseStreet=${houseStreet}&`;
				}

				if (taxOffice) {
					query += `taxOffice=${taxOffice}&`;
				}

				if (taxZone) {
					query += `TaxZone=${taxZone}&`;
				}

				if (taxLGA) {
					query += `TaxLGA=${taxLGA}&`;
				}

				if (paymentStatus) {
					query += `paymentStatus=${paymentStatus}&`;
				}

				if (processStatus) {
					query += `processStatus=${processStatus}&`;
				}

				if (minAmount) {
					query += `minAmount=${minAmount}&`;
				}

				if (maxAmount) {
					query += `maxAmount=${maxAmount}&`;
				}

				try {
					const accessToken = localStorage.getItem("access_token");
					let data = await axios
						.get(
							`mdareports/mdabilldisplay/${user?.mdaModuleCoy}?${query}`,
							{
								headers: {
									Authorization: `Bearer ${accessToken}`
								}
							}
						)
						.then((res) => res.data || { data: {} });

					if (!data || !data?.data) {
						// handle errors
						return null;
					}

					if (!download) setData(data.data);
					setTotal(data?.total);

					setData(data.data);
					setTotal(data?.total);
					return data.data;
				} catch (error) {
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response?.data?.msg ||
								error.response?.data?.errors?.[0] ||
								error.response?.data?.errors?.[0]?.message ||
								error.response?.data?.errors?.details[0]?.message ||
								error.response?.message ||
								error.response?.data?.message
						});
					} else {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: error.response?.data?.msg
						});
					}
				} finally {
					setLoading(false);
				}
			};

	const getAllTaxOffices = async () => {
		try {
			const offices = await axios
				.get(`settings/assesstaxoffice`)
				.then((res) => res.data);

			setAllTaxOffices(offices.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response?.data?.msg ||
						error.response?.data?.errors?.[0] ||
						error.response?.data?.errors?.[0]?.message ||
						error.response?.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response.data?.msg
				});
			}
		}
	};

	const getAllTaxLGAs = async () => {
		try {
			const LGAs = await axios
				.get(`settings/lgass/01`)
				.then((res) => res.data);

			setAllTaxLGAs(LGAs.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response?.data?.msg ||
						error.response?.data?.errors?.[0] ||
						error.response?.data?.errors?.[0]?.message ||
						error.response?.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		}
	};

	const getAllTaxZones = async () => {
		try {
			const zones = await axios
				.get(`settings/zones/${user?.mdaModuleCoy}`)
				.then((res) => res.data);

			setAllTaxZones(zones.data);
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response?.data?.msg ||
						error.response?.data?.errors?.[0] ||
						error.response?.data?.errors?.[0]?.message ||
						error.response?.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.response?.data?.msg
				});
			}
		}
	};

	const exportAnalytics = async () => {
		const info = await getProfile({
			currentPage: 1,
			pageSize: total,
			download: true
		})();
		exportExcel(remapObj(info, headers), headers, `New Haven Report.xlsx`);
	};

	const clearFilters = () => {
		setOwnersESBN("");
		setStartDate("");
		setEndDate("");
		setOwnersName("");
		setName("");
		setresidentialaddress("");
		setshopnumber();
		// setOwnerEmail("");
		// setOwnersPhone("");
		setBillNumber("");
		setInputter("");
		setPropertyId("");
		setHouseStreet("");
		setTaxOffice("");
		setTaxLGA("");
		setTaxZone("");
		setProcessStatus("");
		setPaymentStatus("");
		setMinAmount("");
		setMaxAmount("");

		filterRef.current.classList.toggle(styles.no__display);
		setFiltersCleared(true);
	};

	useEffect(() => {
		getProfile()();
		setFiltersCleared(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filtersCleared === true]);

	useEffect(() => {
		getProfile()();
		getAllTaxLGAs();
		getAllTaxZones();
		getAllTaxOffices();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles1.MDA__page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{viewModalShowing && (
				<Modal toggle={setViewModalShowing} showing={viewModalShowing}>
					{loadingCert ? (
						<p style={{ textAlign: "center" }}>Loading...</p>
					) : (
						<>
							<div className={styles2.cert__btns}>
								{reviewedCertDetails.status === "Approved" && (
									<>
										<button
											className="btn btn-danger"
											style={{
												padding: '12px',
												height: 'fit-content'
											}}
											onClick={(e) => {
												e.preventDefault();
												setViewModalShowing(false);
											}}
										>
											{"Close"}
										</button>
										<button
											className={`tertiary__btn ${styles2.download__btn}`}
											style={{ marginBottom: "20px" }}
											onClick={() => printCertificate()}
										>
											Print
										</button>
										<button
											className={`secondary__btn ${styles2.download__btn}`}
											style={{ marginBottom: "20px" }}
											onClick={() =>
												handleBillActions(reviewedCertDetails.billNumber, 2)
											}
										>
											<img
												src={downloadIcon}
												alt="download icon"
												style={{ marginRight: "8px" }}
											/>
											{downloading
												? "Downloading..."
												: "Download"}
										</button>
										<button
											className={styles2.to__mail_btn}
											style={{ marginBottom: "20px" }}
											onClick={() =>
												handleBillActions(reviewedCertDetails.billNumber, 3)
											}
										>
											{sendingToMail
												? "Sending..."
												: "Send to Email"}
										</button>
									</>
								)}
								{reviewedCertDetails.status === "Pending Approval" && (
									<>
										<button
											className="btn btn-danger"
											// disabled={!rejectReason || cancelApprovalRequest}
											onClick={(e) => {
												e.preventDefault();
												setViewModalShowing(false);
											}}
										>
											{"Close"}
										</button>
										<button
											style={{ padding: "0 25px" }}
											className={`tertiary__btn ${styles.request__btn}`}
											onClick={() => setRejectionSuccessModalShowing(true)}
										>
											{"Cancel Bill"}
										</button>
									</>
								)}
								{reviewedCertDetails.status === "Cancelled" && (
									<>
										<button
											className="btn btn-danger"
											// disabled={!rejectReason || cancelApprovalRequest}
											onClick={(e) => {
												e.preventDefault();
												setViewModalShowing(false);
											}}
										>
											{"Close"}
										</button>
										<button
											style={{ padding: "0 25px" }}
											className={`tertiary__btn ${styles.request__btn}`}
											onClick={() => setDeletionSuccessModalShowing(true)}
										>
											{"Delete Bill"}
										</button>
									</>
								)}
								{reviewedCertDetails.status === "Pending" && (
									<>
										<button
											className="btn btn-danger"
											// disabled={!rejectReason || cancelApprovalRequest}
											onClick={(e) => {
												e.preventDefault();
												setViewModalShowing(false);
											}}
										>
											{"Close"}
										</button>
										<button
											style={{ padding: "0 25px" }}
											onClick={() => requestForApproval("5")}
											className={`primary__btn ${styles.request__btn}`}
										>
											{sendingForApproval
												? "Requesting..."
												: "Request for Approval"}
										</button>
									</>
								)}
							</div>
							<div
								dangerouslySetInnerHTML={{
									__html: certificate
								}}
							></div>
						</>
					)}
				</Modal>
			)}
			{/* approval success modal */}
			{approvalSuccessModalShowing && (
				<Modal>
					<div className={styles.approvalSuccess__badge}>
						<img
							src={approvedCheck}
							alt="circle with a tick check inside"
						/>
						<span>APPROVED</span>
					</div>
					<p className={styles.approve__success_message}>
						You have successfully approved{" "}
						<b>{reviewedCertDetails?.name}</b>'s Bill, an email would be sent to notify them
						accordingly.
					</p>
					<button
						className={styles.continue__btn}
						onClick={() => {
							setReviewedCertDetails(null);
							setApprovalSuccessModalShowing(false);
						}}
					>
						Continue
					</button>
				</Modal>
			)}
			{/* rejection success modal */}
			{rejectionSuccessModalShowing && (
				<Modal
					showing={rejectionSuccessModalShowing}
					toggle={setRejectionSuccessModalShowing}
				>
					<div className={hniStyle.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>REJECTION</span>
					</div>
					<p className={hniStyle.reject__reason_instruction}>
						You are about to reject{" "}
						<b>{reviewedCertDetails?.Name || reviewedCertDetails?.name}</b>'s Bill. Please, state your reason.
					</p>
					<form
						onSubmit={(e) => reject(e)}
						className={hniStyle.reject__reason_form}
					>
						<label htmlFor="rejectReason">
							Reason for Rejecting{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="rejectReason"
							placeholder="write the reason for rejecting this Bill"
							value={rejectReason}
							onChange={(e) => setRejectReason(e.target.value)}
							rows={2}
						/>
						<button
							type="submit"
							className="primary__btn"
							disabled={!rejectReason || rejecting}
						>
							{rejecting ? "Continuing..." : "Continue"}
						</button>
					</form>
				</Modal>
			)}
			{deletionSuccessModalShowing && (
				<Modal
					showing={deletionSuccessModalShowing}
					toggle={setDeletionSuccessModalShowing}
				>
					<div className={styles2.rejection__badge}>
						<img src={failureCheck} alt="circle with an X inside" />
						<span>DELETION</span>
					</div>
					<p className={styles2.reject__reason_instruction}>
						You are about to delete{" "}
						<b>{reviewedCertDetails?.Name}</b>'s Bill. Please, state your reason.
					</p>
					<form
						onSubmit={(e) => {
							e.preventDefault();
							requestForApproval("6", deletionReason)
						}}
						className={styles2.reject__reason_form}
					>
						<label htmlFor="rejectReason">
							Reason for deletion{" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<textarea
							id="rejectReason"
							placeholder="write the reason for cancelling this Bill"
							value={deletionReason}
							onChange={(e) => setDeletionReason(e.target.value)}
							rows={2}
						/>
						<div style={{
							display: 'flex'
							// gap: 'px'
						}}>
							<button
								className="tertiary__btn"
								disabled={!deletionReason || deletionApprovalRequest}
								onClick={(e) => {
									e.preventDefault();
									setDeletionSuccessModalShowing(false);
								}}
							>
								{"Close"}
							</button>
							<button
								type="submit"
								className="primary__btn"
								disabled={!deletionReason || deletionApprovalRequest}
							>
								{cancelApprovalRequest
									? "Deleting..."
									: "Delete Bill"}
							</button>
						</div>
					</form>
				</Modal>
			)}
			<div className={styles.actions__div}>
				<button
					style={{
						padding: "0 25px"
					}}
					className={["primary__btn", anssidStyles.create__btn].join(
						" "
					)}
					onClick={() => {
						history.push("/multi-company/create-new-haven", {
							// taxpayers: true,
							// fromIndividual: true,
							// ssActive: "1"
						});
					}}
				>
					Prepare bill
				</button>
				<button
					className={[
						"primary__btn",
						MDAstyles.download__button
					].join(" ")}
					onClick={() => exportAnalytics()}
				// disabled={loading}
				>
					Download Report
				</button>

				<div className={styles.filter}>
					<button
						onClick={() => {
							filterRef.current.classList.toggle(
								styles.no__display
							);
						}}
						className={styles.filter__button}
					>
						Filter
					</button>
					<div
						ref={filterRef}
						className={[
							styles.filter__div,
							styles.no__display
						].join(" ")}
					>
						<div className={styles.filter__header}>
							<h6>Filter</h6>
							<button
								aria-labelledby="close filter button"
								onClick={() => {
									filterRef.current.classList.toggle(
										styles.no__display
									);
								}}
							>
								X
							</button>
						</div>
						<button
							className={styles.clear__filter_fields}
							onClick={clearFilters}
							disabled={
								!OwnersESBN &&
								!startDate &&
								!endDate &&
								!ownersName &&
								!Name &&
								!residentialaddress &&
								shopnumber &&
								// !OwnerEmail &&
								// !OwnersPhone &&
								!BillNumber &&
								!Inputter &&
								// !PropertyId &&
								!houseStreet &&
								!taxOffice &&
								!taxZone &&
								!taxLGA &&
								!processStatus &&
								!paymentStatus &&
								!minAmount &&
								!maxAmount
							}
						>
							Clear Filter fields
						</button>
						<p>By Code</p>
						<form>
							<div>
								<label htmlFor="owneresbn">ESBN</label>
								<input
									name="owneresbn"
									id="owneresbn"
									type="text"
									value={OwnersESBN}
									onChange={(e) =>
										setOwnersESBN(e.target.value)
									}
									placeholder="Eg. 00909986588"
								/>
							</div>
							<div>
								<label htmlFor="billNumber">Bill Number</label>
								<input
									name="billNumber"
									id="billNumber"
									type="text"
									value={BillNumber}
									onChange={(e) =>
										setBillNumber(e.target.value)
									}
									placeholder="Eg. AB2024155662EN"
								/>
							</div>
						</form>
						<p>By Owner Details</p>
						<form>
							<div>
								<label htmlFor="ownersName">Owner's Name</label>
								<input
									name="ownersName"
									id="ownersName"
									type="text"
									value={ownersName}
									onChange={(e) =>
										setOwnersName(e.target.value)
									}
									placeholder="Eg. Odogwu Okeke"
								/>
							</div>
							{/* <div>
								<label htmlFor="Name">Owner Name Email</label>
								<input
									name="Name"
									id="Name"
									type="email"
									value={Name}
									onChange={(e) => setName(e.target.value)}
									placeholder="Eg. odogwu@okeke.com"
								/>
							</div> */}
							{/* <div>
								<label htmlFor="OwnersPhone">Owner Phone</label>
								<input
									name="OwnersPhone"
									id="OwnersPhone"
									type="tel"
									value={OwnersPhone}
									onChange={(e) =>
										setOwnersPhone(e.target.value)
									}
									placeholder="Eg. 09333348848"
								/>
							</div> */}
							<div>
								<label htmlFor="residentialaddress">
									Line Of Business
								</label>
								<input
									name="residentialaddress"
									id="residentialaddress"
									type="text"
									value={residentialaddress}
									onChange={(e) =>
										setresidentialaddress(e.target.value)
									}
									placeholder="Eg. 4567"
								/>
							</div>
							<div>
								<label htmlFor="shopnumber">Shop Number</label>
								<input
									name="shopnumber"
									id="shopnumber"
									type="text"
									value={shopnumber}
									onChange={(e) =>
										setshopnumber(e.target.value)
									}
									placeholder="Eg. No. 34 Odimegwu"
								/>
							</div>
							{/* <div>
								<label htmlFor="houseStreet">
									House Street
								</label>
								<input
									name="houseStreet"
									id="houseStreet"
									type="text"
									value={houseStreet}
									onChange={(e) =>
										setHouseStreet(e.target.value)
									}
									placeholder="Eg. Uduma Street"
								/>
							</div> */}
							<div>
								<label htmlFor="taxLGA">Tax LGA</label>
								<select
									name="taxLGA"
									id="taxLGA"
									value={taxLGA}
									onChange={(e) => setTaxLGA(e.target.value)}
								>
									<option value="">--select tax LGA--</option>
									{allTaxLGAs?.map((LGA, i) => (
										<option key={i}>
											{LGA.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="taxZone">Tax Zone</label>
								<select
									name="taxZone"
									id="taxZone"
									value={taxZone}
									onChange={(e) => setTaxZone(e.target.value)}
								>
									<option value="">
										--select tax zone--
									</option>
									{allTaxZones?.map((zone, i) => (
										<option key={i}>
											{zone.Description}
										</option>
									))}
								</select>
							</div>
							<div>
								<label htmlFor="taxOffice">Tax Office</label>
								<select
									name="taxOffice"
									id="taxOffice"
									value={taxOffice}
									onChange={(e) =>
										setTaxOffice(e.target.value)
									}
								>
									<option value="">
										--select tax office--
									</option>
									{allTaxOffices?.map((office, i) => (
										<option key={i}>
											{office.Description}
										</option>
									))}
								</select>
							</div>
						</form>
						<p>By Amount</p>
						<form>
							<div>
								<label htmlFor="minAmount">Min Amount</label>
								<input
									name="minAmount"
									id="minAmount"
									type="number"
									value={minAmount}
									onChange={(e) =>
										setMinAmount(e.target.value)
									}
									placeholder="₦ 100, 000"
								/>
							</div>
							<div>
								<label htmlFor="maxAmount">Max Amount</label>
								<input
									name="maxAmount"
									id="maxAmount"
									type="number"
									value={maxAmount}
									onChange={(e) =>
										setMaxAmount(e.target.value)
									}
									placeholder="₦ 1, 000, 000"
								/>
							</div>
						</form>
						<p>By Status</p>
						<form>
							<div>
								<label htmlFor="processStatus">
									Process Status
								</label>
								<select
									name="processStatus"
									id="processStatus"
									value={processStatus}
									onChange={(e) =>
										setProcessStatus(e.target.value)
									}
								>
									<option value="">
										--select process status--
									</option>
									<option>Approved</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
							<div>
								<label htmlFor="paymentStatus">
									Payment Status
								</label>
								<select
									name="paymentStatus"
									id="paymentStatus"
									value={paymentStatus}
									onChange={(e) =>
										setPaymentStatus(e.target.value)
									}
								>
									<option value="">
										--select payment status--
									</option>
									<option>Paid</option>
									<option>Pending</option>
									<option>Revoked</option>
								</select>
							</div>
						</form>
						<p>By Inputter</p>
						<form>
							<div>
								<label htmlFor="Inputter">Inputter</label>
								<input
									name="Inputter"
									id="Inputter"
									type="email"
									value={Inputter}
									onChange={(e) =>
										setInputter(e.target.value)
									}
									placeholder="Eg. ada@example.com"
								/>
							</div>
						</form>
						<p>By Creation Date</p>
						<form>
							<div>
								<label htmlFor="start_date">Start Date</label>
								<input
									name="start_date"
									id="start_date"
									type="date"
									value={startDate}
									onChange={(e) =>
										setStartDate(e.target.value)
									}
								/>
							</div>
							<div>
								<label htmlFor="start_date">End Date</label>
								<input
									name="end_date"
									id="end_date"
									type="date"
									value={endDate}
									onChange={(e) => setEndDate(e.target.value)}
								/>
							</div>
						</form>
						<button
							className={[
								styles.submit__button,
								"primary__btn"
							].join(" ")}
							onClick={getProfile()}
						>
							Search
						</button>
					</div>
				</div>
			</div>
			<div className={styles.table__div}>
				{loading && !data ? (
					<p style={{ textAlign: "center" }}>Loading...</p>
				) : (
					<Table
						headers={headers}
						data={data}
						full
						pageSize={20}
						total={total}
						onPageChanged={getProfile}
						onEdit={(data) => {
							Number(data?.ApproveFlg) <= 2 ?
								history.push(`/multi-company/billing4NewHaven/edit`, {
									coy: data.CoyId,
									userId: data.nb
								}) :
								setAlert({
									showing: true,
									type: 'error',
									message:
										`${getStatusText(data.ApproveFlg)} Bill cannot be edited.`
								})
						}}
						onView={(data) => {
							Number(data?.ApproveFlg) !== 6 ?
								handleView(data) :
								setAlert({
									showing: true,
									type: 'error',
									message:
										`${getStatusText(data.ApproveFlg)} Bill cannot be viewed.`
								})
						}}
					/>
				)}
			</div>
			{createModal && (
				<Modal small showing={createModal} toggle={setCreateModal}>
					<CreateTaxPayer />
				</Modal>
			)}
		</div>
	);
};

export default Profile;
