import { useState, useEffect } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import constants from "../constants";
import styles from "../Home/style.module.css";
import Logo from "../assets/Home/esirsLogo.png";
import { ReactComponent as CloseMobileMenu } from "../assets/Home/closeMobileMenu.svg";
import { ReactComponent as MobileMenu } from "../assets/Home/hamburger.svg";
import TaxCalcInputter from "./sidebars/taxCalcInputter";
import LookUpESBN from "./sidebars/lookup/esbn";
import LookUpReceipt from "./sidebars/lookup/receipt";
import LookUpCertificate from "./sidebars/lookup/certificate";
import arrowRightIcon from "../assets/arrowRightIcon.svg";
import Instagram from "../assets/Home/instagram.svg";
import Facebook from "../assets/Home/facebook.svg";
import X from "../assets/Home/x.svg";
import Norlics from "../assets/Home/Norlics.svg";

const coyIMS = process.env.REACT_APP_COYADMIN;

const NavBar = () => {
	const location = useLocation();

	const [mobileState, setMobileState] = useState({
		state: false,
		link: ""
	});

	useEffect(() => {
		if (location.hash) {
			const element = document.querySelector(location.hash);
			if (element) {
				element.scrollIntoView({ behavior: "smooth" });
			}
		}
	}, [location]);

	return (
		<main>
			<TaxCalcInputter />
			<LookUpESBN />
			<LookUpReceipt />
			<LookUpCertificate />

			<header className={styles.header}>
				<div className={styles.desktop__header_logo}>
					<img src={Logo} alt="Logo" />
				</div>
				<img
					src={Logo}
					alt="NorIMS mobile logo"
					className={styles.mobile__header_logo}
				/>

				<nav className={styles?.desktop__nav}>
					<NavLink
						to={{
							pathname: "/home",
							state: { anscims: true }
						}}
						className={styles.nav__link}
						activeClassName={styles.activeNavLink}
					>
						Home
					</NavLink>

					<li className={`dropdown list-unstyled`}>
						<NavLink
							to="#"
							className={`${styles.nav__link} dropdown-toggle`}
							data-toggle="dropdown"
						>
							ESBN <span className="caret"></span>
						</NavLink>
						<ul
							className={`${styles.dropdownLink} dropdown-menu`}
							aria-labelledby="about-us"
						>
							<li type="button" data-toggle="modal">
								<NavLink
									to={{
										pathname: "/signup",
										state: { anscims: true }
									}}
									className={styles.nav__link}
								>
									Get ESBN
								</NavLink>
							</li>
							<li className={styles.findESBN}>
								<Link to="/home#find-ESBN">Find ESBN</Link>
							</li>
							<li
								className={styles.ConfirmESBN}
								type="button"
								data-toggle="modal"
								data-target="#lookUpESBN"
							>
								<NavLink to="#">Confirm ESBN</NavLink>
							</li>
						</ul>
					</li>

					<NavLink
						to={{
							pathname: "/login",
							state: { anscims: true }
						}}
						className={styles.nav__link}
					>
						Login to {coyIMS}
					</NavLink>
					<p
						type="button"
						className={`${styles.nav__link}`}
						data-toggle="modal"
						data-target="#myModal2"
					>
						Tax Calculator
					</p>
					<li className="dropdown list-unstyled">
						<NavLink
							to="#"
							className={`${styles.nav__link} dropdown-toggle`}
							data-toggle="dropdown"
						>
							Tax Information
							<span className="caret"></span>
						</NavLink>
						<ul
							className={`${styles.dropdownLink} dropdown-menu`}
							aria-labelledby="about-us"
						>
							<li type="button" data-toggle="modal">
								<NavLink
									to="tax-type"
									className={styles.nav__link}
									activeClassName={styles.activeNavLink}
								>
									Tax Types
								</NavLink>
							</li>
							<li type="button" data-toggle="modal">
								<NavLink
									to="tax-system"
									className={styles.nav__link}
									activeClassName={styles.activeNavLink}
								>
									Tax Systems
								</NavLink>
							</li>
							<li type="button" data-toggle="modal">
								<NavLink
									to="tax-collection"
									className={styles.nav__link}
									activeClassName={styles.activeNavLink}
								>
									Tax Collection
								</NavLink>
							</li>

							<li type="button" data-toggle="modal">
								<NavLink
									to="tax-legislation"
									className={styles.nav__link}
									activeClassName={styles.activeNavLink}
								>
									Tax Legislation
								</NavLink>
							</li>
						</ul>
					</li>
					<NavLink
						to={{
							pathname: "/about-us",
							state: { anscims: true }
						}}
						className={styles.nav__link}
						activeClassName={styles.activeNavLink}
					>
						About Us
					</NavLink>
					<NavLink
						to={{
							pathname: "/frequently-asked-questions",
							state: { anscims: true }
						}}
						className={styles.nav__link}
						activeClassName={styles.activeNavLink}
					>
						FAQ
					</NavLink>

					<div className="dropdown">
						<button
							className={` ${styles.nav__payment_btn} dropdown-toggle`}
							type="button"
							id="about-us"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
						>
							Pay Tax with
							<span
								className="caret"
								style={{ marginLeft: "8px" }}
							></span>
						</button>
						<ul
							className="dropdown-menu"
							aria-labelledby="about-us"
						>
							{constants.paymentGateways.map((gateway) => (
								<li>
									<NavLink
										to={gateway.link}
										className={styles.nav__link}
									>
										<img
											src={gateway.img}
											alt={gateway.name}
											style={{
												height: "23px",
												marginRight: "10px"
											}}
										/>
										{gateway.name}
									</NavLink>
								</li>
							))}
							<li>
								<NavLink to="/steps-to-pay-bills">
									<div className={styles.consolidatedSteps}>
										<span>
											Steps to Pay your Single <br /> or
											Consolidated Bills{" "}
										</span>
										<span style={{ marginRight: "5px" }}>
											<img
												src={arrowRightIcon}
												alt="arrowRightIcon"
												style={{ height: "23px" }}
											/>
										</span>
									</div>
								</NavLink>
							</li>
						</ul>
					</div>
				</nav>
				{mobileState?.state ? (
					<CloseMobileMenu
						className={styles?.mobile__icon}
						onClick={() =>
							setMobileState({ ...mobileState, state: false })
						}
					/>
				) : (
					<MobileMenu
						className={styles?.mobile__icon}
						onClick={() =>
							setMobileState({ ...mobileState, state: true })
						}
					/>
				)}
				{mobileState.state && (
					<div className={styles?.mobile__nav}>
						<ul>
							<li
								className={`${
									mobileState.link === `/home` &&
									styles.mobileActive
								}`}
							>
								<NavLink
									to={{
										pathname: "/home",
										state: { anscims: true }
									}}
								>
									Home
								</NavLink>
							</li>
							<li
								className={`${
									mobileState.link === `Login to Enugu CMS` &&
									styles.mobileActive
								}`}
								onClick={(e) =>
									setMobileState({
										...mobileState,
										link: e.target.innerText
									})
								}
							>
								<NavLink to="/signup">Get ESBN</NavLink>
							</li>
							<li
								className={`${
									mobileState.link === `Login to Enugu CMS` &&
									styles.mobileActive
								}`}
								onClick={(e) =>
									setMobileState({
										...mobileState,
										link: e.target.innerText
									})
								}
							>
								<NavLink to="/login">
									Login to Enugu CMS
								</NavLink>
							</li>

							<li
								className={`${
									mobileState.link === `Tax Calculator` &&
									styles.mobileActive
								}`}
								onClick={(e) =>
									setMobileState({
										...mobileState,
										link: e.target.innerText
									})
								}
								type="button"
								data-toggle="modal"
								data-target="#myModal2"
								style={{ color: "#337ab7" }}
								activeStyle={{ color: "#337ab7" }}
							>
								Tax Calculator
							</li>
							<li className="dropdown list-unstyled">
								<NavLink
									className={`${
										mobileState.link ===
											`Tax Information` &&
										styles.mobileActive
									}`}
									to="#"
									data-toggle="dropdown"
								>
									Tax Information
									<span className="caret"></span>
								</NavLink>
								<ul
									className={`${styles.dropdownLinkMobile} dropdown-menu`}
									aria-labelledby="about-us"
								>
									<li>
										<NavLink
											to="tax-type"
											className={styles.nav__link}
										>
											Tax Types
										</NavLink>
									</li>
									<li>
										<NavLink
											to="tax-system"
											className={styles.nav__link}
										>
											<span> Tax Systems </span>{" "}
										</NavLink>
									</li>
									<li>
										<NavLink
											to="tax-collection"
											className={styles.nav__link}
										>
											Tax Collection
										</NavLink>
									</li>

									<li>
										<NavLink
											to="tax-legislation"
											className={styles.nav__link}
										>
											Tax Legislation
										</NavLink>
									</li>
								</ul>
							</li>
							<li
								className={`${
									mobileState.link === `About Us` &&
									styles.mobileActive
								}`}
								onClick={(e) =>
									setMobileState({
										...mobileState,
										link: e.target.innerText
									})
								}
							>
								<NavLink to="/about-us">About Us</NavLink>
							</li>

							<li
								className={`${
									mobileState.link === `FAQ` &&
									styles.mobileActive
								}`}
								onClick={(e) =>
									setMobileState({
										...mobileState,
										link: e.target.innerText
									})
								}
							>
								<NavLink to="/frequently-asked-questions">
									FAQ
								</NavLink>
							</li>
							{/* <li
                                className={`${mobileState.link === `News` &&
                                    styles.mobileActive
                                    }`}
                                onClick={(e) =>
                                    setMobileState({
                                        ...mobileState,
                                        link: e.target.innerText
                                    })
                                }
                            >
                                <NavLink to="/latest-news">News</NavLink>
                            </li> */}
						</ul>

						<div className={`dropdown ${styles.payments}`}>
							<button
								className={`${styles.nav__payment_btn}  dropdown-toggle`}
								type="button"
								data-toggle="dropdown"
								aria-haspopup="true"
								aria-expanded="false"
							>
								Pay Tax with{" "}
								<span
									className="caret"
									style={{ marginLeft: "8px" }}
								></span>
							</button>
							<ul
								className={`dropdown-menu ${styles.paymentDropdownMobile}`}
								aria-labelledby="about-us"
							>
								{constants.paymentGateways.map((gateway) => (
									<li>
										<NavLink
											to={gateway.link}
											className={styles.nav__link}
										>
											<img
												src={gateway.img}
												alt={gateway.name}
												style={{
													height: "23px",
													marginRight: "10px"
												}}
											/>
											{gateway.name}
										</NavLink>
									</li>
								))}
								{/* <li>
									<NavLink to="/steps-to-pay-bills">
										<div
											className={styles.consolidatedSteps}
										>
											<span>
												Steps to Pay your Single <br />{" "}
												or Consolidated Bills{" "}
											</span>
											<span
												style={{ marginRight: "5px" }}
											>
												<img
													src={arrowRightIcon}
													alt="arrowRightIcon"
													style={{ height: "23px" }}
												/>
											</span>
										</div>
									</NavLink>
								</li> */}
							</ul>
						</div>
						<footer>
							<ul className="list-group">
								<li
									className={`${styles.socialMedia} list-group-item`}
								>
									<a
										href="https://www.facebook.com/profile.php?id=61559661552725&mibextid=ZbWKwL"
										target="_blank"
										rel="noreferrer"
									>
										<img src={Facebook} alt="Facebook" />
									</a>
									<a
										href="https://www.instagram.com/esrs_norlics?igsh=MXBqeHBqaWpsNmZlbg=="
										target="_blank"
										rel="noreferrer"
									>
										<img src={Instagram} alt="Instagram" />
									</a>
									<a
										href="https://x.com/norlics20125?t=kDI_rEmAwK-amtHJogWgCA&s=09"
										target="_blank"
										rel="noreferrer"
									>
										<img src={X} alt="Facebook" />
									</a>
								</li>
							</ul>
							<div className={styles.credits}>
								<p className="col-6">
									&copy; Copyright {new Date().getFullYear()}{" "}
									ESIRS. All rights reserved
								</p>
								<p className="col-6">
									Powered by &nbsp;
									<img src={Norlics} alt="Norlics" />
								</p>
							</div>
						</footer>
					</div>
				)}
			</header>
			<section className={styles.preHero}>
				<div className={styles.newsBg}>News</div>
				<div className={styles.NewsBody}>
					<span>
						Enugu polls: PDP sweeps all 260 councillorship seats
						(News Diary Online)
					</span>
					<span>
						Enugu government to introduce CNG buses, creates energy
						ministry (Tribune Online)
					</span>
					<span>
						Mbah mourns Akwa Ibom governor's wife (News Diary
						Online)
					</span>
					<span>
						Enugu Govt summons Nsukka traditional rulers, President
						General, over excesses of Oriokpa Masquerade (The
						Authority)
					</span>

					<span>
						Gov Mbah Comments prompt services at Enugu UK Visa
						Center, woos more foreign missions (Champion Newspapers
						Limited)
					</span>
					<span>
						World Tourism Day 2024: Enugu governor Peter Mbah
						Invests Billions in world-class Tourism center (Legit
						NG)
					</span>
					<span>
						Gov Mbah awards scholarships, cash to Enugu student for
						winning president's national debate championship
						(ThisDay Live)
					</span>
					<span>
						Two dams in platueau, Enugu on verge of complete
						-Minister (Independent Nigeria)
					</span>
					<span>
						Enugu police arrest two for breaking into car, stealing
						phones (people Gazette)
					</span>
				</div>
				<div className={styles.NewsBody}>
					<span>
						Enugu polls: PDP sweeps all 260 councillorship seats
						(News Diary Online)
					</span>
					<span>
						Enugu government to introduce CNG buses, creates energy
						ministry (Tribune Online)
					</span>
					<span>
						Mbah mourns Akwa Ibom governor's wife (News Diary
						Online)
					</span>
					<span>
						Enugu Govt summons Nsukka traditional rulers, President
						General, over excesses of Oriokpa Masquerade (The
						Authority)
					</span>
					<span>
						Gov Mbah Comments prompt services at Enugu UK Visa
						Center, woos more foreign missions (Champion Newspapers
						Limited)
					</span>
					<span>
						World Tourism Day 2024: Enugu governor Peter Mbah
						Invests Billions in world-class Tourism center (Legit
						NG)
					</span>
					<span>
						Gov Mbah awards scholarships, cash to Enugu student for
						winning president's national debate championship
						(ThisDay Live)
					</span>
					<span>
						Two dams in platueau, Enugu on verge of complete
						-Minister (Independent Nigeria)
					</span>
					<span>
						Enugu police arrest two for breaking into car, stealing
						phones (people Gazette)
					</span>
				</div>
			</section>
		</main>
	);
};

export default NavBar;
