import { useHistory } from "react-router";
import goBackIcon from "../../assets/blackArrowLeft.svg";
import styles from "./style.module.css";

const GoBackButton = () => {
    const history = useHistory();

    return (
        <button
            className={styles.back__btn}
            onClick={() => history.goBack()}
        >
            <img
                src={goBackIcon}
                alt="back arrow icon"
                style={{ marginRight: "10px" }}
            />
            Go Back
        </button>
    )
}

export default GoBackButton;