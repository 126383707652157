import React, { useState } from "react";
import styles from "../../MDAs/style.module.css";
import styles1 from "../../../Identity-management-individual/style.module.css";

import RegularModal from '../../../components/Modal/regular';

export const EditModal = ({
    data,
    incomeTypes = [],
    showing = false,
    closeModal = () => { },
    modalAction,
    modalType = "prepayments",
}) => {
    const [income, setIncome] = useState({
        IncomeType: data?.incometype?.trim() ?? data?.IncomeType?.trim() ?? '',
        IncomeSource: data?.incomesource?.trim() ?? data?.IncomeSource?.trim() ?? '',
        Amount: data?.amount ?? data?.Amount ?? ''
    })

    const [payment, setPayment] = useState({
        IncomeType: data?.incometype?.trim() ?? data?.IncomeType?.trim() ?? '',
        IncomeSource: data?.incomesource?.trim() ?? data?.IncomeSource?.trim() ?? '',
        Amount: data?.amount ?? data?.Amount ?? ''
    })

    const handleIncomeChange = (e) => {
        const { name, value } = e.target;
        setIncome({ ...income, [name]: value })
    }
    const handlePaymentChange = (e) => {
        const { name, value } = e.target;
        setPayment({ ...payment, [name]: value })
    }

    return (
        <RegularModal showing={showing} toggle={(val) => closeModal(val)}>

            <p className={styles.revoke__warning}>
                <p>After entering the information, click on "Update" to include it.
                    <br />
                    Repeat this process to add additional income sources.</p>
                <br />
            </p>

            {
                modalType === "income" &&
                <>
                    <div className={styles1.double__inputs}>
                        <div>
                            <label
                                className={styles.input__label}
                                htmlFor="IncomeType"
                            >
                                Type of Income
                                {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <select
                                id="IncomeType"
                                name="IncomeType"
                                onChange={handleIncomeChange}
                                value={income?.IncomeType}
                            // required
                            >
                                <option value="">Select</option>
                                {incomeTypes?.map((income, index) => (
                                    <React.Fragment key={income.Code + index}>
                                        <option
                                            value={income.Description}
                                        >
                                            {income.Description}
                                        </option>
                                    </React.Fragment>
                                ))}
                            </select>
                        </div>

                        <div>
                            <br />
                            <label className={styles1.input__label} htmlFor="IncomeSource">
                                Source of Income
                            </label>
                            <input
                                type="text"
                                id="IncomeSource"
                                name="IncomeSource"
                                onChange={handleIncomeChange}
                                placeholder=""
                                //  disabled
                                value={income?.IncomeSource}
                            />
                        </div>
                    </div>
                    <div>
                        <br />
                        <label className={styles.input__label} htmlFor="Amount">
                            Amount
                        </label>
                        <input
                            type="text"
                            id="Amount"
                            name="Amount"
                            onChange={handleIncomeChange}
                            placeholder=""
                            //  disabled
                            value={income?.Amount}
                        />
                    </div>
                </>
            }

            {
                modalType === "prepayments" &&
                <>
                    <div className={styles1.double__inputs}>
                        <div>
                            <label
                                className={styles.input__label}
                                htmlFor="IncomeType"
                            >
                                Type of Income
                                {/* <span style={{ color: "red" }}>*</span> */}
                            </label>
                            <select
                                id="IncomeType"
                                name="IncomeType"
                                onChange={handlePaymentChange}
                                value={payment?.IncomeType}
                            >
                                <option value="">Select</option>
                                {incomeTypes?.map((income, index) => (
                                    <React.Fragment key={income.Code + index}>
                                        <option value={income.Description}>
                                            {income.Description}
                                        </option>
                                    </React.Fragment>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div>
                            <br />
                            <label className={styles1.input__label} htmlFor="IncomeSource">
                                Source of Income
                            </label>
                            <input
                                type="text"
                                id="IncomeSource"
                                name="IncomeSource"
                                onChange={handlePaymentChange}
                                placeholder=""
                                value={payment?.IncomeSource}
                            />
                        </div>
                    <div>
                        <br />
                        <label className={styles.input__label} htmlFor="Amount">
                            Amount
                        </label>
                        <input
                            type="text"
                            id="Amount"
                            name="Amount"
                            onChange={handlePaymentChange}
                            placeholder=""
                            //  disabled
                            value={payment?.Amount}
                        />
                    </div>
                </>
            }

            <div className={styles.revoke__btns}>
                <button
                    onClick={
                        !Object.values(income)?.includes('') ||
                            !Object.values(payment)?.includes('') ?
                            () => {
                                modalAction(modalType === 'income'
                                    ? { ...income, index: data?.id ?? data?.index }
                                    : { ...payment, index: data?.id ?? data?.index }
                                );
                                closeModal(!showing);
                            } : null
                    }
                    className="primary__btn"
                >
                    Update
                </button>
            </div>
        </RegularModal>
    )
}
