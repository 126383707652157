import React, { useEffect, useState, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import styles from "./style.module.css";
import Alert from "../components/Alert";
import RegularModal from "../components/Modal/regular";
import RemitaForPayment from "../assets/Home/remitaLogo.png";
import { Mdas } from "./components/mdas";
import constants from "../constants";
import { formatAmount } from "../utils";
import backArrow from "../assets/arrowLeftWhite.svg";

axios.defaults.testURL = constants.BASE_URL;

var sha512 = require("js-sha512");

const RemitaCheckout = () => {
	const history = useHistory();
	const { state: locationState, search } = useLocation();
	const query = useMemo(() => new URLSearchParams(search), [search]);

	const [amount, setAmount] = useState("");
	const [name, setName] = useState("");
	const [ESBN, setESBN] = useState("");
	const [billNumber, setBillNumber] = useState(
		locationState?.billNumber || query.get("billNumber") || ""
	);
	const [fetchingBill, setFetchingBill] = useState(false);
	const [billInfo, setBillInfo] = useState(null);
	const [address, setAddress] = useState("");
	const [revenue, setRevenue] = useState("");
	const [revenues, setRevenues] = useState([]);
	const [mda, setMda] = useState("");
	const [remark, setRemark] = useState("");
	const [paying, setPaying] = useState(false);
	const [consolidatedPaymentAlertOpen, setConsolidatedAlertOpen] =
		useState(false);
	const [payingWithBillNumber, setPayingWithBillNumber] = useState(true);
	const [alert, setAlert] = useState({
		showing: false,
		type: null,
		message: ""
	});

	const RevenueHead = async () => {
		const res = await axios
			.get(`/settings/mdalinkedrevenue/${mda}`)
			.then((res) => (res.data || {}).data);
		setRevenues(res);
	};

	useEffect(() => {
		RevenueHead();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mda]);

	useEffect(() => {
		const getBillAmount = async () => {
			try {
				setBillInfo(null);
				setFetchingBill(true);
				setRevenue("");
				setAmount("");
				let bill = (
					await axios.get(`/account/bill/${billNumber}`, {
						headers: { secureddata: "VGhpc2lzTm9ybGljcw==" }
					})
				).data.data;

				setBillInfo(bill);
				setName(bill?.Name_on_Bill);
				setESBN(bill?.ESBN_PID_on_Bill);
				setAddress(bill?.CentralisedBillDetails[0].Address);

				if (bill?.CentralisedBillDetails?.length === 1) {
					const mdaCode =
						bill?.CentralisedBillDetails[0].RevenueCode.split(
							"/"
						)[0];

					setMda(mdaCode);
					setRevenue(bill?.CentralisedBillDetails[0].RevenueCode);
				}
			} catch (error) {
				if (error.response) {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message:
							error.response.data?.msg ||
							error.response.data?.errors?.[0]?.message ||
							error.response.data?.errors?.details[0]?.message
					});
				} else {
					setAlert({
						...alert,
						showing: true,
						type: "error",
						message: error.message
					});
				}
			} finally {
				setFetchingBill(false);
			}
		};
		if (billNumber?.length >= 17) getBillAmount();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [billNumber]);

	useEffect(() => {
		const script = document.createElement("script");
		script.src =
			// window.location.hostname === process.env.REACT_APP_COY_PROD_HOSTNAME
			// ?
			"https://login.remita.net/payment/v1/remita-pay-inline.bundle.js";
		// : "https://demo.remita.net/payment/v1/remita-pay-inline.bundle.js";
		script.async = true;
		document.body.appendChild(script);
		// Cleanup: remove the script when the component is unmounted

		return () => document.body.removeChild(script);
	}, []);

	let RRR;

	const generateRemitaRRR = async (amt, revCode) => {
		setPaying(true);
		const apiKey = "809567";
		const merchantId = "12422489261";
		// const apiKey = process.env.REACT_APP_REMITA_APIKEY;
		// const merchantId = process.env.REACT_APP_REMITA_MERCHANTID;
		let serviceTypeId;
		var d = new Date();

		let modifiedRevCode = revenue
			? revenue.split("/").join("-")
			: revCode.split("/").join("-");

		try {
			// if not on production, use test service type ID. Else, try and get service type ID
			// if (
			// 	window.location.hostname !==
			// 	process.env.REACT_APP_COY_PROD_HOSTNAME
			// )
			// 	serviceTypeId = "4430731";
			// else {
			const getServiceTypeId = await axios.get(
				`account/remita/servicetype/${modifiedRevCode}`
			);

			serviceTypeId = getServiceTypeId.data.data.ServiceType;
			// }
		} catch (error) {
			console.log(`---servicetype error:`, error);
		}

		try {
			const url =
				// window.location.hostname ===
				// process.env.REACT_APP_COY_PROD_HOSTNAME
				// ?
				"https://login.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit";
			// : "https://demo.remita.net/remita/exapp/api/v1/send/api/echannelsvc/merchant/api/paymentinit";

			const payerName = name;
			const description = "Enugu Tax Payer Bill";
			const orderId = d.getTime();

			const apiHash = sha512(
				merchantId +
					(serviceTypeId || "12422453483") +
					orderId +
					Number(
						amt ? amt : amount || billInfo?.Total_Grand_Bill_Amount
					) +
					apiKey
			);

			const requestData = {
				serviceTypeId: serviceTypeId || "12422453483",
				amount: Number(
					amt ? amt : amount || billInfo?.Total_Grand_Bill_Amount
				),
				orderId,
				payerName,
				payerPhone: billInfo?.PhoneNo || "081111111111111",
				payerEmail: billInfo?.Email || "eirssupport@norlics.com.ng",
				description,
				customFields: [
					{
						name: "payerName",
						value: name,
						type: "ALL"
					},
					{
						name: "payerPhoneNumber",
						value: billInfo?.PhoneNo || "081111111111111",
						type: "ALL"
					},
					{
						name: "CustReference",
						value: ESBN,
						type: "ALL"
					},
					{
						name: "CustomerId",
						value: ESBN,
						type: "ALL"
					},
					{
						name: "BillNumber",
						value: billNumber || "",
						type: "ALL"
					},
					{
						name: "AgencyCode",
						value: (revenue || revCode).split("/")[0],
						type: "ALL"
					},
					{
						name: "RevenueCode",
						value: revenue || revCode,
						type: "ALL"
					},
					{
						name: "Bill_from_which_Module",
						value: billInfo?.Bill_from_which_Module || "",
						type: "ALL"
					},
					{
						name: "address",
						value: address,
						type: "ALL"
					},
					{
						name: "remark",
						value: remark,
						type: "ALL"
					}
				]
			};
			const config = {
				headers: {
					dataType: "json",
					"Content-Type": "application/json",
					Authorization: `remitaConsumerKey=${merchantId},remitaConsumerToken=${apiHash}`
				}
			};
			const req = await axios.post(url, requestData, config);
			// eslint-disable-next-line no-new-func
			const f = new Function("jsonp", `${req.data}`);

			f(function (ref) {
				RRR = ref.RRR;
			});

			if (!billNumber || billInfo?.CentralisedBillDetails?.length <= 1)
				paymentCallback();
		} catch (error) {
			setPaying(false);
			console.error(error);
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	async function paymentCallback(amt, revCode) {
		const requestData = {
			paymentgateway: "REMITA",
			paymentoption: "noEsbn",
			paygatetranid: RRR,
			billnumber: billNumber || "",
			billername: billInfo?.Name_on_Bill,
			billerphoneno: "",
			billeremail: "",
			amount: amt
				? amt
				: amount.trim() || billInfo?.Total_Grand_Bill_Amount,
			mda: mda || revCode.split("/")[0],
			revenue: revenue || revCode,
			payload: [
				{
					firstName: name,
					lastName: "",
					narration: "Remita Payment",
					amount: Number(amount),
					transactionId: "",
					processRrr: true,
					customFieldData: [
						{
							name: "rrr",
							value: RRR
						},
						{
							name: "payerName",
							value: name
						},
						{
							name: "payerPhoneNumber",
							value: billInfo?.PhoneNo
						},
						{
							name: "CustReference",
							value: ESBN
						},
						{
							name: "CustomerId",
							value: ESBN
						},
						{
							name: "BillNumber",
							value: billNumber || ""
						},
						{
							name: "AgencyCode",
							value: (revenue || revCode).split("/")[0]
						},
						{
							name: "RevenueCode",
							value: revenue || revCode
						},
						{
							name: "Bill_from_which_Module",
							value: billInfo?.Bill_from_which_Module || "",
							type: "ALL"
						},
						{
							name: "address",
							value: address,
							type: "ALL"
						},
						{
							name: "remark",
							value: remark,
							type: "ALL"
						}
					]
				}
			]
		};

		try {
			await axios.post(`${constants.BASE_URL}/taxpaid`, requestData);

			if (!billNumber || billInfo?.CentralisedBillDetails?.length <= 1)
				initializeRemita();
		} catch (error) {
			setPaying(false);
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message ||
						error.response.data.data.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	}

	const startPayment = async () => {
		try {
			if (billInfo?.CentralisedBillDetails?.length > 1 && !amount) {
				billInfo.CentralisedBillDetails.map(async (bill) => {
					await generateRemitaRRR(bill.Amount, bill.RevenueCode);
					paymentCallback(bill.Amount, bill.RevenueCode);
					initializeRemita(bill.Amount, bill.RevenueCode);
				});
			} else if (billInfo?.CentralisedBillDetails?.length > 1 && amount) {
				billInfo.CentralisedBillDetails.map(async (bill) => {
					let amt =
						(bill.Amount / billInfo.Total_Grand_Bill_Amount) *
						amount;

					await generateRemitaRRR(amt, bill.RevenueCode);
					paymentCallback(amt, bill.RevenueCode);
					initializeRemita(amt, bill.RevenueCode);
				});
			} else await generateRemitaRRR();
		} catch (error) {
			if (error.response) {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message:
						error.response.data?.msg ||
						error.response.data?.errors?.[0] ||
						error.response.data?.errors?.[0]?.message ||
						error.response.data?.errors?.details[0]?.message ||
						error.response?.data?.message
				});
			} else {
				setAlert({
					...alert,
					showing: true,
					type: "error",
					message: error.message
				});
			}
		}
	};

	const publicKey = process.env.REACT_APP_REMITA_PUBLIC_KEY;

	const initializeRemita = async (amt, revCode) => {
		try {
			const remitaPaymentEngine = window.RmPaymentEngine.init({
				key: publicKey,
				firstName: name,
				lastName: "",
				narration: "Remita Payment",
				amount: amt
					? amt
					: amount.trim() || billInfo?.Total_Grand_Bill_Amount,
				transactionId: "",
				processRrr: true,
				extendedData: {
					customFields: [
						{
							name: "rrr",
							value: RRR
						},
						{
							name: "payerName",
							value: name
						},
						{
							name: "payerPhoneNumber",
							value: ""
						},
						{
							name: "CustReference",
							value: ESBN
						},
						{
							name: "CustomerId",
							value: ESBN
						},
						{
							name: "BillNumber",
							value: billNumber || ""
						},
						{
							name: "AgencyCode",
							value: (revenue || revCode).split("/")[0]
						},
						{
							name: "RevenueCode",
							value: revenue || revCode
						},
						{
							name: "Bill_from_which_Module",
							value: billInfo?.Bill_from_which_Module || "",
							type: "ALL"
						},
						{
							name: "address",
							value: address,
							type: "ALL"
						},
						{
							name: "remark",
							value: remark,
							type: "ALL"
						}
					]
				},
				onSuccess: function () {
					setPaying(false);
					setBillNumber("");
					setName("");
					setESBN("");
					setAddress("");
					setAmount("");
					setBillInfo("");
					setRevenue("");
					setMda("");
					setAlert({
						...alert,
						showing: true,
						type: "success",
						message:
							`You have successfully paid ₦ ${
								amount.trim() ||
								billInfo?.Total_Grand_Bill_Amount
							}` + (billNumber ? ` for bill ${billNumber}` : "")
					});
					// if this page was opened from any bill payment module outside of the homepage
					if (locationState?.billNumber) {
						if (
							billInfo?.CentralisedBillDetails?.length > 1 &&
							billInfo?.CentralisedBillDetails[
								billInfo?.CentralisedBillDetails?.length - 1
							].RevenueCode === revCode
						)
							locationState?.goBackTo
								? history.push(locationState?.goBackTo, {
										paymentSuccessful: true
									})
								: history.goBack();
						else if (
							billInfo?.CentralisedBillDetails?.length > 1 &&
							billInfo?.CentralisedBillDetails[
								billInfo?.CentralisedBillDetails?.length - 1
							].RevenueCode !== revCode
						)
							return;
						else
							locationState?.goBackTo
								? history.push(locationState?.goBackTo, {
										paymentSuccessful: true
									})
								: history.goBack();
					}
					// if this page was opened from another site other than ours
					if (query.get("redirectURL"))
						window.location.href = `${query.get(
							"redirectURL"
						)}?paymentStatus=successful`;
				},
				onError: function (error) {
					setPaying(false);
					if (error.response) {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message:
								error.response.data?.msg ||
								error.response.data?.errors?.[0] ||
								error.response.data?.errors?.[0]?.message ||
								error.response.data?.errors?.details[0]
									?.message ||
								error.response?.data?.message
						});
					} else {
						setAlert({
							...alert,
							showing: true,
							type: "error",
							message: error.message
						});
					}
				},
				onClose: setPaying(false)
			});
			await remitaPaymentEngine.showPaymentWidget();
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		const delay = setTimeout(
			() =>
				setAlert({
					showing: false,
					type: null,
					message: ""
				}),
			3000
		);

		return () => clearTimeout(delay);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [!!alert.showing]);

	return (
		<div className={styles.page}>
			{alert.showing && <Alert text={alert.message} type={alert.type} />}
			{consolidatedPaymentAlertOpen && (
				<RegularModal
					showing={consolidatedPaymentAlertOpen}
					toggle={setConsolidatedAlertOpen}
				>
					<p>
						You will go through the payment{" "}
						{billInfo?.CentralisedBillDetails?.length} times. Click
						okay to continue.{" "}
					</p>
					<button
						className="primary__btn"
						style={{
							display: "block",
							margin: "40px auto 0",
							padding: "0 25px"
						}}
						onClick={() => {
							setConsolidatedAlertOpen(false);
							startPayment();
						}}
					>
						Okay
					</button>
				</RegularModal>
			)}
			<main className={styles.main}>
				<div className={styles.remita__header}>
					<button
						className={styles.go__back}
						onClick={() => history.goBack()}
					>
						<img src={backArrow} alt="go back arrow" />
					</button>
					<img src={RemitaForPayment} alt="remita logo" />
				</div>
				<div className={styles.radio__btns}>
					<div>
						<input
							id="payWithBillNo"
							type="radio"
							value={true}
							checked={payingWithBillNumber}
							onChange={(e) => {
								setPayingWithBillNumber(true);
								setName("");
								setESBN("");
								setAmount("");
								setBillNumber("");
								setBillInfo(null);
								setAddress("");
								setRevenue("");
								setMda("");
								setRemark("");
							}}
						/>
						<label htmlFor="payWithBillNo">
							Pay With Bill Number
						</label>
					</div>
					<div>
						<input
							id="payWithoutBillNo"
							type="radio"
							value={false}
							checked={!payingWithBillNumber}
							onChange={(e) => {
								setPayingWithBillNumber(false);
								setName("");
								setESBN("");
								setAmount("");
								setBillNumber("");
								setBillInfo(null);
								setAddress("");
								setRevenue("");
								setMda("");
								setRemark("");
							}}
						/>
						<label htmlFor="payWithoutBillNo">
							Pay{" "}
							<b>
								<i>Without</i>
							</b>{" "}
							Bill Number
						</label>
					</div>
				</div>
				<div className={styles.wrapper}>
					{payingWithBillNumber && (
						<div className={styles.single__input}>
							<label
								className={styles.input__label}
								htmlFor="BillNumber"
							>
								Bill Number{" "}
								<span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								onChange={(e) => setBillNumber(e.target.value)}
								id="BillNumber"
								placeholder="Enter bill number"
								required
								value={billNumber}
							/>
						</div>
					)}
					<div className={styles.payments__double__inputs}>
						<div>
							<label
								className={styles.input__label}
								htmlFor="name"
							>
								Name <span style={{ color: "red" }}>*</span>
							</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
								id="amount"
								placeholder="Enter Name"
								required
								disabled={payingWithBillNumber}
							/>
						</div>
						<div>
							<label
								className={styles.input__label}
								htmlFor="ESBN"
							>
								ESBN/PID{" "}
								{payingWithBillNumber && (
									<span style={{ color: "red" }}>*</span>
								)}
							</label>
							<input
								type="text"
								id="ESBN"
								placeholder="Enter your ESBN"
								required={payingWithBillNumber}
								value={ESBN}
								onChange={(e) => setESBN(e.target.value)}
								disabled={payingWithBillNumber}
							/>
						</div>
					</div>
					<div className={styles.single__input}>
						<label
							className={styles.input__label}
							htmlFor="address"
						>
							Address <span style={{ color: "red" }}>*</span>
						</label>
						<input
							id="address"
							placeholder="Enter address"
							value={address}
							onChange={(e) => setAddress(e.target.value)}
							disabled={payingWithBillNumber}
							required
						/>
					</div>
					{/* Revenue Head Bill Breakdown */}
					{fetchingBill && (
						<p style={{ textAlign: "center" }}>Loading...</p>
					)}
					{billInfo && (
						<table className={styles.table}>
							<thead>
								<tr>
									<th>S/N</th>
									<th>CATEGORY (REVENUE HEAD)</th>
									<th>Outstanding Bill (₦)</th>
									<th>Current Bill (₦)</th>
								</tr>
							</thead>
							<tbody>
								{billInfo?.CentralisedBillDetails.map(
									(each, i) => (
										<tr key={each.MDA}>
											<td>{i + 1}.</td>
											<td>{each.BillDescription}</td>
											<td>{each.OutstandingAmount}</td>
											<td>
												₦{formatAmount(each.Amount)}
											</td>
										</tr>
									)
								)}
								<tr className={styles.no__border_bottom}>
									<td></td>
									<td></td>
									<td className={styles.discount}>
										DISCOUNT
									</td>
									<td className={styles.discount}>
										₦
										{formatAmount(
											billInfo?.Total_Discount_Bill_Amount
										)}
									</td>
								</tr>
								<tr className={styles.no__border_bottom}>
									<td></td>
									<td></td>
									<td className={styles.total}>TOTAL DUE</td>
									<td className={styles.total}>
										₦
										{formatAmount(
											billInfo?.Total_Grand_Bill_Amount
										)}
									</td>
								</tr>
							</tbody>
						</table>
					)}
					{(billInfo?.CentralisedBillDetails?.length === 1 ||
						!payingWithBillNumber) && (
						<div className={styles.payments__double__inputs}>
							<Mdas setValue={(val) => setMda(val)} value={mda} />
							<div>
								<label
									className={styles.input__label}
									htmlFor="mda"
								>
									Revenue{" "}
									<span style={{ color: "red" }}>*</span>
								</label>
								<select
									id="mda"
									className={styles.input__medium}
									name="mda"
									onChange={(e) => {
										setRevenue(e.target.value);
									}}
									value={revenue}
									disabled={
										(payingWithBillNumber &&
											(!billNumber ||
												!mda ||
												!billInfo)) ||
										(!payingWithBillNumber && !mda)
									}
									required
								>
									<option value="">
										{revenues
											? "--Select Revenue head--"
											: "Loading..."}
									</option>
									{revenues &&
										revenues.map((getRev) => (
											<option
												key={getRev.Code}
												value={getRev.Code}
											>
												{getRev.Description}
											</option>
										))}
								</select>
							</div>
						</div>
					)}
					{!payingWithBillNumber && (
						<div className={styles.single__input}>
							<label
								className={styles.input__label}
								htmlFor="remark"
							>
								Remark
							</label>
							<textarea
								id="remark"
								placeholder="Enter remark"
								value={remark}
								onChange={(e) => setRemark(e.target.value)}
								rows={3}
								disabled={payingWithBillNumber}
							/>
						</div>
					)}
					<div className={styles.single__input}>
						<label className={styles.input__label} htmlFor="amount">
							Amount to Pay (
							{billInfo?.CentralisedBillDetails?.length > 1 &&
								"Min. Amount: ₦ 2000."}{" "}
							Ignore and Click “Pay Now” to pay in full){" "}
							<span style={{ color: "red" }}>*</span>
						</label>
						<input
							type="number"
							value={amount}
							onChange={(e) => setAmount(e.target.value)}
							id="amount"
							placeholder="Enter amount"
							required
						/>
						{billInfo?.CentralisedBillDetails?.length > 1 &&
							amount &&
							amount < 2000 && (
								<span style={{ color: "red" }}>
									Amount must be, at least, ₦ 2000
								</span>
							)}
					</div>
					<button
						onClick={() => {
							if (billInfo?.CentralisedBillDetails?.length > 1)
								setConsolidatedAlertOpen(true);
							else startPayment();
						}}
						className={["primary__btn", styles.btn].join(" ")}
						disabled={
							(payingWithBillNumber &&
								(!billNumber ||
									!billInfo ||
									(billInfo?.CentralisedBillDetails
										?.length === 1 &&
										(!revenue || !mda)))) ||
							(!payingWithBillNumber &&
								(!name ||
									!address ||
									!mda ||
									!revenue ||
									!amount)) ||
							(amount &&
								billInfo?.CentralisedBillDetails?.length > 1 &&
								+amount < 2000)
						}
					>
						{paying
							? `Paying ₦ 
						${formatAmount(amount || billInfo?.Total_Grand_Bill_Amount)}
						... `
							: `Pay ₦ 
						${formatAmount(amount || billInfo?.Total_Grand_Bill_Amount)}
						 now`}
					</button>
				</div>
			</main>
		</div>
	);
};

export default RemitaCheckout;
