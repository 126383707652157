import React from "react";
import { useState } from "react";
import ESIRSJTBTIN from "../assets/Home/ESIRS_JTB_TIN.xlsx";
import styles from "./news.modal.module.css";

const NewsModal = () => {
	const [modalVisible, setModalVisible] = useState(true);

	// checking if the user has visited the page and has seen the news modal //
	// useEffect(() => {
	// 	if (!localStorage.getItem("modalShown")) {
	// 	setModalVisible(true);
	// 	localStorage.setItem("modalShown", "true");
	// 	}
	// }, []);

	const downloadImage = () => {
		const link = document.createElement("a");
		link.href = ESIRSJTBTIN;
		link.download = "ESIRS JTB TIN";
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<div
			className={`${styles.modalOverlay} ${
				modalVisible ? styles.visible : styles.invisible
			}`}
			onClick={() => setModalVisible(false)}
		>
			<div className={styles.modalContent}>
				{/* <h2 className={styles.clickMe}>Click Me </h2>
				<img
					src={OneYearAnniversary}
					alt="AnualReportNotification"
					className={styles.AnualReportNotificationImage}
					onClick={downloadImage}
				/> */}
				<h2 className={styles.text__announcement_header}>
					Generation of TIN for all MDAs' staff
				</h2>
				<p className={styles.text__announcement_description}>
					Each MDA is required to send to EIRS both the hard copy and
					soft copy of the filled template containing all the staff in
					their employment on or before 31st October, 2024 using the
					attached excel worksheet. <br />{" "}
				</p>
				<button
					className={styles.modal__action_btn}
					onClick={downloadImage}
				>
					Download worksheet
				</button>
			</div>
		</div>
	);
};

export default NewsModal;
