import React from 'react'

export const ModalTest = () => {
  return (
<div style={{
    height: '9.6in',
    width: '11.9in',
    margin: 'auto',
    padding: '1rem',
    border: '1px solid red',
    backgroundImage: 'url(https://enunustategovernment.netlify.app/WhatsApp%20Image%202023-11-20%20at%2020.28.22_304c9907.jpg)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70%'
}}>
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', alignItems: 'center', gap: '20px'}}>
            <img src="https://enunustategovernment.netlify.app/ENSG Logo.png" width="7%" alt="" />
            <div style={{fontFamily: 'Arial, Helvetica, sans-serif'}}>
                <h1 style={{color: 'green', margin: 0, fontSize: '25px'}}>ENUGU STATE GOVERNMENT</h1>
                <h3 style={{margin: 0, fontWeight: 'bold'}}>ENUGU STATE WATER CORPORATION</h3>
                <p style={{fontSize: '14px', margin: 0}}>3 Constitution Road, G.R.A, Enugu, Enugu State</p>
            </div>
        </div>
        <img width="70px" src="https://test.norlics.ng/eirslogo1.png" alt="" />
    </div>
    <h2 style={{padding: '5px', textAlign: 'center', color: 'green', backgroundColor: 'rgb(209, 209, 209)', margin: '5px 0'}}>WATER CHARGES BILLING INVOICE - 2013
    </h2>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '3rem',
      marginRight: '5rem',
      fontSize: '14px',
    }}>
        <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            {/* <!-- <h3>PLEASE PAY WITHIN 7 DAYS TO AVOID ENFORCEMENT AND PENALTY</h3> --> */}
        </div>
        <div style={{display: 'flex', alignItems: 'center', gap: '1rem', fontSize: '15px'}}>
            <div>
                <h3 style={{margin: 0, color: 'green'}}>BILL NUMBER:</h3>
                {/* <!-- <h3 style={{margin: 0, fontWeight: bold">ESBN:</h3> --> */}
                <h3 style={{margin: 0}}>ESBN</h3>
                <h3 style={{margin: 0}}>BILLING YEAR:</h3>
                <h3 style={{margin: 0}}>DATE:</h3>
            </div>
            <div>
                <h3 style={{margin: 0, color: 'green'}}>AB20241000000808EN</h3>
                {/* <!-- <h3 style={{margin: 0">0510300885</h3> --> */}
                <h3 style={{margin: 0}}>0510300885</h3>
                <h3 style={{margin: 0}}>2013</h3>
                <h3 style={{margin: 0}}>13th May, 2024</h3>
            </div>
        </div>
    </div>
    <div style={{display: 'flex', alignItems: 'flex-start', gap: '1rem', marginTop: '1rem'}}>
        <div style={{width: '35%'}}>
            <div style={{border: '1px solid green', padding: '5px'}}>
                <p style={{margin: 0}}>Billed To:</p>
                <h3 style={{margin: 0, fontSize: '14px'}}>MIKE NGWU</h3>
                <p style={{margin: 0, paddingTop: '5px'}}>Address:</p>
                <h3 style={{margin: 0, fontSize: '14px'}}>LOOK LEFT STREET OGUI ENUGU </h3>
                <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '5px'}}>
                    <div>
                        <p style={{margin: 0}}>Phone No.:</p>
                        <h3 style={{margin: 0, fontSize: '14px'}}>+23480800000000</h3>
                    </div>
                    <div>
                        <p style={{margin: 0}}>Email.:</p>
                        <h3 style={{margin: 0, fontSize: '14px'}}>good@gmail.com</h3>
                    </div>
                    <div></div>
                </div>
            </div>
            <div style={{
          border: '1px solid green',
          padding: '5px',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
          marginTop: '5px',
            }}>
                <div>
                    <p style={{margin: 0, paddingBottom: '5px'}}>Type of Premises:</p>
                    <p style={{margin: 0}}>Hotels and Restaurants</p>
                </div>
                <div>
                    <p style={{margin: 0, paddingBottom: '5px'}}>Category:</p>
                    <p style={{margin: 0}}>Commercial Properties</p>
                </div>
                <div>
                    <p style={{margin: 0, paddingBottom: '5px'}}>Nature of Business/ Residence:</p>
                    <p style={{margin: 0}}></p>
                </div>
            </div>
        </div>
        <div style={{width: '30%'}}>
            <table style={{borderCollapse: 'collapse', width: '100%'}}>
                <tr style={{border: '1px solid black'}}>
                    <td style={{border: '1px solid #000', width: '40%'}}>Read Date</td>
                    <td></td>
                </tr>
                <tr style={{border: '1px solid black'}}>
                    <td style={{border: '1px solid #000', width: '40%'}}>Due Date</td>
                    <td>13th May, 2024</td>
                </tr>
            </table>
            <table style={{borderCollapse: 'collapse', width: '100%', marginTop: '3rem'}}>
                <tr style={{border: '1px solid black'}}>
                    <td style={{border: '1px solid #000', width: '40%'}}>Previous Amt. Due</td>
                    <td>&#8358;1,000.00</td>
                </tr>
                <tr style={{border: '1px solid black'}}>
                    <td style={{border: '1px solid #000', width: '40%'}}>Payment Received</td>
                    <td>&#8358;0.00</td>
                </tr>
                <tr style={{border: '1px solid black'}}>
                    <td style={{border: '1px solid #000', width: '40%'}}>Adjustments</td>
                    <td>&#8358;0.00</td>
                </tr>
                <tr style={{border: '1px solid black'}}>
                    <td style={{border: '1px solid #000', width: '40%'}}>Applied Deposits</td>
                    <td>&#8358;0.00</td>
                </tr>
                <tr style={{border: '1px solid black'}}>
                    <td style={{border: '1px solid #000', width: '40%'}}>Arrears</td>
                    <td>&#8358;1,000.00</td>
                </tr>
            </table>
        </div>
        <div style={{width: '35%'}}>
            <table style={{borderCollapse: 'collapse', width: '100%'}}>
                <thead style={{border: '1px solid black', backgroundColor: 'rgb(244, 111, 111)'}}>
                    {/* <!-- <tr > --> */}
                        <p style={{padding: '5px', width: '380px', borderTop: '1px solid black', borderRight: '1px solid #000', borderLeft: '1px solid black', marginBottom: 0}}>Current Usage(MtD)</p>
                        {/* <!-- </tr> --> */}
                </thead>
                <tbody>
                    <tr style={{border: '1px solid black'}}>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>a</td>
                        <td style={{border: '1px solid black', padding: '5px 10px', fontSize: '14px'}}>Water Base Charge
                        </td>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>&#8358,0.00</td>
                    </tr>
                    <tr style={{border: '1px solid black'}}>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>b</td>
                        <td style={{border: '1px solid black', padding: '5px 10px', fontSize: '14px'}}>Usage Charge</td>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>&#8358;28,799,900.00</td>
                    </tr>
                    <tr style={{border: '1px solid black'}}>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>c</td>
                        <td style={{border: '1px solid black', padding: '5px 10px', fontSize: '14px'}}>Recurring Charges</td>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>&#8358;0.00</td>
                    </tr>
                    <tr style={{border: '1px solid black'}}>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>d</td>
                        <td style={{border: '1px solid black', padding: '5px 10px', fontSize: '14px'}}>Miscellenous</td>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>&#8358;0.00</td>
                    </tr>
                    <tr style={{border: '1px solid black'}}>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>2</td>
                        <td style={{border: '1px solid black', padding: '5px 10px', fontSize: '14px'}}>Period Charges(a+b+c+d)</td>
                        <td style={{border: '1px solid black', padding: '5px 10px'}}>&#8358;28,799,900.00</td>
                    </tr>
                </tbody>
            </table>
            <table style={{borderCollapse: 'collapse', width: '100%'}}>
                <tr style={{border: '1px solid black'}}>
                    <td style={{border: '1px solid black', padding: '5px 10px'}}>3</td>
                    <td style={{border: '1px solid black', padding: '5px 10px', fontSize: '15px'}}>Service Charge</td>
                    <td style={{border: '1px solid black', padding: '5px 10px'}}>&#8358;0.00</td>
                    <td style={{border: '1px solid black', padding: '5px 10px', textAlign: 'right'}}>Total Due</td>
                    <td style={{border: '1px solid black', padding: '5px 10px', textAlign: 'right'}}>&#8358;28,800,900.00</td>
                </tr>
                <tr style={{border: '1px solid black'}}>
                    <td style={{border: '1px solid black', padding: '5px 10px'}}>4</td>
                    <td style={{border: '1px solid black', padding: '5px 10px', textAlign: 'right'}}>VAT</td>
                    <td style={{border: '1px solid black', padding: '5px 10px'}}>&#8358;0.00</td>
                    <td style={{border: '1px solid black', padding: '5px 10px', textAlign: 'right'}}>(1+2+3+4)</td>
                    <td style={{border: '1px solid black', padding: '5px 10px', textAlign: 'right'}}></td>
                </tr>
            </table>
            <p style={{margin: 0, paddingTop: '10px', fontWeight: 'bold', textAlign: 'center'}}>Balance Remaining: &#8358;0.00
            </p>
            <p style={{margin: 0, paddingTop: '10px', fontWeight: 'bold', textAlign: 'center'}}>Amount in Word: Twenty Eight Million Eight Hundred Thousand Nine Hundred Naira Only
            </p>
        </div>
    </div>
    <table style={{borderCollapse: 'collapse', width: '100%', marginTop: '10px'}}>
        <tr style={{border: '1px solid black', padding: '12px'}}>
            <td style={{border: '1px solid #000', width: '33%'}}>Charge Codes</td>
            <td style={{border: '1px solid #000', width: '33%'}}>F5</td>
            <td style={{border: '1px solid #000', width: '33%'}}>Current Usage(Mtd)</td>
        </tr>
        <tr style={{border: '1px solid black'}}>
            <td style={{border: '1px solid #000', width: '33%'}}>Charge Codes</td>
            <td style={{border: '1px solid #000', width: '33%'}}>Znth</td>
            <td style={{border: '1px solid #000', width: '33%'}}>Current previous(Meter Reading)</td>
        </tr>
    </table>
    <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start', marginTop: '1rem'}}>
        <div style={{width: '70px', marginLeft: '15px', marginTop: '30px', paddingTop: '5px'}}>
            <svg height="64" style={{height:'64px',width:'64px'}} width="64" xmlns="http://www.w3.org/2000/svg">
                <path d="M 0 0 L 2 0 L 2 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,0,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,2,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,4,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,6,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,8,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,10,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,12,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,16,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,17,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,27,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,0)"></path>
                <path d="M 0 0 L 2 0 L 2 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,35,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,37,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,39,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,41,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,45,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,50,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,52,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,54,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,56,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,58,0)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,60,0)"></path>
                <path d="M 0 0 L 2 0 L 2 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,62,0)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,2)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,2)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,2)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,2)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,4)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,4)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,4)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,4)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,6)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,6)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,6)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,6)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,8)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,8)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,8)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,8)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,10)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,10)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,10)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,10)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,12)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,12)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,12)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,12)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,0,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,14)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,14)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,14)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,14)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,16)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,16)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,16)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,16)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,14,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,17)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,17)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,17)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,17)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,0,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,19)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,19)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,19)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,19)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,14,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,21)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,21)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,21)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,21)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,23)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,23)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,23)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,23)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,25)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,25)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,25)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,25)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,0,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,14,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,27)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,27)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,27)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,27)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,29)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,29)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,29)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,29)"></path>
                <path d="M 0 0 L 2 0 L 2 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,0,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,2,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,4,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,6,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,8,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,12,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,14,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,19,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,21,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,25,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,27,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,31)"></path>
                <path d="M 0 0 L 2 0 L 2 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,31,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,37,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,39,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,41,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,43,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,45,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,50,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,52,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,54,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,56,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,58,31)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,60,31)"></path>
                <path d="M 0 0 L 2 0 L 2 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,62,31)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,0,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,33)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,33)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,33)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,33)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,35)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,35)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,35)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,35)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,37)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,37)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,37)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,37)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,0,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,14,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,39)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,39)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,39)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,39)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,41)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,41)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,41)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,41)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,0,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,14,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,43)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,43)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,43)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,43)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,0,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,45)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,45)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,45)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,45)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,47)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,47)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,47)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,47)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,0,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,12,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,48)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,48)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,48)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,48)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,2,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,10,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,50)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,50)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,50)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,50)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,52)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,52)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,52)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,52)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,16,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,23,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,54)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,37,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,45,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,52,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,54)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,54)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,54)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,17,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,25,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,27,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,56)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,35,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,58,56)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,56)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,56)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,4,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,6,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,8,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,19,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,29,58)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,31,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,33,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,41,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,43,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,50,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,56,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,58)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,60,58)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,58)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,0,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,2,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,4,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,6,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,8,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,10,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,12,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,16,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,21,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,25,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,27,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,60)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,33,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,37,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,39,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,41,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,45,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,47,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,48,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,50,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,52,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,54,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,58,60)"></path>
                <path d="M 0 0 L 3 0 L 3 3 L 0 3 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,60)"></path>
                <path d="M 0 0 L 2 0 L 2 3 L 0 3 Z" fill="#000000" transform="matrix(1,0,0,1,62,60)"></path>
                <path d="M 0 0 L 2 0 L 2 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,0,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,2,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,4,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,6,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,8,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,10,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,12,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,14,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,16,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,17,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,19,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,21,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,23,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,25,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,27,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,29,62)"></path>
                <path d="M 0 0 L 2 0 L 2 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,31,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,33,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,35,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,37,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,39,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,41,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,43,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,45,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,47,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,48,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,50,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,52,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,54,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,56,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#000000" transform="matrix(1,0,0,1,58,62)"></path>
                <path d="M 0 0 L 3 0 L 3 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,60,62)"></path>
                <path d="M 0 0 L 2 0 L 2 2 L 0 2 Z" fill="#FFFFFF" transform="matrix(1,0,0,1,62,62)"></path>
            </svg>
        </div>
        <div style={{width: '40%', margin: '0 1rem'}}>
            <h3 style={{margin: 0, fontSize: '15px'}}>HOW TO PAY:</h3>
            <p style={{fontSize: '14px'}}>Indicate the revenue you want to pay for
                and present the Bill Number on the top right corner of this document. Pay the total amount. Get e-payment print out
                from the bank. Customer care line - 08174845411.
            </p>
            <p style={{fontSize: '14px'}}>Contact Information: You can call for enquiries: 08174845411 or send an email to
                waterconnection@enswc.com or visit www.enswc.com.

                {/* <!-- Or Email: <a style={{color: 'black'}} href="mailto:info@irs.en.gov.ng">info@irs.en.gov.ng</a> Or Visit: <a style={{color: 'black'}} href="https://www.irs.en.gov.ng">www.irs.en.gov.ng</a> --> */}
            </p>
        </div>
        <div style={{width: '40%'}}>
            <div style={{display: 'flex', alignItems: 'flex-start'}}>
                <h3 style={{margin: 0, color: 'red', fontSize: '14px'}}>PLEASE NOTE:</h3>
                {/* <!-- <p style={{margin: 0, fontSize: '14px'}}>Tariff plan can be seen displayed in all ESWAMA offices</p> -->
                <!-- <p style={{margin: 0, fontSize: '14px'}}>Tariff plan can be seen displayed in all ESWAMA offices OR please visit irs.en.gov.ng</p> --> */}
            </div>
            <p style={{border: '1px dashed red', padding: '5px', fontSize: '12px'}}>
                Failure to settle the Amount within 14days of Due Date subjects your premises to <b>disconnection.</b>
                All enquiries regarding this invoice should be directed to the Commercial Department of the Corporation.
                Your prompt payment(s) enable us to serve your better.

            </p>
            <div style={{textAlign: 'end', marginTop: '1rem'}}>
                <img src="https://usersignatures.netlify.app/andreasgebauer.png" width="100px" alt="" />
                <p style={{margin: 0}}>Andreas Gebauer</p>
                <p style={{margin: 0}}>SPA to the Governor on Water</p>
            </div>
        </div>
    </div>
    <div style={{
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      gap: '2rem',
      borderTop: '1px solid black',
      paddingTop: '5px',
      marginTop: '8px'
    }}>
        <p style={{margin: 0, fontSize: '12px'}}>
            {/* <!-- Generated via: www.irs.en.gov.ng on December 4, 2023 at 05:54:10 AM by Admin Housing. Created By:chiomaejim. To Verify the --> */}
            Generated via: www.irs.en.gov.ng on May 13, 2024 at 02:33:48 PM. To Verify the authencity of this document,
            scan QR-Code at no cost.
        </p>
        <img src="https://enunustategovernment.netlify.app/WhatsApp Image 2023-11-20 at 20.28.23_c0f4fe22.jpg" width="25%" alt="" />
    </div>
</div>
  )
}
